import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';

import { size, get, concat, sortBy, trim, capitalize } from 'lodash';

import ErrorMessages from 'components/ErrorMessages';

import DocumentoAdesaoPrchpa from 'containers/AdesaoPrchpa';
import AprovacaoProjetosDocEspecifico from 'containers/AprovacaoProjetos';
import { UAP_LABELS_ENUM } from 'containers/AprovacaoProjetos/TabelaAreasAP/constantsAP';
import { obtemArrazoadoDados } from 'containers/ArrazoadoEvu';
import CertidaoDemolicaoDocEspecifico, { obtemCertidaoDemolicaoDados } from 'containers/CertidaoDemolicao';
import { obtemCertificacaoSustentavelDados } from 'containers/CertificacaoSustentavel';
import DocumentoCertificacaoSustentavel from 'containers/CertificacaoSustentavel';
import LicencasExpressasDocEspecifico, { obtemFormularioLicencasExpressasDados } from 'containers/LicencasExpressas';
import LicenciamentoExpressoDocEspecifico, {
	obtemFormularioLicenciamentoExpressoDados
} from 'containers/LicenciamentoExpresso/LicencimentoExpressoDocEspecifico';
import RequerimentoIndices, { obtemRequerimentoIndicesDados } from 'containers/RequerimentoIndices';
import DocumentoVeiculoDivulgacao, { obtemVeiculoDivulgacaoDados } from 'containers/VeiculosDivulgacao';
import DocumentoVistoriaHabitese, { obtemVistoriaDados } from 'containers/VistoriaHabitese';

import { API_URL } from 'environments';

import { calculaNumeroLinhas, defineNomeSecretaria, isDebug, joinBr, obtemDadosDocumento } from 'utils/tools';

import actions from '../actions';
import {
	EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS,
	EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU,
	EXTENSAO_DOC_ESPECIFICO_HABITESE,
	EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
	EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
	EXTENSAO_DOC_ESPECIFICO_UDRI,
	EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL,
	EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO,
	EXTENSOES_DOCUMENTOS_ESPECIFICOS,
	EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
	EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA,
	EXTENSAO_DOC_ESPECIFICO_VD
} from '../Detalhe';
import DocumentoArrazoadoEvu from './DocumentoArrazoadoEvu';
import DocumentoPlanilhaEdifGerais from './DocumentoPlanilhaEdifGerais';
import DocumentoSlotModal from './DocumentoSlotModal';
import DocumentoUpload from './DocumentoUpload';

export const escondeBotaoAddMultiDocs = (documentos, docPai) => {
	// se achar algum slot do grupo deste docPai sem filename, quer dizer que este slot stá vazio,
	// então esconde o botão de adicionar slots para não dar opção de adicionar novos slots para este pai
	// documentos.reduce((acc, d) => acc || (d.docGroup === docPai.id ? !d.filename : acc), false);

	const docGroup = documentos.filter(d => d.docGroup === docPai.id);
	let esconder = false;
	docGroup.forEach(d => {
		if (!d.filename) {
			esconder = true;
		}
	});
	return esconder;
};
const ComplementacaoBpmTask = ({
	formulario,
	taskName,
	documentosComplementar,
	perguntasComplementar,
	documentosDados,
	motivos,
	errors,
	onDrop,
	removeFile,
	criarNovoDocumento,
	retiraDocumento,
	complementarProcesso,
	criarMultiDocumentos,
	updateResposta,
	emProcessamento,
	dataSolicComplementacao,
	emComplementacao
}) => {
	let nomeEnquadramentoAP = '';

	/* REDUX */
	const dispatch = useDispatch();

	const enquadramento = useMemo(() => formulario?.formData?.data?.enquadramento, [formulario]);
	const definicao = useMemo(() => enquadramento?.definicao, [enquadramento]);

	const tituloCertificacaoSustentavel = useMemo(
		() =>
			`Formulário de Certificação em Sustentabilidade Ambiental${
				definicao ? ` para Projeto ${capitalize(definicao)}` : ''
			}`,
		[definicao]
	);
	const tituloVeiculoDivulgacao = useMemo(
		() => `Formulário de Veículo de Divulgação${definicao ? ` para Projeto ${capitalize(definicao)}` : ''}`,
		[definicao]
	);

	const tituloCertidaoDemolicao = useMemo(
		() => `Requerimento para Certidão de Demolição${definicao ? ` ${capitalize(definicao)}` : ''}`,
		[definicao]
	);

	const documentos = useMemo(() => {
		documentosComplementar.forEach(doc => {
			doc.newDoc = doc.newDoc || (doc.ordem % 100 !== 0 && size(doc.idDocumentoSei) === 0);
		});
		const documentosCompletos = [...documentosComplementar];
		return documentosCompletos;
	}, [documentosComplementar]);

	const dispararComplementarProcesso = () => {
		const perguntas = perguntasComplementar.filter(info => size(trim(info)) > 0);
		let existePerguntaNaoRespondida = false;
		perguntas.forEach(perg => {
			if (!get(perg, 'resposta')) {
				existePerguntaNaoRespondida = true;
			}
		});

		let existemDocsNaoPreenchidos = false;
		if (size(documentosComplementar) > 0) {
			documentosComplementar.forEach(doc => {
				if (doc.fromSolicitante && !doc.filename && !doc.size) {
					existemDocsNaoPreenchidos = true;
				}
			});
		}

		if (size(documentosComplementar) === 0 && existePerguntaNaoRespondida) {
			dispatch(
				actions.setErrors({
					perguntaVazia: [
						'Você deve adicionar algum documento ou escrever uma resposta ao revisor para devolver esse pedido de complementação.'
					]
				})
			);
		} else if (size(documentosComplementar) > 0 && existemDocsNaoPreenchidos) {
			dispatch(
				actions.setErrors({
					perguntaVazia: ['Você deve realizar o upload dos novos documentos adicionados.']
				})
			);
		} else {
			dispatch(actions.setErrors([]));
			complementarProcesso(taskName);
		}
	};

	const esconderBotaoAddDocsLE0970 = () =>
		documentosComplementar?.some(doc => doc.idDocumento === 'planta-assinada-rt') && taskName === 'LE0970';

	return (
		<div className="box-complementacao">
			{dataSolicComplementacao && (
				<div className="form-group col-md-12">
					<label>
						<b>{`Complementação ${defineNomeSecretaria(taskName).nomeSecretaria} em ${dataSolicComplementacao.format(
							'DD/MM/YYYY [às] HH:mm'
						)}`}</b>
						<div className="debug-message">({taskName})</div>
					</label>
				</div>
			)}

			{/* PERGUNTAS */}
			{size(perguntasComplementar) > 0 &&
				perguntasComplementar.reduce((acc, info) => acc || size(trim(info.pergunta)) > 0, false) && (
					<div className="form-group col-md-12">
						{perguntasComplementar
							.filter(info => size(trim(info)) > 0)
							.map(info => (
								<div className="row" key={info.id}>
									<div className="col-md-12">
										{isDebug && (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<span className="debug-message">
													({info.id} -{' '}
													{info.taskOrigem ? info.taskOrigem : info.taskName ? info.taskName : 'não tem taskOrigem'})
												</span>
											</div>
										)}
										<span style={{ textAlign: 'justify', overflowWrap: 'break-word' }}>
											<div style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>Despacho: {info.pergunta}</div>
										</span>
										<textarea
											style={{ marginBottom: '10px' }}
											className="form-control"
											type="text"
											rows={calculaNumeroLinhas(info.resposta)}
											placeholder="Observações"
											value={info.resposta || ''}
											onChange={e => {
												const resposta = get(e, 'target.value') || '';
												updateResposta(info.id, resposta);
											}}
											readOnly={formulario.formData.expirado}
											disabled={formulario.formData.disabled}
										></textarea>
									</div>
									<ErrorMessages errorList={get(errors, info.id) || false} />
								</div>
							))}
					</div>
				)}
			<ErrorMessages errorList={get(errors, 'perguntaVazia') || false} />

			{/* DOCUMENTOS */}
			{sortBy(documentos, 'ordem')
				.filter(d => !d.invisible)
				.map((d, i) => {
					const arquivoGerado = !!d.filename || size(get(formulario, 'formData.resultado')) > 0;
					const enquadramento = get(formulario, 'formData.data.enquadramento');

					if (d.extensao === EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS) {
						if (enquadramento && enquadramento.definicao && size(enquadramento.definicao) > 0) {
							nomeEnquadramentoAP = (enquadramento.definicao || '').split(',').reduce((acc, def) => {
								const novoLabel = UAP_LABELS_ENUM[def];
								if (novoLabel) {
									return [...acc, novoLabel];
								}
								return acc;
							}, []);
							nomeEnquadramentoAP = joinBr(nomeEnquadramentoAP);
						}
					}
					return (
						<div className="form-group col-md-12" key={i}>
							<label htmlFor={`inputFile${d.id}`}>
								<span style={{ display: 'flex', justifyContent: 'space-between' }}>
									<span>
										{d.tituloDocumento || d.tituloDocumentoAdmin}
										{d.obrigatorio ? '*' : ''}
										{d.versao && d.versao !== 1 ? ` (v. ${d.versao})` : ''}
									</span>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{isDebug && (
											<span className="debug-message">
												({d.idDocumento} - {d.ordem} - {d.taskOrigem ? d.taskOrigem : 'não tem taskOrigem'})
											</span>
										)}
									</div>
								</span>
								{d.descricaoDocumento && (
									<span style={{ fontSize: '1.0rem', color: 'grey' }}>{d.descricaoDocumento}</span>
								)}
							</label>
							{formulario.formData.expirado ? (
								<ErrorMessages errorList={get(motivos, d.id, false)} />
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS ? (
								<DocumentoPlanilhaEdifGerais
									documento={d}
									planilha={(documentosDados || []).find(dd => dd.id === d.id)}
									usuarioDonoTask={true}
									removeFile={removeFile}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU ? (
								<DocumentoArrazoadoEvu
									documento={d}
									dados={obtemArrazoadoDados(formulario)}
									usuarioDonoTask={true}
									removeFile={removeFile}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_HABITESE ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Documento de Vistoria em rascunho"
									labelDocumentoInvalidado="Corrigir o documento"
									labelCriarDocumento="Criar Formulário Habite-se"
									modalTitulo="Formulário Habite-se"
									documento={d}
									documentoDados={obtemVistoriaDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoVistoriaHabitese}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_HABITESE}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_HABITESE}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário Licenciamento Expresso em rascunho"
									labelDocumentoInvalidado="Corrigir o documento"
									labelCriarDocumento="Criar Formulário Licenciamento Expresso"
									modalTitulo="Licenciamento Expresso"
									documento={d}
									documentoDados={obtemFormularioLicenciamentoExpressoDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={LicenciamentoExpressoDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário Licenças para obras de simples natureza em rascunho"
									labelDocumentoInvalidado="Corrigir o documento"
									labelCriarDocumento="Criar Formulário Licenças para obras de simples natureza"
									modalTitulo="Licenças para obras de simples natureza"
									documento={d}
									documentoDados={obtemFormularioLicencasExpressasDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={LicencasExpressasDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_UDRI ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Requerimento de Índices em rascunho"
									labelDocumentoInvalidado="Corrigir o Requerimento de Índices"
									labelCriarDocumento="Criar Requerimento de Índices"
									modalTitulo="Requerimento de Índices"
									documento={d}
									documentoDados={obtemRequerimentoIndicesDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={RequerimentoIndices}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_UDRI}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_UDRI}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário para Certificação em Sustentabilidade Ambiental em rascunho"
									labelDocumentoInvalidado="Corrigir o formulário para Certificação em Sustentabilidade Ambiental"
									labelCriarDocumento="Criar formulário para Certificação em Sustentabilidade Ambiental"
									modalTitulo={tituloCertificacaoSustentavel}
									documento={d}
									documentoDados={obtemCertificacaoSustentavelDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoCertificacaoSustentavel}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_VD ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário para Veículo de Divulgação em rascunho"
									labelDocumentoInvalidado="Corrigir o formulário para Veículo de Divulgação"
									labelCriarDocumento="Criar formulário para Veículo de Divulgação"
									modalTitulo={tituloVeiculoDivulgacao}
									documento={d}
									documentoDados={obtemVeiculoDivulgacaoDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoVeiculoDivulgacao}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_VD}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_VD}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO ? (
								<DocumentoSlotModal
									labelDocumentoRascunho={`Documento específico para solicitação de certidão de demolição${
										definicao ? ` ${definicao}` : ''
									} em rascunho`}
									labelDocumentoInvalidado={`Corrigir o documento específico para solicitação de certidão ${definicao}`}
									labelCriarDocumento={`Criar Documento específico para solicitação de certidão ${definicao}`}
									modalTitulo={tituloCertidaoDemolicao}
									documento={d}
									documentoDados={obtemCertidaoDemolicaoDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={CertidaoDemolicaoDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS ? (
								<DocumentoSlotModal
									labelDocumentoRascunho={'Documento específico para aprovação de projeto arquitetônico em rascunho'}
									labelDocumentoInvalidado={'Corrigir o documento específico para aprovação de projeto arquitetônico'}
									labelCriarDocumento={'Criar documento específico para aprovação de projeto arquitetônico'}
									modalTitulo={'Aprovação de Projeto Arquitetônico'}
									modalSubTitulo={nomeEnquadramentoAP}
									documento={d}
									documentoDados={obtemDadosDocumento(
										formulario,
										EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
										false,
										false
									)}
									usuarioDonoTask={true}
									componentDocumento={AprovacaoProjetosDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário em rascunho"
									labelDocumentoInvalidado="Corrigir o formulário"
									labelCriarDocumento="Criar o formulário"
									modalTitulo="Formulário para Adesão ao Programa de Reabilitação Urbana do Centro Histórico de Porto Alegre"
									documento={d}
									documentoDados={obtemCertificacaoSustentavelDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoAdesaoPrchpa}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}`}
									removeFile={removeFile}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA}
								/>
							) : (
								<DocumentoUpload
									formData={formulario.formData}
									documento={d}
									emProcessamento={emProcessamento(d)}
									arquivoGerado={arquivoGerado}
									onDrop={onDrop}
									removeFile={removeFile}
									retiraDocumento={retiraDocumento}
									procedimentoFormatadoSei={formulario.formData.procedimentoFormatadoSei}
									usuarioDonoTask={true}
									emComplementacao={emComplementacao}
								/>
							)}
							{!EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(d.extensao) && (
								<button
									hidden={d.ordem % 100 !== 0 || !d.filename || escondeBotaoAddMultiDocs(documentos, d)}
									type="button"
									className="btn-link"
									onClick={() => criarMultiDocumentos(d)}
								>
									<i className="fa fa-plus" style={{ fontSize: '18px' }} />
									{`  Adicionar outro documento de ${d.tituloDocumento || d.tituloDocumentoAdmi}`}
								</button>
							)}
							<ErrorMessages errorList={concat(get(motivos, d.id) || [], get(errors, d.id) || [])} />
						</div>
					);
				})}

			<div>
				{!esconderBotaoAddDocsLE0970() && (
					<button type="button" className="btn-secondary" onClick={() => criarNovoDocumento(taskName)}>
						Adicionar documento
					</button>
				)}
				<button type="button" className="btn-primary" onClick={() => dispararComplementarProcesso()}>
					{`Retornar para análise do revisor${` (${defineNomeSecretaria(taskName).siglaSecretaria})`}`}
				</button>
			</div>
			{/* {isDebug && <pre>{JSON.stringify({ motivos, documentos, perguntasComplementar }, null, 2)}</pre>} */}
		</div>
	);
};
ComplementacaoBpmTask.displayName = 'ComplementacaoBpmTask';
ComplementacaoBpmTask.propTypes = {
	formulario: PropTypes.object,
	taskName: PropTypes.string,
	documentosComplementar: PropTypes.arrayOf(PropTypes.object),
	perguntasComplementar: PropTypes.arrayOf(PropTypes.object),
	documentosDados: PropTypes.array,
	motivos: PropTypes.object,
	errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
	onDrop: PropTypes.func,
	removeFile: PropTypes.func,
	retiraDocumento: PropTypes.func,
	criarNovoDocumento: PropTypes.func,
	complementarProcesso: PropTypes.func,
	criarMultiDocumentos: PropTypes.func,
	updateResposta: PropTypes.func,
	emProcessamento: PropTypes.func,
	dataSolicComplementacao: PropTypes.object,
	emComplementacao: PropTypes.bool
};

export default ComplementacaoBpmTask;

import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { connect, ReactReduxContext } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { push, replace } from 'connected-react-router';
import { findIndex, get, isNil, omit, pick, size, trim } from 'lodash';
import moment from 'moment';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid/v4';

import { onValidate } from 'components/AtividadesLicenciaveisHooks';
import Breadcrumbs, { LIST_PLACEHOLDER } from 'components/Breadcrumbs';
import EditorView from 'components/EditorView';
import ErrorMessages from 'components/ErrorMessages';
import EstadoRequerimento, { STATUS_PROCESS } from 'components/EstadoRequerimento';
import Expediente from 'components/Expediente';
import Formulario from 'components/Formulario';
import LinkProcesso from 'components/LinkProcesso';
import Loader from 'components/Loader';
import Selecao from 'components/Selecao';
import TermosCondicoes from 'components/TermosCondicoes';
import WarningMessage from 'components/WarningMessage';
import withMessage from 'components/WithMessage';

import TermosCondicoesLicencasExpressas from 'containers/LicencasExpressas/TermosECondicoesLicencasExpressas';

import { TIPO_AUTORIZACAO_OUTROS, TIPO_AUTORIZACAO_PRAZO, TIPO_PROPRIETARIO_OUTROS } from 'utils/constants';
import { accessApi } from 'utils/injectApi';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import {
	deepEqual,
	getUpdatedToken,
	isCNPJ,
	isCPF,
	isDebug,
	isEmail,
	isTelefone,
	obtainUrlAdmin,
	termoNotificacaoEmpty,
	titleCase,
	verificaFeiraDeRua
} from 'utils/tools';

import { FormLicencaAmbiental } from './aba-ambiental/licenca';
import AbaDamForm from './aba-dam/AbaDamForm';
import ComplementacaoBpm from './aba-documentos/ComplementacaoBpm';
import Documentos from './aba-documentos/Documentos';
import EnquadramentoForm from './aba-enquadramento';
import actions from './actions';
import LicencaExpressaForm from './licenca-expressa';
import { ListaAbas } from './lista-abas';
import MetadataForm from './metadata-form';
import RceOficinaSimilaresForm from './metadata-form-especializado/licenca-ambiental/RceOficinaSimilares';
import EventosForm from './metadata-form-especializado/solicitar-eventos';
import reducer from './reducer';
import saga from './saga';
import selectors from './selectors';

export const originalMessages = ['is a required property', 'should be integer', 'should be string'];
export const translatedMessages = ['campo obrigatório', 'campo deve ser um número inteiro', 'campo deve ser um texto'];

const debug = isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(DETALHE)', ++i, ...args);

const ABA_FORMULARIO = 'formulario';
const ABA_LAC = 'licenca_ambiental';
const ABA_ENQUADRAMENTO = 'enquadramento';
const ABA_DOCUMENTOS = 'documentos';
const ABA_TERMO = 'termo';
const ABA_PROTOCOLO = 'protocolo';
const ABA_DAM = 'dam';

export const TIPO_FORMULARIO_APROVACAO_PROJETOS = 'aprovacao-projetos';
export const TIPO_FORMULARIO_HABITE_SE = 'habite-se-bpm';
export const TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO = 'licenciamento_expresso';
export const TIPO_FORMULARIO_LICENCAS_EXPRESSAS = 'licencas-expressas';
export const TIPO_FORMULARIO_CERTIDAO_CDRI = 'certidao-cdri';
export const TIPO_FORMULARIO_CERTIDAO_DEMOLICAO = 'certidao-demolicao';
export const TIPO_FORMULARIO_CERTIDAO_AREA_CONSTRUIDA = 'certidao-area-construida';
export const TIPO_FORMULARIO_LAUDOS = 'laudos';
export const TIPO_FORMULARIO_DIGITALIZACAO = 'digitalizacao';
export const TIPO_FORMULARIO_ETR_NA_HORA = 'etr-na-hora';
export const TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA = 'licenca-toldos';
export const TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO = 'etr-na-hora-renovacao';
export const TIPO_FORMULARIO_RETIFICA_CARTA = 'retifica-carta-bpm';
export const TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL = 'certificacao-sustentavel';
export const TIPO_FORMULARIO_CERTIDAO_AVERBACAO = 'certidao-averbacao';
export const TIPO_FORMULARIO_APROVACAO_PROJETOS_LIC_EXPRESSO = 'aprovacao-projeto-licenciamento-expresso';
export const TIPO_FORMULARIO_LICENCA_EXPRESSA = 'licenca-expressa';
export const TIPO_FORMULARIO_CERTIDAO = 'certidao';
export const TIPO_FORMULARIO_EVENTOS = 'eventos';
export const TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES = 'lac_rce_oficinas_similares';
export const TIPO_FORMULARIO_GENERICO = 'form-generico';
export const TIPO_FORMULARIO_DMI_DETALHADA = 'dmi-detalhada';
export const TIPO_FORMULARIO_VISTAS_COPIAS = 'vistas';
export const TIPO_FORMULARIO_CROQUI_LOGRADOURO = 'croqui-logradouro';
export const TIPO_FORMULARIO_GENERICO_SEM_VALIDACAO_RT = 'form-generico-sem-validacao-rt';
export const TIPO_FORMULARIO_GENERICO_SEM_ARTRRT = 'form-generico-sem-artrrt';
export const TIPO_FORMULARIO_GENERICO_RT_NAO_OBRIGATORIO = 'form-generico-rt-nao-obrigatorio';
export const TIPO_FORMULARIO_GENERICO_SEM_ARTRRT_OBRIGATORIO_SEM_DAM = 'form-generico-sem-artrrt-obrigatorio-sem-dam';
export const TIPO_FORMULARIO_GENERICO_DAM_OBRIGATORIA = 'form-generico-dam-obrigatoria';
export const TIPO_FORMULARIO_GENERICO_SEM_DAM = 'form-generico-sem-dam';
export const TIPO_FORMULARIO_BOLETOS_UDRI = 'boletos-udri';
export const TIPO_FORMULARIO_TESTE = 'tipo-formulario-teste';
export const TIPO_FORMULARIO_PROJETOS_ESPECIAIS = 'projetos-especiais';
export const TIPO_FORMULARIO_AUTORIZACAO_PLANTIO_CALCADA = 'autorizacao-plantio-calcada';
export const TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM = 'solicitacao-eventos-bpm';
export const TIPO_FORMULARIO_PGM_CAMERA_CONCILIACAO = 'pgm-camera-conciliacao';
export const TIPO_FORMULARIO_ADESAO_PRCHPA = 'adesao-prchpa';
export const TIPO_FORM_DMAE_FORM_01 = 'dmae-form-01';
export const TIPO_FORM_SMAP_PERMISSOES_USO = 'smap-permissoes-uso';
export const TIPO_FORMULARIO_AJUSTE_PROJETOS = 'ajuste-projetos';
export const TIPO_FORMULARIO_VEICULO_DIVULGACAO = 'veiculo-divulgacao';
export const TIPO_FORMULARIO_TROCA_RT = 'troca-de-rt';

const META_FORMULARIOS = [
	TIPO_FORMULARIO_TESTE,
	TIPO_FORMULARIO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_APROVACAO_PROJETOS_LIC_EXPRESSO,
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_CERTIDAO,
	TIPO_FORMULARIO_GENERICO,
	TIPO_FORMULARIO_DMI_DETALHADA,
	TIPO_FORMULARIO_VISTAS_COPIAS,
	TIPO_FORMULARIO_CROQUI_LOGRADOURO,
	TIPO_FORMULARIO_GENERICO_SEM_VALIDACAO_RT,
	TIPO_FORMULARIO_GENERICO_SEM_ARTRRT,
	TIPO_FORMULARIO_GENERICO_RT_NAO_OBRIGATORIO,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
	TIPO_FORMULARIO_LICENCAS_EXPRESSAS,
	TIPO_FORMULARIO_ETR_NA_HORA,
	TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
	TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA,
	TIPO_FORMULARIO_DIGITALIZACAO,
	TIPO_FORMULARIO_GENERICO_SEM_DAM,
	TIPO_FORMULARIO_GENERICO_SEM_ARTRRT_OBRIGATORIO_SEM_DAM,
	TIPO_FORMULARIO_CERTIDAO_CDRI,
	TIPO_FORMULARIO_CERTIDAO_DEMOLICAO,
	TIPO_FORMULARIO_CERTIDAO_AREA_CONSTRUIDA,
	TIPO_FORMULARIO_CERTIDAO_AVERBACAO,
	TIPO_FORMULARIO_RETIFICA_CARTA,
	TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL,
	TIPO_FORMULARIO_PROJETOS_ESPECIAIS,
	TIPO_FORMULARIO_AUTORIZACAO_PLANTIO_CALCADA,
	TIPO_FORM_SMAP_PERMISSOES_USO,
	TIPO_FORMULARIO_VEICULO_DIVULGACAO
];

const FORMS_COM_ENQUADRAMENTO = [
	TIPO_FORMULARIO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
	TIPO_FORMULARIO_LICENCAS_EXPRESSAS,
	TIPO_FORMULARIO_CERTIDAO_CDRI,
	TIPO_FORMULARIO_LAUDOS,
	TIPO_FORMULARIO_CERTIDAO_DEMOLICAO,
	TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL,
	TIPO_FORMULARIO_PROJETOS_ESPECIAIS,
	TIPO_FORMULARIO_AUTORIZACAO_PLANTIO_CALCADA,
	TIPO_FORMULARIO_ADESAO_PRCHPA,
	TIPO_FORM_DMAE_FORM_01,
	TIPO_FORM_SMAP_PERMISSOES_USO,
	TIPO_FORMULARIO_VEICULO_DIVULGACAO,
	TIPO_FORMULARIO_TROCA_RT
];

const FORMS_COM_DAM = [
	TIPO_FORMULARIO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_LICENCA_EXPRESSA,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
	TIPO_FORMULARIO_GENERICO,
	TIPO_FORMULARIO_GENERICO_SEM_ARTRRT,
	TIPO_FORMULARIO_GENERICO_SEM_VALIDACAO_RT,
	TIPO_FORMULARIO_GENERICO_RT_NAO_OBRIGATORIO,
	TIPO_FORMULARIO_APROVACAO_PROJETOS_LIC_EXPRESSO,
	TIPO_FORMULARIO_GENERICO_DAM_OBRIGATORIA,
	TIPO_FORMULARIO_ETR_NA_HORA,
	TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
	TIPO_FORMULARIO_LICENCAS_EXPRESSAS,
	TIPO_FORMULARIO_ADESAO_PRCHPA,
	TIPO_FORMULARIO_AJUSTE_PROJETOS,
	TIPO_FORMULARIO_VEICULO_DIVULGACAO
];

const FORMS_COM_DAM_OBRIGATORIA = [
	TIPO_FORMULARIO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_LICENCA_EXPRESSA,
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_GENERICO_DAM_OBRIGATORIA,
	TIPO_FORMULARIO_ETR_NA_HORA,
	TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
	TIPO_FORMULARIO_AJUSTE_PROJETOS,
	TIPO_FORMULARIO_VEICULO_DIVULGACAO,
	TIPO_FORMULARIO_LICENCAS_EXPRESSAS,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
	TIPO_FORMULARIO_ADESAO_PRCHPA,
	TIPO_FORMULARIO_APROVACAO_PROJETOS_LIC_EXPRESSO
];

const FORMS_GERAM_DOCUMENTO_NA_HORA = [
	TIPO_FORMULARIO_ETR_NA_HORA,
	TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
	TIPO_FORMULARIO_CERTIDAO_AVERBACAO
	//TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA                (REMOVIDO POIS TEM UMA LOGICA PROPRIA)
];

const FORMS_GERAM_LIC_TOLDO = [TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA];

export const EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS = 'planilha-edif-gerais';
export const EXTENSAO_DOC_ESPECIFICO_HABITESE = 'vistoria-habitese';
export const EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU = 'arrazoado-evu';
export const EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO = 'licenciamento-expresso-especifico';
export const EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS = 'licencas-expressas-especifico';
export const EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL = 'certificacao-sustentavel-formulario-especifico';
export const EXTENSAO_DOC_ESPECIFICO_UDRI = 'formulario-especifico-udri';
export const EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO = 'certidao-demolicao-documento-especifico';
export const EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS = 'aprovacao-projetos-documento-especifico';
export const EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA = 'adesao-prchpa-documento-especifico';
export const EXTENSAO_DOC_ESPECIFICO_VD = 'veiculo-divulgacao-documento-especifico';

export const EXTENSOES_DOCUMENTOS_ESPECIFICOS = [
	EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS,
	EXTENSAO_DOC_ESPECIFICO_HABITESE,
	EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU,
	EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
	EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
	EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL,
	EXTENSAO_DOC_ESPECIFICO_UDRI,
	EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO,
	EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
	EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA,
	EXTENSAO_DOC_ESPECIFICO_VD
];

const FORMS_AUTO_PREENCHER = [TIPO_FORMULARIO_EVENTOS, TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM];

const TEMP_ID = () => Math.random().toString(36).substr(2, 9);

export class Form extends Component {
	static displayName = 'Form';
	static contextType = ReactReduxContext;

	constructor(props) {
		debugLog('construtor');
		super(props);
		this.props.dispatchLoadFormAndData(this.props.match.params.idForm, this.props.match.params.id);
		let search = this.props.location.search;
		let aba = ABA_FORMULARIO;
		search = (size(search) > 0 ? search.substring(1) : '').split('&');
		search.forEach(property => {
			if (property.startsWith('aba=')) {
				try {
					aba = property.substring(4);
				} catch (error) {
					aba = ABA_FORMULARIO;
				}
			}
		});

		this.state = {
			user: props.keycloakUser || {},
			aba: aba,
			firstLoad: true,
			dadosOriginais: false,
			isBase: this.props.match.params.id.indexOf('base-') > -1 || this.props.match.params.id.indexOf('-base') > -1,
			mostrarMensagemCertidao: true,
			result: null
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		debugLog('getDerivedStateFromProps');
		const formulario = nextProps.formulario;
		let newState = { ...prevState, prevAba: prevState.aba };
		newState.showDamNoRequerimento = get(formulario, 'mostrarDamRequerimento', false) === true;

		if (prevState.processoGerado === undefined) {
			newState.processoGerado = get(formulario, 'formData.idProcedimentoSei') ? true : false;
		}
		if (nextProps.reload) {
			if (nextProps.reload.id === 'new') {
				newState.aba = ABA_FORMULARIO;
			} else if (prevState.aba === ABA_DAM) {
				newState.aba = ABA_DAM;
			} else if (FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario)) {
				newState.aba = ABA_ENQUADRAMENTO;
			} else {
				newState.aba = ABA_DOCUMENTOS;
			}
		} else {
			if (nextProps.result) {
				let nextAba = ABA_DOCUMENTOS;
				newState.result = nextProps.result;

				if (nextProps.result === 'aba_formulario_success' || nextProps.result === 'id_substituido') {
					if (FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario)) {
						nextAba = ABA_ENQUADRAMENTO;
					} else if (formulario?.idTipoFormulario === TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES) {
						nextAba = ABA_LAC;
						if (prevState.aba === ABA_LAC) {
							nextAba = ABA_DOCUMENTOS;
						}
					}
					newState.aba = nextAba;
				} else if (nextProps.result === 'aba_enquadramento_success') {
					newState.aba = ABA_DOCUMENTOS;
				} else if (nextProps.result === 'aba_documentos_success') {
					newState.aba = ABA_PROTOCOLO;
				} else if (nextProps.result === 'aba_termo_success') {
					newState.aba = ABA_PROTOCOLO;
					newState.recemGerado = true;
				} else if (nextProps.result === 'arquivos_excluidos') {
					newState.aba = ABA_DOCUMENTOS;
				} else if (nextProps.result === 'aba_dam_success') {
					newState.aba = ABA_TERMO;
				} else if (nextProps.result === 'aba_dam_error') {
					newState.aba = ABA_DAM;
				}
			}
		}
		if (prevState.firstLoad) {
			const temDocsInvalidos = (get(formulario, 'documentos') || []).reduce(
				(acc, d) => acc || (d.invalido && !d.substituido),
				false
			);
			const temPerguntasNaoRespondidas = (get(formulario, 'formData.extraInfo') || []).reduce(
				(acc, info) => acc || (size(info.pergunta) > 0 && size(trim(info.resposta)) === 0),
				false
			);

			const ehNovo = d => d.descricaoOutroDocumento && !d.idDocumentoSei;

			// proveniente de uma revisão (alteração de enquadramento, por exemplo)
			const ehRevisado = d => d.complementadaEm && !d.idDocumentoSei;

			const temDocsNovos = (get(formulario, 'documentos') || []).reduce(
				(acc, d) => acc || ehNovo(d) || ehRevisado(d),
				false
			);

			if (
				get(formulario, 'formData.dataComparecimento') &&
				(temDocsInvalidos || temPerguntasNaoRespondidas || temDocsNovos)
			) {
				newState.aba = ABA_DOCUMENTOS;
				newState.firstLoad = false;
			} else if (
				get(formulario, 'formData.data.dadosValidacaoIndeferimento') &&
				get(formulario, 'formData.resultado') === 'indeferido'
			) {
				newState.aba = ABA_DOCUMENTOS;
				newState.firstLoad = false;
			}
		}

		let motivos = {};
		if (get(formulario, 'documentos')) {
			formulario.documentos
				.filter(d => d.original && !d.idDocumentoSei)
				.forEach(d => {
					const index = findIndex(formulario.documentos, item => item.id === d.original);
					motivos[d.id] = [
						{
							msg: `Arquivo rejeitado pelo revisor: ${
								get(formulario, `documentos.${index}.motivo`) || 'não informou motivo'
							}`,
							resolvido: !!d.filename,
							type: 'warning'
						}
					];
				});
		}
		newState.motivos = motivos;

		// if (prevState.aba !== newState.aba) {
		// 	nextProps.dispatchGoAba(formulario.id, formulario.formData.id, newState.aba);
		// }
		return newState;
	}

	componentDidMount() {
		debugLog('componentDidMount');
		if (!this.props.keycloakUser || !this.props.usuario) {
			this.obtainUser();
		}
		if (!this.state.subscription) {
			this.obtainFiltroOrgao();
		}
		this.unlisten = this.props.history.listen(location => {
			const search = location.search ? location.search.substring(1).split('&') : [];
			let aba = ABA_FORMULARIO;
			search.forEach(property => {
				if (property.startsWith('aba=')) {
					try {
						aba = property.substring(4);
					} catch (error) {
						aba = ABA_FORMULARIO;
					}
				}
			});
			const abas = this.getAbas();
			const abaFormulario = abas[ABA_FORMULARIO];
			if (abas[aba].disabled() || !abas[aba].showOnlyIf) {
				aba = abaFormulario.showOnlyIf ? ABA_FORMULARIO : ABA_DOCUMENTOS;
			}
			this.setState({ aba });
		});

		// this.timer = setTimeout(() => {
		// 	this.setState({ mostrarMensagemCertidao: false });
		// }, 90000);
	}

	componentWillUnmount() {
		debugLog('componentWillUnmount');
		if (this.state.subscription) {
			this.state.subscription.unsubscribe();
		}
		this.props.dispatchSetFormulario(null);
		this.props.dispatchSetErrors(null);
		this.unlisten();
		// Limpa o temporizador
		if (this.timer) {
			clearTimeout(this.timer);
		}
	}

	componentDidUpdate(prevProps) {
		debugLog('componentDidUpdate');
		if (this.props.keycloakUser) {
			if (get(this.props, 'formulario.formData.bpmProcessInstance') && !this.props.bpmTasks) {
				const caseId = this.props.formulario.formData.bpmProcessInstance;
				this.props.dispatchObtainBpmTasks(caseId, this.props.keycloakUser.email);
			}
		}

		if (
			/licencas-expressas/.test(get(this.props.formulario, 'idTipoFormulario')) &&
			get(this.props.formulario, 'formData.resultado') === 'deferido'
		) {
			if (!this.state.documentoSeiLicenca) {
				this.obtainDocumentoSeiLicenca();
			}
		}

		if (this.props.reload && !prevProps.reload) {
			const { id, idFormulario } = this.props.reload;
			const { aba } = this.state;
			let naba = aba === ABA_DAM ? aba : ABA_DOCUMENTOS;
			this.props.dispatchUpdateURI(idFormulario, id, naba);
		} else {
			if (this.props.result && !prevProps.result) {
				this.props.dispatchSetResult(undefined);
			} else if (this.props.result === 'id_substituido') {
				this.props.dispatchSetResult(undefined);
			}
		}

		const abaFormulario = this.getAbas()[ABA_FORMULARIO];
		const abaDisabled = this.getAbas()[this.state.aba]?.disabled();
		const abaShowOnly = this.getAbas()[this.state.aba]?.showOnlyIf;
		if (size(this.state.aba) && (abaDisabled || !abaShowOnly)) {
			const qualAba = abaFormulario.showOnlyIf ? ABA_FORMULARIO : ABA_DOCUMENTOS;
			if (this.state.aba !== qualAba) {
				this.setState({ aba: qualAba });
			}
		}

		if (
			!this.state.optionCodram &&
			get(this.props.formulario, 'formData.data.atividadeEmpreendimento.atividade.codram')
		) {
			Form.getCodramObject(this.props.formulario.formData.data.atividadeEmpreendimento.atividade.codram).then(
				option => {
					this.setState({ optionCodram: option });
				}
			);
		}
	}

	obtainUser = async () => {
		debugLog('obtainUser');
		if (this.context && this.context.store) {
			if (this.state.user.name) {
				if (!this.props.usuario) {
					this.props.dispatchLoadUsuario(this.state.user);
				}
				return this.state.user;
			} else {
				await this.context.store.kc.loadUserInfo();
				await this.context.store.kc.loadUserProfile();
				const userInfo = Object.assign({}, this.context.store.kc.userInfo, this.context.store.kc.profile);
				const logout = this.context.store.kc.logout;
				const usuarioInterno = get(userInfo, 'attributes.LDAP_ID');
				this.setState({ user: userInfo, logout, usuarioInterno });
				this.props.dispatchUsuarioInterno(!!usuarioInterno);
				this.props.dispatchSetKcUser(userInfo);
				this.props.dispatchLoadUsuario(userInfo);
				return userInfo;
			}
		}
		return null;
	};

	obtainDocumentoSeiLicenca = async () => {
		const urlExpedientes = `EXPEDIENTES_API_URL/documento-sei/${get(
			this.props.formulario,
			'formData.procedimentoFormatadoSei'
		)}`;
		const res = await accessApi(urlExpedientes, true);
		const documentoSeiLicenca = res.data;
		if (get(documentoSeiLicenca, 'urlDocumentoSei')) {
			const newState = { documentoSeiLicenca };
			this.setState(newState);
		}
	};

	obtainFiltroOrgao = async () => {
		if (this.context && this.context.store) {
			const subscription = this.context.store.$filtros.subscribe(filtros => {
				if (filtros?.filtroOrgao) {
					const filtroOrgao = filtros.filtroOrgao;
					this.setState({ filtroOrgao });
				}
			});
			this.setState({ subscription });
		}
		return null;
	};

	//#region CODRAMERRORHANDLING
	static getCodramObject = async codram => {
		const result = await accessApi('/collections/consema-atividades-licenciaveis', true);
		const options = result.data.sort((o1, o2) => {
			const c1 = parseFloat(o1.codram.replace(',', '.'));
			const c2 = parseFloat(o2.codram.replace(',', '.'));
			return c1 > c2 ? 1 : -1;
		});
		let option = options.find(o => o.codram === codram);

		return option;
	};

	mapeiaLimitesCodram = codramObject => {
		let limites = {
			naoIncidencia: undefined,
			porteMinimo: undefined,
			portePequeno: undefined,
			porteMedio: undefined,
			porteGrande: undefined,
			porteExcepcional: undefined
		};

		if (codramObject?.naoIncidencia === 'todos os portes') {
			limites.naoIncidencia = 'todos os portes';
			return limites;
		}
		if (codramObject?.porteMinimo === 'Único') {
			limites.porteUnico = true;
			return limites;
		}

		Object.keys(codramObject || {}).forEach(key => {
			let auxString = '';
			switch (key) {
				case 'naoIncidencia':
					if (!isNil(codramObject[key])) {
						if (codramObject[key].startsWith('até ')) {
							limites[key] = ['0,00', codramObject[key].slice(4)];
						} else if (codramObject[key].startsWith('até')) {
							limites[key] = ['0,00', codramObject[key].slice(3)];
						}
					} else {
						limites[key] = null;
					}
					break;
				case 'porteMinimo':
					if (codramObject[key].startsWith('até ')) {
						limites[key] = ['0,00', codramObject[key].slice(4)];
					} else if (codramObject[key].startsWith('até')) {
						limites[key] = ['0,00', codramObject[key].slice(3)];
					} else {
						if (codramObject[key].startsWith('de ')) {
							auxString = codramObject[key].slice(3);
						} else {
							auxString = codramObject[key].slice(2);
						}
						auxString = auxString.split(' ');
						limites[key] = [auxString[0], auxString[2]];
					}
					break;
				case 'portePequeno':
				case 'porteMedio':
				case 'porteGrande':
					if (codramObject[key].startsWith('de ')) {
						auxString = codramObject[key].slice(3);
					} else {
						auxString = codramObject[key].slice(2);
					}
					auxString = auxString.split(' ');
					limites[key] = [auxString[0], auxString[2]];
					break;
				case 'porteExcepcional':
					if (codramObject[key] === 'demais') {
						limites[key] = codramObject[key];
					} else if (!isNil(codramObject[key])) {
						auxString = codramObject[key].slice('acima de '.length);
					}
					break;
				default:
					break;
			}
		});
		return limites;
	};

	classificaPorte = async (codram, medidaPorte) => {
		let codramObject = await Form.getCodramObject(codram);

		const limites = this.mapeiaLimitesCodram(codramObject);
		let tipoPorte = undefined;

		if (limites.naoIncidencia === 'todos os portes') {
			tipoPorte = 'naoIncidencia';
		} else if (limites?.porteUnico) {
			tipoPorte = 'porteMinimo';
		} else if (
			Array.isArray(limites.porteMinimo) &&
			parseFloat(limites.porteMinimo[0]) <= medidaPorte &&
			parseFloat(limites.porteMinimo[1]) >= medidaPorte
		) {
			tipoPorte = 'porteMinimo';
		} else if (
			Array.isArray(limites.portePequeno) &&
			parseFloat(limites.portePequeno[0]) <= medidaPorte &&
			parseFloat(limites.portePequeno[1]) >= medidaPorte
		) {
			tipoPorte = 'portePequeno';
		} else if (
			Array.isArray(limites.porteMedio) &&
			parseFloat(limites.porteMedio[0]) <= medidaPorte &&
			parseFloat(limites.porteMedio[1]) >= medidaPorte
		) {
			tipoPorte = 'porteMedio';
		} else if (
			Array.isArray(limites.porteGrande) &&
			parseFloat(limites.porteGrande[0]) <= medidaPorte &&
			parseFloat(limites.porteGrande[1]) >= medidaPorte
		) {
			tipoPorte = 'porteGrande';
		} else if (Array.isArray(limites.naoIncidencia) && parseFloat(limites.naoIncidencia[0]) <= medidaPorte) {
			tipoPorte = 'naoIncidencia';
		} else {
			tipoPorte = 'porteExcepcional';
		}

		// console.log('medidaPorte', medidaPorte);
		// console.log('tipoPorte', tipoPorte);
		// console.log(
		// 	'codramObject?.competenciaMunicipal.includes(tipoPorte)',
		// 	codramObject?.competenciaMunicipal.includes(tipoPorte)
		// );

		return codramObject?.competenciaMunicipal.includes(tipoPorte)
			? 'protocolar'
			: tipoPorte === 'naoIncidencia'
			? 'bloquear'
			: 'restringir';
	};
	//#endregion CODRAMERRORHANDLING

	onChangeHandler = data => {
		debugLog('onChangeHandler');
		let formData = data.formData;
		if (formData.interessados) {
			if (formData.interessados.proprietarioRT) {
				formData.interessados.proprietario = window.structuredClone(
					omit(formData.interessados.rt, ['registroProfissional'])
				);
				formData.interessados.proprietario.cpfCnpj = formData.interessados.rt.cpf;
				delete formData.interessados.proprietarioRT;
			}
			const {
				interessados: { rt }
			} = formData;
			const { registroProfissional } = rt;
			if (registroProfissional) {
				if (size(rt.nome) === 0 && size(registroProfissional.nome) > 0) {
					rt.nome = titleCase(registroProfissional.nome);
				}
				if (size(rt.cpf) === 0 && size(registroProfissional.cpf) > 0) {
					rt.cpf = registroProfissional.cpf;
				}
			}
		}
		const saida = {
			...this.props.formulario.formData,
			id: this.props.match.params.id,
			idFormulario: this.props.match.params.idForm,
			data: formData
		};
		return this.props.dispatchSetFormData(saida);
	};

	onSubmitHandler = ({ formData: formDataData, extras } = {}) => {
		debugLog('onSubmitHandler');

		const validarDamNoForm = this.state.showDamNoRequerimento === true;
		this.props.dispatchSetErrors({});

		setTimeout(() => {
			const title = get(this.props, 'formulario.nome') || get(this.props, 'formulario.schema.title');
			// se ja tem sei atualiza apenas o campo identificacao
			const jaTemSei = get(this.props, 'formulario.formData.idProcedimentoSei');
			const dados = {
				id: this.props.match.params.id,
				idFormulario: this.props.match.params.idForm,
				data: jaTemSei ? { identificacao: formDataData.identificacao } : formDataData,
				outrosDocumentos: extras?.outrosDocumentos,
				usuario: get(this.props, 'formulario.formData.usuario') || this.state.user,
				documentos: get(this.props, 'formulario.documentos'),
				dadosFormulario: pick(
					get(this.props, 'formulario'),
					!isNil(get(this.props, 'formulario.codOcorrenciaLicenca'))
						? ['idTipoFormulario', 'idUnidadeSei', 'idTipoProcessoSei', 'idSetor', 'codOcorrenciaLicenca']
						: ['idTipoFormulario', 'idUnidadeSei', 'idTipoProcessoSei', 'idSetor']
				),
				validarDamNoForm
			};
			dados.dadosFormulario.title = title;
			this.props.dispatchSendFormData(dados);
		}, 100);
	};

	/**
	 *
	 * @param {*} formDataData data do template form
	 */
	onSubmitLicencaMetaForm = formDataData => {
		const { formulario } = this.props;
		debugLog('onSubmitLicencaMetaForm', formDataData);
		let extras = {};
		if (formDataData.sendReadOnly === true) {
			this.onSubmitHandler({ formData: { identificacao: formDataData?.identificacao } });
		} else if (formDataData) {
			if (size(formDataData.expediente) > 0 && !get(formDataData, 'expediente._id')) {
				delete formDataData.expediente;
			}
			if (
				[TIPO_FORMULARIO_ETR_NA_HORA].includes(formulario?.idTipoFormulario) &&
				formDataData.instalacaoAreaEscriturada === 'nao'
			) {
				// licença ETR com área não escriturada
				extras.outrosDocumentos = ['ETR-LAUDO-DETALHADO-AREA-NAO-ESCRITURADA'];
			} else if (
				[TIPO_FORMULARIO_ETR_NA_HORA].includes(formulario?.idTipoFormulario) &&
				formDataData.instalacaoAreaEscriturada === 'sim'
			) {
				// licença ETR com área escriturada
				extras.outrosDocumentos = ['!ETR-LAUDO-DETALHADO-AREA-NAO-ESCRITURADA'];
			} else if (
				[TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO].includes(formulario?.idTipoFormulario) &&
				formDataData.instalacaoAreaEscriturada === 'sim' &&
				formDataData.temLicencaEtr2Anos
			) {
				// licença ETR RENOVAÇÃO comprovar matricula do laudo anterior de área não escriturada
				extras.outrosDocumentos = ['ETR-RENOVACAO-MATRICULA-OU-CERTIDAO-DE-AREA-NAO-ESCRITURADA'];
			} else if ([TIPO_FORMULARIO_APROVACAO_PROJETOS].includes(formulario?.idTipoFormulario)) {
				const isCentroHistorico = formDataData.enderecoCdlList.find(e => e.regiao === 'centro_historico');
				const isQuartoDistrito = formDataData.enderecoCdlList.find(e => e.regiao === 'quarto_distrito');
				if (isCentroHistorico || isQuartoDistrito) {
					extras.outrosDocumentos = ['aprovacao-projetos-formulario-offline'];
				} else {
					extras.outrosDocumentos = ['!aprovacao-projetos-formulario-offline'];
				}
			}
			if (size(extras.outrosDocumentos) > 0) {
				formDataData.outrosDocumentos = extras.outrosDocumentos;
			}
			this.onSubmitHandler({ formData: formDataData, extras });
		} else {
			/* só avançar */
			const requerEnquadramento = FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario);
			this.setState({ aba: requerEnquadramento ? ABA_ENQUADRAMENTO : ABA_DOCUMENTOS });
		}
	};

	houveAlteracaoEnquadramento = result => {
		const { respostas = null, etapas = null, extrasObject: extras } = result;
		const { definicao = null, codOcorrencia = null } = extras || {};
		const enquadramentoAntigo = this.props.formulario?.formData?.data?.enquadramento;
		const {
			definicao: definicaoAntiga = null,
			codOcorrencia: codOcorrenciaAntigo = null,
			etapas: etapasAntigas = null,
			respostas: respostasAntigas = null
		} = enquadramentoAntigo || {};

		let alterado = false;

		// critérios para considerar se o enquadramento alterou e deve resetar o formulário específico
		const perguntarAoAndreSeDeveConsiderar = true;
		if (perguntarAoAndreSeDeveConsiderar) {
			alterado = alterado || definicao !== definicaoAntiga;
			alterado = alterado || codOcorrencia !== codOcorrenciaAntigo || null;
			alterado = alterado || !deepEqual(etapas, etapasAntigas);
		}

		alterado = alterado || !deepEqual(respostas, respostasAntigas);

		return alterado;
	};

	onSubmitEnquadramento = result => {
		debugLog('onSubmitEnquadramento');
		this.props.dispatchSetErrors({});
		const idsDocsEspecificos = get(this.props.formulario, 'documentosDados', [])?.map(d => d.id);
		const { nomeLista, respostas, etapas, extrasObject: extras, metadados, dataPromocao } = result;
		const {
			documentos: outrosDocumentos,
			definicao,
			codOcorrencia,
			respostaSelecionadaNum,
			...outrosExtras
		} = extras || {};
		setTimeout(() => {
			const data = {
				...outrosExtras,
				id: this.props.match.params.id,
				usuario: get(this.props, 'formulario.formData.usuario') || this.state.user,
				nomeLista,
				outrosDocumentos,
				definicao,
				codOcorrencia,
				respostas,
				etapas,
				metadados,
				respostaSelecionadaNum,
				dataPromocao
			};
			data.alterado = this.houveAlteracaoEnquadramento(data);
			if (data.alterado) {
				data.idsDocsEspecificos = idsDocsEspecificos;
			}
			this.props.dispatchSendEnquadramentoData(data);
			this.props.dispatchUpdateURI(this.props.match.params.idForm, this.props.match.params.id, ABA_DOCUMENTOS);
		}, 100);
	};

	onSubmitNextAba2 = (formData, extras) => {
		debugLog('onSubmitNextAba2');
		if (formData) {
			this.onSubmitHandler({ formData }, extras);
		} else {
			this.setState({ aba: ABA_DOCUMENTOS });
		}
	};

	toggleDadosOriginais = () => this.setState(old => ({ dadosOriginais: !old.dadosOriginais }));

	onDrop = d => (accepted, rejected) => {
		debugLog('onDrop');
		if (size(rejected) === 0 && size(accepted) === 1) {
			const file = accepted[0];
			if (file.name.length > 100) {
				this.props.dispatchSetErrors({ send: ['O nome do arquivo pode ter no máximo 100 caracteres.'] });
			} else if (accepted[0].size >= 100000000) {
				this.props.dispatchSetErrors({ send: ['O tamanho máximo de upload é de 100MB por arquivo.'] });
			} else {
				const idFormulario = this.props.match.params.idForm;
				const idFormData = this.props.match.params.id;
				this.props.dispatchSetErrors(omit(this.props.errors, [d.id]));
				getUpdatedToken().then(
					token => {
						this.props.dispatchUploadFile({
							idFormulario,
							idFormData,
							id: d.id,
							idDocumento: d.idDocumento,
							tituloDocumento: d.tituloDocumento,
							obrigatorio: d.obrigatorio,
							extensao: d.extensao,
							ordem: d.ordem,
							file,
							token,
							complementadaEm: d.complementadaEm,
							owner: d.owner,
							fromSolicitante: d.fromSolicitante
						});
					},
					error => {
						this.props.dispatchSetErrors({ send: [error] });
					}
				);
			}
		} else if (size(rejected) + size(accepted) > 1) {
			this.props.dispatchSetErrors({ send: ['Apenas 1 arquivo pode ser submetido em cada documento'] });
		} else {
			this.props.dispatchSetErrors({
				send: [`Tipo de arquivo inválido. Selecione um arquivo do tipo ${(d.extensao || '').toUpperCase()}`]
			});
		}
	};

	onValidate = (formData, errors) => {
		debugLog('onValidate');

		if (formData.expediente) {
			const asyncErrors = Expediente.onValidate(formData.expediente, errors);
			this.setState({ asyncErrors });
		}

		if (formData.local) {
			let enderecos = get(formData, 'local.enderecoCdlList.enderecosCDL')
				? formData.local.enderecoCdlList.enderecosCDL.filter(e => !!e)
				: [];
			if (size(enderecos) === 0) {
				errors.local.enderecoCdlList.enderecosCDL.addError('Você deve adicionar ao menos um endereço');
			}
		}

		if (formData.atividadeEmpreendimento) {
			let enderecos = get(formData, 'atividadeEmpreendimento.enderecoCdlList.enderecosCDL')
				? formData.atividadeEmpreendimento.enderecoCdlList.enderecosCDL.filter(e => !!e)
				: [];
			if (size(enderecos) === 0) {
				errors.atividadeEmpreendimento.enderecoCdlList.enderecosCDL.addError(
					'Você deve adicionar ao menos um endereço'
				);
			}
		}

		if (formData.interessados) {
			const registroProfissional = get(formData, 'interessados.rt.registroProfissional');
			if (registroProfissional) {
				if (size(registroProfissional.error) > 0) {
					const msg =
						registroProfissional.error.indexOf('nao localizado') > -1
							? 'Registro inválido'
							: registroProfissional.error;
					errors.interessados.rt.registroProfissional.numeroRegistro.addError(msg);
				} else {
					if (size(formData.interessados.rt.cpf) > 0 && size(formData.interessados.rt.registroProfissional.cpf) > 0) {
						if (formData.interessados.rt.cpf !== formData.interessados.rt.registroProfissional.cpf) {
							errors.interessados.rt.cpf.addError(
								`Este CPF não é o registrado no ${formData.interessados.rt.registroProfissional.tipo}`
							);
						}
					}
				}
			}
			const cpfRt = get(formData, 'interessados.rt.cpf');
			if (cpfRt) {
				if (!isCPF(cpfRt)) {
					errors.interessados.rt.cpf.addError('Deve ser um CPF válido');
				}
			}
			const cpfCnpjProprietario = get(formData, 'interessados.proprietario.cpfCnpj');
			if (cpfCnpjProprietario) {
				if (!(isCPF(cpfCnpjProprietario) || isCNPJ(cpfCnpjProprietario))) {
					errors.interessados.proprietario.cpfCnpj.addError('Deve ser um CPF ou CNPJ válido');
				}
			}
			const emailRT = get(formData, 'interessados.rt.email');
			if (emailRT) {
				if (!isEmail(emailRT)) {
					errors.interessados.rt.email.addError('Deve ser um email válido');
				}
			}
			const emailProprietario = get(formData, 'interessados.proprietario.email');
			if (emailProprietario) {
				if (!isEmail(emailProprietario)) {
					errors.interessados.proprietario.email.addError('Deve ser um email válido');
				}
			}

			const requerente = get(formData, 'interessados.requerente');
			if (requerente) {
				if (requerente.enderecoCDL && requerente.enderecoCDL.numero && !requerente.enderecoCDL.codigoCDL) {
					errors.interessados.requerente.enderecoCDL.addError(
						'Você deve selecionar um logradouro da lista de pesquisa'
					);
				}
			}

			const tpTipo = get(formData, 'interessados.proprietario.tipoProprietario.tipo');
			if (TIPO_PROPRIETARIO_OUTROS === tpTipo) {
				const tpOutros = get(formData, 'interessados.proprietario.tipoProprietario.outros');
				if (size(tpOutros) === 0) {
					errors.interessados.proprietario.tipoProprietario.outros.addError('Informe o outro tipo de proprietário');
				}
			}
		}

		const taTipo = get(formData, 'tipoAutorizacao.tipo');
		if (TIPO_AUTORIZACAO_OUTROS === taTipo) {
			const taOutros = get(formData, 'tipoAutorizacao.outros');
			if (size(taOutros) === 0) {
				errors.tipoAutorizacao.outros.addError('Informe o outro tipo de autorização');
			}
		}
		if (TIPO_AUTORIZACAO_PRAZO === taTipo) {
			const taPrazo = get(formData, 'tipoAutorizacao.prazo');
			if (size(taPrazo) === 0) {
				errors.tipoAutorizacao.prazo.addError('Informe o prazo de prorrogação');
			}
		}
		/** 49544 */
		if (formData.proprietario) {
			const pcpf = get(formData, 'proprietario.cpfCnpj');
			if (pcpf && !(isCPF(pcpf) || isCNPJ(pcpf))) {
				errors.proprietario.cpfCnpj.addError('Deve ser um CPF ou CNPJ válido');
			}
			const pemail = get(formData, 'proprietario.email');
			if (pemail && !isEmail(pemail)) {
				errors.proprietario.email.addError('Deve ser um email válido');
			}
			const pphone = get(formData, 'proprietario.telefone');
			if (pphone && !isTelefone(pphone)) {
				errors.proprietario.telefone.addError('Deve ser um telefone válido');
			}
		}
		if (formData.rt) {
			const rtcpf = get(formData, 'rt.cpf');
			if (rtcpf && !isCPF(rtcpf)) {
				errors.rt.cpf.addError('Deve ser um CPF válido');
			}
			const rtemail = get(formData, 'rt.email');
			if (rtemail && !isEmail(rtemail)) {
				errors.rt.email.addError('Deve ser um email válido');
			}
			const ert = get(formData, 'rt.registroProfissional.error');
			const esrt = get(formData, 'rt.registroProfissional.situacao');
			if (esrt === 'ERRO') {
				errors.rt.registroProfissional.addError(ert);
			}
		}

		if (formData?.atividadeEmpreendimento?.atividade) {
			onValidate({ atividade: formData?.atividadeEmpreendimento?.atividade, option: this.state.optionCodram }, errors);
		}

		return errors;
	};

	transformErrors = errors => {
		debugLog('transformErrors');
		const saida = errors.map(error => {
			let newError = error;
			const i = originalMessages.indexOf(error.message);
			if (i > -1) {
				newError = {
					...error,
					message: translatedMessages[i]
				};
			}
			return newError;
		});
		return saida;
	};

	avancar2to3 = () => {
		debugLog('avancar2to3');
		let errors = [];
		const formulario = this.props.formulario;
		const { showDamNoRequerimento } = this.state;
		const requerDam = FORMS_COM_DAM.includes(formulario?.idTipoFormulario);

		if (size(get(formulario, 'formData.resultado')) === 0) {
			const docsErrors = formulario.documentos.reduce((acc, d) => {
				if (d.obrigatorio && !d.filename) {
					if ([EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL, EXTENSAO_DOC_ESPECIFICO_VD].includes(d.idDocumento)) {
						acc[d.id] = [
							`Arquivo '${d.tituloDocumento}' marcado como obrigatório (*) deve ser gerado, clique no link acima para editar, verifique todos os campos e logo abaixo clique em 'Salvar e Gerar o PDF'`
						];
					} else {
						acc[d.id] = [`Arquivo '${d.tituloDocumento}' marcado como obrigatório (*) deve ser enviado`];
					}
				}
				return acc;
			}, {});
			if (size(docsErrors) > 0) {
				errors.push(docsErrors);
			}
		}

		if (size(errors) === 0) {
			this.props.dispatchSetErrors({});
			const aba = showDamNoRequerimento === false && requerDam ? ABA_DAM : ABA_TERMO;
			this.props.dispatchGoAba(formulario.id, formulario.formData.id, aba);
			this.setState({ aba });
		} else {
			this.props.dispatchSetErrors(Object.assign(...errors));
		}
	};

	onAceitarTermos = (concordo, { termoResponsabilidade }) => {
		debugLog('onAceitarTermos');
		this.props.dispatchAceitarTermo({ concordo, termoAceito: termoResponsabilidade });
	};

	onAvancarTermosHandler = e => {
		debugLog('onAvancarTermosHandler');
		e.preventDefault();
		const { formulario } = this.props;
		const resultado = get(formulario, 'formData.resultado');

		if (TIPO_FORMULARIO_SOLICITACAO_EVENTOS_BPM === formulario.idTipoFormulario) {
			this.props.dispatchSetErrors({});
			this.props.dispatchSetLoading(true);
			verificaFeiraDeRua(formulario.formData.data).then(res => {
				if (res === 0) {
					this.sendFormCompleto();
				} else {
					this.props.dispatchSetErrors({ termos: ['Um outro evento já foi protocolado para esta data e local.'] });
				}
			});
		} else if (size(resultado) === 0 && !get(formulario, 'formData.procedimentoFormatadoSei')) {
			this.sendFormCompleto();
		} else {
			this.setState({ aba: ABA_PROTOCOLO });
		}
	};

	obtemDocumentoEspecificoUltimaVersao = () => {
		debugLog('obtemComplementadaEmDocumento');
		const { formulario } = this.props;
		const { documentos } = formulario || {};
		const documento = (documentos || []).reduce((acc, doc) => {
			if ([EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL, EXTENSAO_DOC_ESPECIFICO_VD].includes(doc.extensao)) {
				if (acc) {
					return doc.versao > acc.versao ? doc : acc;
				} else {
					return doc;
				}
			}
			return acc;
		}, null);
		return documento;
	};

	sendFormCompleto = () => {
		debugLog('sendFormCompleto');
		if (this.faltamArquivos()) {
			const verbo = this.state.processoGerado ? 'alterar' : 'criar';
			this.props.dispatchSetErrors({
				send: [`Você deve informar todos os arquivos obrigatorios(*) antes de ${verbo} o processo`]
			});
		} else {
			this.props.dispatchSetErrors({});
			setTimeout(() => {
				this.props.dispatchSendFormCompleto(this.props.formulario, this.state.filtroOrgao);
			}, 100);
		}
	};

	removeFile = documento => this.props.dispatchRemoveFile({ ...documento, idFormData: this.props.match.params.id });
	removeFileHandler = documento => {
		this.setState({ isRemoveFileModalAtive: true, removeFileAux: documento });
	};

	retiraDocumento = payload => this.props.dispatchRetiraDocumento(payload);

	limpaDocumentosDados = documento => {
		if (documento) {
			const indice = this.props.formulario.documentosDados?.findIndex(d => d.id === documento.id);
			if (!isNil(indice) && indice > -1) {
				this.setState({ indiceLimpar: indice, showPopupLimparDocumentosDados: true });
			}
		}
	};

	togglePromptTituloDocumento = complementadaEm => {
		const promptTituloDocumento = !this.state.promptTituloDocumento;
		this.setState({ promptTituloDocumento, complementadaEm });
	};

	criarNovoDocumento = titulo => {
		debugLog('criarNovoDocumento');
		const ordem = this.props.formulario.documentos.reduce((acc, o) => (o.ordem && o.ordem > acc ? o.ordem : acc), 0);
		const newDoc = {
			textoHelp: null,
			ordem: ordem + 100,
			extensao: get(this.state, 'extensao.id') === 'dwg' ? 'dwg' : 'pdf',
			obrigatorio: false,
			idDocumento: uuid(),
			id: uuid(),
			linkHelp: null,
			tituloDocumento: titulo,
			versao: 1,
			newDoc: true, // Para verificação de doc que é criado pelo usuário
			fromSolicitante: true // Para verificação de doc que é criado pelo usuário
		};

		if (this.state.complementadaEm) {
			newDoc.owner = this.state.complementadaEm.substring(0, 2);
			newDoc.complementadaEm = this.state.complementadaEm;
		}
		this.props.dispatchAddNewDocument(newDoc);
	};

	addMultiDocumentos = docPai => {
		debugLog('criarMultiNovoDocumento');
		const dg = this.props.formulario.documentos.filter(d => d.docGroup === docPai.id);
		let ordem = (docPai.ordem || 100) + dg.length + 1;
		const newDoc = {
			textoHelp: null,
			ordem: ordem,
			extensao: get(this.state, 'extensao.id') === 'dwg' ? 'dwg' : 'pdf',
			obrigatorio: false,
			idDocumento: `${docPai.idDocumento}-${ordem % 100}`,
			id: uuid(),
			linkHelp: null,
			tituloDocumento: `${docPai.tituloDocumento}-${ordem % 100}`,
			versao: 1,
			docGroup: docPai.id,
			newDoc: true,
			fromSolicitante: true, // Para verificação de doc que é criado pelo usuário
			complementadaEm: docPai.complementadaEm
		};
		const docEspecificoUltimaVersao = this.obtemDocumentoEspecificoUltimaVersao();
		if (docEspecificoUltimaVersao?.complementadaEm) {
			newDoc.complementadaEm = docEspecificoUltimaVersao.complementadaEm;
		}
		if (docEspecificoUltimaVersao?.owner) {
			newDoc.owner = docEspecificoUltimaVersao.owner;
		}
		this.props.dispatchAddNewDocument(newDoc);
	};

	faltamArquivos = () => {
		const { formulario } = this.props;
		const qtdObrigatorios = (formulario.documentos || []).reduce(
			(acc, d) => (acc += d.obrigatorio && !d.invalido ? 1 : 0),
			0
		);
		const consideraValido = invalido => (formulario.formData.dataComparecimento ? !invalido : true);
		const qtd =
			size(formulario.documentos) > 0
				? formulario.documentos.reduce(
						(acc, d) => (acc += d.filename && d.obrigatorio && consideraValido(d.invalido) ? 1 : 0),
						0
				  )
				: 0;
		return qtd < qtdObrigatorios;
	};

	docsCompletos = formulario => {
		const qtdObrigatorios = formulario.documentos.reduce((acc, d) => (acc += d.obrigatorio && !d.invalido ? 1 : 0), 0);
		const qtd =
			size(formulario.documentos) > 0
				? formulario.documentos.reduce((acc, d) => (acc += d.idDocumentoSei && d.obrigatorio && !d.invalido ? 1 : 0), 0)
				: 0;
		return qtd >= qtdObrigatorios;
	};

	complementarProcesso = taskName => {
		const formulario = this.props.formulario;
		let errors = [];

		const docsErrors = this.obtainDocumentos().reduce((acc, d) => {
			const docOriginal = formulario.documentos.find(dO => dO.id === d.original);

			if (!taskName || taskName === d.complementadaEm) {
				if (d.obrigatorio && !d.filename) {
					acc[d.id] = ['Arquivo marcado como obrigatório (*) deve ser enviado'];
				} else if (docOriginal?.invalido && !docOriginal?.substituido && !d.filename) {
					acc[d.id] = ['Arquivo rejeitado pelo revisor se torna obrigatório e deve ser preenchido'];
				}
			}
			return acc;
		}, {});

		if (size(docsErrors) > 0) {
			errors.push(docsErrors);
		}

		if (size(errors) === 0) {
			const { formulario } = this.props;
			const { email, preferred_username } = this.state.user || {};
			const username = email || preferred_username;

			if (size(get(formulario, 'formData.extraInfo')) > 0) {
				formulario.formData.extraInfo = formulario.formData.extraInfo.map(info =>
					info.naoRespondida && ((!taskName && !info.complementadaEm) || taskName === info.complementadaEm)
						? {
								...info,
								resposta: size(trim(info.resposta)) === 0 ? 'n/c' : info.resposta,
								quemRespondeu: username,
								quemRespondeuNome: get(this, 'state.user.name', ''),
								quandoRespondeu: moment().format('YYYY-MM-DD HH:mm:ss')
						  }
						: info
				);
			}

			if (
				[
					TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO,
					TIPO_FORMULARIO_LICENCAS_EXPRESSAS,
					TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL
				].includes(formulario?.idTipoFormulario)
			) {
				if (size(get(formulario, 'documentosDados')) > 0) {
					const docDados = get(formulario, 'documentosDados.0.dados');
					const newDocDados = { ...docDados };

					if (newDocDados.restricoesAdministrativas) {
						Object.keys(newDocDados.restricoesAdministrativas).forEach(propName => {
							if (propName.endsWith('AlteradaRevisor')) {
								delete newDocDados.restricoesAdministrativas[propName];
							}
						});
					}

					Object.keys(newDocDados).forEach(propName => {
						if (propName.endsWith('AlteradaRevisor')) {
							delete newDocDados[propName];
						}
					});

					formulario.documentosDados[0].dados = newDocDados;
					if (formulario.formData.documentosDados[0]?.dados) {
						formulario.formData.documentosDados[0].dados = newDocDados;
					} else {
						formulario.formData.documentosDados = formulario.documentosDados;
					}
				}
			}

			this.props.dispatchComplementarProcesso(
				formulario,
				username,
				taskName,
				this.props.createMessage,
				this.state.filtroOrgao
			);
		} else {
			this.props.dispatchSetErrors(Object.assign(...errors));
		}
	};

	obtemRootAtual = data => {
		let rootAtual = null;
		const respostas = get(data, 'enquadramento.respostas') || {};
		for (const key in respostas) {
			const resposta = respostas[key];

			if (resposta?.startsWith(key)) {
				rootAtual = resposta;
			}
		}
		return rootAtual;
	};

	getAbas = () => {
		const { formulario, match } = this.props;
		const requerEnquadramento = FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario);
		const requerDam = FORMS_COM_DAM.includes(formulario?.idTipoFormulario);
		const damObrigatoria = FORMS_COM_DAM_OBRIGATORIA.includes(formulario?.idTipoFormulario);
		const isSmamsLAC = get(formulario, 'idTipoFormulario') === TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES;
		const aindaSemEnquadramento = size(get(formulario, 'formData.data.enquadramento')) === 0;
		const aindaSemNumeroDam =
			size(trim(get(formulario, 'formData.data.numeroDam'))) === 0 && !get(formulario, 'formData.data.ignoraDam');
		const aindaSemValorDam =
			size(trim(get(formulario, 'formData.data.valorDam'))) === 0 && !get(formulario, 'formData.data.ignoraDam');
		const showDamNoRequerimento = this.state && this.state.showDamNoRequerimento;
		const deveDesabilitarAbaDam =
			match.params.id === 'new' ||
			!this.props.formulario ||
			(this.faltamArquivos() && !get(formulario, 'formData.idProcedimentoSei')) ||
			(requerEnquadramento && aindaSemEnquadramento) ||
			(damObrigatoria && (aindaSemNumeroDam || aindaSemValorDam));

		const tituloAbaFormulario = isSmamsLAC ? 'Rel. de Caracterização do Empreendimento' : 'Requerimento';

		return {
			[ABA_FORMULARIO]: {
				title: tituloAbaFormulario,
				showOnlyIf: ![TIPO_FORMULARIO_BOLETOS_UDRI, TIPO_FORMULARIO_DIGITALIZACAO].includes(
					formulario?.idTipoFormulario
				),
				disabled: () => false
			},
			[ABA_LAC]: {
				title: 'Atividade e taxa de licenciamento',
				showOnlyIf: [TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES],
				disabled: () => match.params.id === 'new'
			},
			[ABA_ENQUADRAMENTO]: {
				title: 'Enquadramento',
				showOnlyIf: FORMS_COM_ENQUADRAMENTO,
				disabled: () => match.params.id === 'new'
			},
			[ABA_DOCUMENTOS]: {
				title: 'Documentos',
				showOnlyIf: true,
				disabled: () => match.params.id === 'new' || (requerEnquadramento && aindaSemEnquadramento)
			},
			[ABA_DAM]: {
				title: 'DAM/Taxa',
				showOnlyIf: showDamNoRequerimento === false && requerDam,
				disabled: () =>
					match.params.id === 'new' ||
					!this.props.formulario ||
					(this.faltamArquivos() && !get(formulario, 'formData.idProcedimentoSei')) ||
					(requerEnquadramento && aindaSemEnquadramento)
			},
			[ABA_TERMO]: {
				title: [TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO, TIPO_FORMULARIO_LICENCAS_EXPRESSAS].includes(
					formulario?.idTipoFormulario
				)
					? 'Termo de Responsabilidade'
					: 'Termo de Notificação',
				showOnlyIf: ![TIPO_FORMULARIO_BOLETOS_UDRI, TIPO_FORMULARIO_DIGITALIZACAO].includes(
					formulario?.idTipoFormulario
				),
				disabled: () => deveDesabilitarAbaDam
			},
			[ABA_PROTOCOLO]: {
				title: 'Protocolo',
				showOnlyIf: true,
				disabled: () => !get(formulario, 'formData.idProcedimentoSei')
			}
		};
	};

	submitAbaDam = formdata => {
		debugLog('submitAbaDam');
		const { formulario } = this.props;
		const damObrigatoria = FORMS_COM_DAM_OBRIGATORIA.includes(formulario?.idTipoFormulario);

		this.props.dispatchSetErrors({});
		const dados = {
			id: this.props.match.params.id,
			idFormulario: this.props.match.params.idForm,
			data: formdata,
			damObrigatoria,
			dadosFormulario: pick(
				get(this.props, 'formulario'),
				!isNil(get(this.props, 'formulario.codOcorrenciaLicenca'))
					? ['idTipoFormulario', 'idUnidadeSei', 'idTipoProcessoSei', 'idSetor', 'codOcorrenciaLicenca']
					: ['idTipoFormulario', 'idUnidadeSei', 'idTipoProcessoSei', 'idSetor']
			)
		};
		this.props.dispatchAbaDam(dados);
		this.props.dispatchGoAba(formulario.id, formulario.formData.id, ABA_DAM);
	};

	// Metodo para remover  possibilidade de avancar para outras abas
	// Solicitado pelo cliente para criacao de um tipo de processo vazio, de carater informativo
	exibirBotaoAvancar = () => {
		const { formulario } = this.props;
		if (get(formulario, 'idTipoFormulario') === 'formularioVazio') {
			return false;
		} else {
			return true;
		}
	};

	ehNovo = d => d.descricaoOutroDocumento && !d.idDocumentoSei;

	// proveniente de uma revisão (alteração de enquadramento, por exemplo)
	ehRevisado = d => d.complementadaEm && !d.idDocumentoSei;
	ehInvalido = d => {
		const substituto = ((this.props.formulario || {}).documentos || []).find(doc => doc.original === d.id);
		return d.invalido && !d.substituido && !(substituto || {}).idDocumentoSei;
	};
	ehOpcionalPreenchido = d =>
		!d.obrigatorio && !d.descricaoOutroDocumento && !d.invalido && d.filename && !d.idDocumentoSei;
	ehSubstituto = d => {
		const original = ((this.props.formulario || {}).documentos || []).find(doc => doc.id === d.original);
		return d.original && !d.idDocumentoSei && original && d.versao > original.versao;
	};
	ehFromSolicitante = d => !d.idDocumentoSei && d.fromSolicitante;

	obtainDocumentos = () => {
		const { formulario } = this.props;

		const rascunhoDocsComplementacao = !!get(formulario, 'formData.rascunhoDocsComplementacao');

		const qtdInvalidos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehInvalido(d) ? 1 : 0),
			0
		);
		const qtdNovos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehNovo(d) || this.ehRevisado(d) ? 1 : 0),
			0
		);
		const qtdOpcionaisPreenchidos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehOpcionalPreenchido(d) ? 1 : 0),
			0
		);
		const qtdPerguntasNaoRespondidas = (get(formulario, 'formData.extraInfo') || []).reduce(
			(acc, info) => (acc += info.naoRespondida ? 1 : 0),
			0
		);

		const emComplementacao =
			size(get(formulario, 'formData.resultado')) === 0 &&
			!rascunhoDocsComplementacao &&
			!!get(formulario, 'formData.dataComparecimento') &&
			!get(formulario, 'formData.expirado') &&
			// size(formulario.documentos) > 0 &&
			qtdInvalidos + qtdNovos + qtdPerguntasNaoRespondidas + qtdOpcionaisPreenchidos > 0;

		let documentos = [];
		if (get(formulario, 'formData.rascunhoDocsComplementacao')) {
			// esta situação só existe enquanto o processo está com o admin e já houve salvamento de docs invalidados
			// mas ainda não foi enviado para complementação.
			// pega versão mais nova de documentos antes de invalidados
			documentos = [];
			get(formulario, 'documentos')
				.filter(d => d.idDocumentoSei)
				.forEach(d => {
					const index = documentos.findIndex(di => di.id === d.original && d.versao > di.versao);
					if (index === -1) {
						documentos.push(d);
					} else {
						documentos[index] = d;
					}
				});
		} else {
			documentos = (get(formulario, 'documentos') || []).filter(d => !d.invalido);
		}

		documentos = documentos.filter(d => !!d);
		if (emComplementacao) {
			documentos = documentos.filter(
				d =>
					this.ehSubstituto(d) ||
					this.ehNovo(d) ||
					this.ehRevisado(d) ||
					this.ehOpcionalPreenchido(d) ||
					this.ehFromSolicitante(d)
			);
		}
		return documentos;
	};

	isDocEspecificoEmRascunho = () => {
		const { formulario } = this.props;
		const slotEspecifico = formulario?.documentos
			?.filter(d => EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(d.extensao))
			.reduce((acc, d) => (acc ? (acc.versao > d.versao ? acc : d) : d), null);

		if (size(slotEspecifico) > 0) {
			const dd = formulario?.documentosDados?.find(dd => dd.id === slotEspecifico.id);
			if (size(dd) > 0) {
				return true;
			}
		}

		return false;
	};

	isDocEspecificoGerado = () => {
		const { formulario } = this.props;
		const d = formulario?.documentos
			?.filter(d => EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(d.extensao))
			.reduce((acc, d) => (acc ? (acc.versao > d.versao ? acc : d) : d), null);

		if (size(d) > 0) {
			if (d.size && d.filename) {
				return true;
			}
		}

		return false;
	};

	render() {
		const { formulario, loading, errors, match, usuario } = this.props;
		const { motivos, documentoSeiLicenca } = this.state;
		const showForm = !!formulario && !!usuario;

		const qtdInvalidos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehInvalido(d) ? 1 : 0),
			0
		);
		const qtdNovos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehNovo(d) || this.ehRevisado(d) ? 1 : 0),
			0
		);
		const qtdOpcionaisPreenchidos = ((formulario || {}).documentos || []).reduce(
			(acc, d) => (acc += this.ehOpcionalPreenchido(d) ? 1 : 0),
			0
		);
		const qtdPerguntasNaoRespondidas = (get(formulario, 'formData.extraInfo') || []).reduce(
			(acc, info) => (acc += info.naoRespondida ? 1 : 0),
			0
		);

		const rascunhoDocsComplementacao = !!get(formulario, 'formData.rascunhoDocsComplementacao');

		const emComplementacao =
			size(get(formulario, 'formData.resultado')) === 0 &&
			!rascunhoDocsComplementacao &&
			!!get(formulario, 'formData.dataComparecimento') &&
			!get(formulario, 'formData.expirado') &&
			// size(formulario.documentos) > 0 &&
			qtdInvalidos + qtdNovos + qtdPerguntasNaoRespondidas + qtdOpcionaisPreenchidos > 0;

		debugLog('====================================');
		debugLog('formulario?.formData?.resultado: ', formulario?.formData?.resultado);
		debugLog('formulario?.formData.rascunhoDocsComplementacao: ', formulario?.formData?.rascunhoDocsComplementacao);
		debugLog('formulario?.formData.dataComparecimento: ', formulario?.formData?.dataComparecimento);
		debugLog('formulario?.formData.expirado: ', formulario?.formData?.expirado);
		debugLog('qtdInvalidos: ', qtdInvalidos);
		debugLog('qtdNovos: ', qtdNovos);
		debugLog('qtdPerguntasNaoRespondidas: ', qtdPerguntasNaoRespondidas);
		debugLog('qtdOpcionaisPreenchidos: ', qtdOpcionaisPreenchidos);
		debugLog('emComplementacao: ', emComplementacao);
		debugLog('====================================');

		const resultado = get(formulario, 'formData.resultado');
		const semProcedimentoFormatadoSei = size(resultado) === 0 && !get(formulario, 'formData.procedimentoFormatadoSei');

		let documentosDados = get(formulario, 'documentosDados');

		// Alteração no campo Especificação
		if (formulario && formulario.schema && formulario.schema.properties && formulario.schema.properties.requerimento) {
			if (
				formulario.schema.properties.requerimento.properties &&
				formulario.schema.properties.requerimento.properties.especificacao &&
				formulario.schema.properties.requerimento.properties.especificacao.title
			) {
				// título
				formulario.schema.properties.requerimento.properties.especificacao.title = 'Observações';
			}
			if (formulario.schema.properties.requerimento.required) {
				for (var i = 0; i < formulario.schema.properties.requerimento.required.length; i++) {
					if (formulario.schema.properties.requerimento.required[i] === 'especificacao') {
						// retira obrigatoriedade
						formulario.schema.properties.requerimento.required.splice(i, 1); // remove elemento
					}
				}
			}

			// campos de requerimento
			var requerimento = formulario.schema.properties.requerimento;
			// retira texto do cabeçalho
			requerimento.title = '';

			// passa Requerimento para o final do formulário
			delete formulario.schema.properties.requerimento;
			formulario.schema.properties.requerimento = requerimento;
		}

		const novoFormData = match.params.id === 'new';
		const { email, preferred_username } = this.state.user || {};
		const usernameLogado = preferred_username || email;
		const antesDeCriarSEI = size(get(formulario, 'formData.procedimentoFormatadoSei')) === 0;
		const processoSemBpm = size(get(formulario, 'processName')) === 0;
		const processoComBpmTaskPendingParaUsuarioLogado = size(this.props.bpmTasks) > 0;
		// se já tem id não tem formData.usuario quer dizer que acabou de criar o formData,
		// então ainda é o mesmo usuário, apenas não recarregou o formData criado
		// portanto deve ser considerado o mesmo email, abaixo
		const mesmoEmail = (get(formulario, 'formData.usuario.email') || usernameLogado) === usernameLogado;
		const usuarioDonoTask =
			processoSemBpm ||
			((novoFormData || mesmoEmail) && (antesDeCriarSEI || processoComBpmTaskPendingParaUsuarioLogado));

		debugLog(
			'-------- DADOS PARA DONO DA TASK ---------',
			`\né o dono? ${usuarioDonoTask ? 'sim' : 'não'}`,
			`\nnovoFormData? ${novoFormData ? 'sim' : 'não'}`,
			`\nusernameLogado? ${usernameLogado}`,
			`\nantesDeCriarSEI? ${antesDeCriarSEI ? 'sim' : 'não'}`,
			`\nprocessoSemBpm? ${processoSemBpm ? 'sim' : 'não'} - processName: ${get(formulario, 'processName')} - case: ${
				formulario?.formData?.bpmProcessInstance
			}`,
			`\nprocessoComBpmTaskPendingParaUsuarioLogado? ${processoComBpmTaskPendingParaUsuarioLogado ? 'sim' : 'não'}`,
			`\nmesmoEmail? ${mesmoEmail ? 'sim' : 'não'} - logado: ${usernameLogado}, requerente: ${get(
				formulario,
				'formData.usuario.email'
			)}\n,
			${size(this.props.bpmTasks)}\n------------------------------`
		);

		const readOnly = !!get(formulario, 'formData.idProcedimentoSei');
		let dadosParaLicenca = get(formulario, 'formData.data');

		let dadosIguais = true;
		if (readOnly) {
			if (get(formulario, 'formData.userData')) {
				let keys = [];
				if (this.state.aba === ABA_ENQUADRAMENTO) {
					keys = Object.keys(formulario.formData.userData);
				} else {
					keys = Object.keys(omit(formulario.formData.userData, ['enquadramento']));
				}
				const formDataPropsIniciais = pick(formulario.formData.data, keys);
				if (!deepEqual(formDataPropsIniciais, formulario.formData.userData, true)) {
					dadosIguais = false;
					if (this.state.dadosOriginais) {
						dadosParaLicenca = formulario.formData.userData;
					}
				}
			}
		}
		if (isDebug) {
			if (!dadosParaLicenca) {
				dadosParaLicenca = { data: {} };
				if (size(usuario)) {
					Object.assign(dadosParaLicenca, usuario.perfil);
				}
			}
			if (size(get(dadosParaLicenca, 'identificacao')) === 0 && size(get(this.state, 'user')) > 0) {
				const { email, preferred_username } = this.state.user || {};
				const username = preferred_username || email;
				const identificacao = `Teste de ${username} (${moment(new Date()).format('ddd')} às ${moment(new Date()).format(
					'HH:mm:ss'
				)})`;
				dadosParaLicenca.identificacao = identificacao;
			}
		}
		if (size(usuario) && !size(dadosParaLicenca) && FORMS_AUTO_PREENCHER.includes(formulario?.idTipoFormulario)) {
			dadosParaLicenca = Object.assign({}, usuario.perfil);
		}

		const urlLicencaEtrOuToldos = get(formulario, 'formData.data.urlDocumentoSei');

		const validadeRascunho = moment(get(formulario, 'formData.createdAt')).endOf('day').add(90, 'days');
		const dataAtual = moment().endOf('day');
		const rascunhoValido =
			dataAtual.isBefore(validadeRascunho) && !get(formulario, 'formData.procedimentoFormatadoSei');
		const rascunhoInvalido =
			!dataAtual.isBefore(validadeRascunho) && !get(formulario, 'formData.procedimentoFormatadoSei');

		const tipoProcessoNome = formulario?.nome || formulario?.schema?.title;

		return showForm ? (
			<Fragment>
				<div className="container">
					{/* APP-HEADER */}
					<div className="subhead">
						<Breadcrumbs
							items={[
								LIST_PLACEHOLDER,
								<li key="2">
									{match.params.id === 'new' ? 'Novo ' : 'Edição de '}
									Requerimento
								</li>
							]}
						/>
					</div>
					{size(get(formulario, 'formData.resultado')) === 0 ? (
						!this.state.recemGerado &&
						!usuarioDonoTask &&
						(this.props.keycloakUser?.email !== formulario?.formData?.usuario?.email ? (
							<p className="alert alert-danger">
								<i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Você não tem permissão para este
								requerimento.
							</p>
						) : (
							<p className="alert alert-warning alert-indisponivel">
								<i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Este requerimento está sendo analisado
								pela Prefeitura neste momento e não pode ser modificado.
							</p>
						))
					) : (
						<div
							className={`col alert alert-${
								['deferido', 'confirmado', 'concluído'].includes(resultado?.toLowerCase()) ? 'success' : 'warning'
							}`}
						>
							<h3 style={{ display: 'flex', width: '100%' }}>
								<span style={{ flex: '1' }}>
									{formulario?.formData && (
										<EstadoRequerimento
											formulario={formulario}
											formData={formulario.formData}
											qtdInvalidos={0}
											qtdNovos={0}
											qtdPerguntasNaoRespondidas={0}
										/>
									)}
								</span>
								{get(formulario, 'formData.idFormDataMae') && (
									<LinkProcesso
										id={formulario.formData.idFormDataMae}
										label="Acesso ao processo original"
										style={{ color: 'white' }}
									/>
								)}
							</h3>
							{resultado === 'deferido' &&
								get(formulario, 'formData.bpmProcessInstance') &&
								(/aprovacao-projetos/.test(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download de seu projeto assinado digitalmente '}
										<a
											href={
												(
													(this.obtainDocumentos() || []).find(doc => doc.idDocumento === 'planta-assinada-revisor') ||
													{}
												).urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{` ou acesse o item "${this.props.numeroAbaProtocolo}. PROTOCOLO", clique no número do processo SEI e faça o download do documento "Documento Documento final assinado pelo revisor - v. 1.pdf`}
									</div>
								) : /ajuste-projetos/.test(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download de seu projeto assinado digitalmente '}
										<a
											href={
												(
													(this.obtainDocumentos() || []).find(doc => doc.idDocumento === 'planta-assinada-revisor') ||
													{}
												).urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{` ou acesse o item "${this.props.numeroAbaProtocolo}. PROTOCOLO", clique no número do processo SEI e faça o download do documento "Documento Documento final assinado pelo revisor - v. 1.pdf`}
									</div>
								) : /habite-se/.test(get(formulario, 'idTipoFormulario')) ||
								  /retifica-carta-bpm/.test(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download de sua carta de habitação homologada '}
										<a
											href={
												(
													(this.obtainDocumentos() || []).find(
														doc => doc.idDocumento === 'carta-habitacao-homologada'
													) || {}
												).urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{` ou acesse o item "${this.props.numeroAbaProtocolo}. PROTOCOLO", clique no número do processo SEI e faça o download do documento da carta`}
									</div>
								) : /licenciamento_expresso/.test(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download de seu projeto assinado digitalmente '}
										<a
											href={
												(
													(this.obtainDocumentos() || []).find(doc => doc.idDocumento === 'planta-assinada-revisor') ||
													{}
												).urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{` ou acesse o item "${this.props.numeroAbaProtocolo}. PROTOCOLO", clique no número do processo SEI e faça o download do documento "Documento Documento final assinado pelo revisor - v. 1.pdf`}
									</div>
								) : ['certidao-area-construida', 'certidao-demolicao'].includes(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download da sua certidão'}
										<a
											href={
												((this.obtainDocumentos() || []).find(doc => doc.idDocumento === 'certidao-homologada') || {})
													.urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{
											' ou acesse a última aba, clique no número do processo SEI e faça o download do documento "Documento Certidão.pdf'
										}
									</div>
								) : ['certificacao-sustentavel'].includes(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										Para obter o seu certificado{' '}
										<a
											href={
												(
													(this.obtainDocumentos() || []).find(
														doc => doc.idDocumento === 'certificado-sustentabilidade-ambiental'
													) || {}
												).urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<strong>
												<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
											</strong>
										</a>
										<br />
										ou acesse a aba {this.props.numeroAbaProtocolo}. PROTOCOLO, clique no link para o Protocolo SEI e
										faça o download do
										<br /> documento "Certificado em Sustentabilidade Ambiental"
									</div>
								) : ['projetos-especiais'].includes(get(formulario, 'idTipoFormulario')) ? (
									<div style={{ textAlign: 'justify' }}>
										Para obter o seu certificado{' '}
										<a
											href={
												((this.obtainDocumentos() || []).find(doc => doc.idDocumento === 'parecer-evu-cevea') || {})
													.urlConsultaPublicaSei
											}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<strong>
												<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
											</strong>
										</a>
										. ou acesse a aba {this.props.numeroAbaProtocolo}. PROTOCOLO, clique no link para o Protocolo SEI e
										faça o download do
										<br /> documento "PARECER DE EVU DA CEVEA"
									</div>
								) : ['licenca-toldos', 'etr-na-hora'].includes(get(formulario, 'idTipoFormulario')) ? (
									urlLicencaEtrOuToldos && (
										<div style={{ textAlign: 'justify' }}>
											Para realizar o download de sua licença{' '}
											<a
												href={urlLicencaEtrOuToldos}
												target="_blank"
												rel="noopener noreferrer"
												style={{ color: 'white' }}
											>
												<strong>
													<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
												</strong>
											</a>
											. ou acesse a aba {this.props.numeroAbaProtocolo}. PROTOCOLO, clique no link para o Protocolo SEI
											e faça o download do
											<br /> documento "Documento 0 - Licença Toldos (Licença na Hora)"
										</div>
									)
								) : /licencas-expressas/.test(get(formulario, 'idTipoFormulario')) && documentoSeiLicenca ? (
									<div style={{ textAlign: 'justify' }}>
										{'Para realizar o download de sua licença '}
										<a
											href={documentoSeiLicenca.urlDocumentoSei}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: 'white' }}
										>
											<i className="fa fa-file-pdf-o" aria-hidden="true" /> clique aqui
										</a>
										{` ou acesse o item "${this.props.numeroAbaProtocolo}. PROTOCOLO", clique no número do processo SEI e faça o download do documento`}
									</div>
								) : null)}
						</div>
					)}

					{/* CABEÇALHO */}
					<h1 className="page-header">
						{tipoProcessoNome || 'Requerimento'}
						{isDebug && get(formulario, 'processVersion') ? (
							<span className="debug-message">{` (v ${get(formulario, 'processVersion')})`}</span>
						) : (
							''
						)}
					</h1>
					{/* CONTEUDO */}
					<section className="content">
						{/* LISTA DE ABAS */}
						{rascunhoValido && (
							<div className="alert alert-warning rascunho">
								<i className="fa fa-exclamation-triangle fa-icone-rascunho" aria-hidden="true"></i>
								Este rascunho tem prazo de validade de 90 dias. A partir desta data (
								{validadeRascunho.format('DD/MM/YYYY')}) será necessário criar um novo processo
								{tipoProcessoNome ? ` de ${tipoProcessoNome}` : ''} para realizar o protocolo.
							</div>
						)}
						{rascunhoInvalido && (
							<div className="alert alert-danger rascunho">
								<i className="fa fa-exclamation-triangle fa-icone-rascunho" aria-hidden="true"></i>
								Este rascunho levou mais de 90 dias em elaboração e não pode mais ser usado para criar este
								requerimento. Será necessário criar um novo processo
								{tipoProcessoNome ? ` de ${tipoProcessoNome}` : ''} para realizar o protocolo.
							</div>
						)}
						<ListaAbas aba={this.state.aba} formulario={formulario} abas={this.getAbas()} />
						<div className="row">
							<div className="col-xs-12 col-lg-10 offset-lg-1">
								{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'send', false)} />}
								{/* DETALHES CADA ABA */}
								{/* ABA_FORMULARIO */}
								{this.state.aba === ABA_FORMULARIO && formulario && (
									<Fragment>
										{size(formulario.info) > 0 && <EditorView state={formulario.info} />}
										{(size(formulario.documentos) > 0 ||
											FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario)) &&
											![TIPO_FORMULARIO_HABITE_SE].includes(formulario?.idTipoFormulario) && (
												<div className="doc-necessarios">
													<h4>Confira os documentos exigidos nas próximas abas deste requerimento: </h4>
													{!FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario) &&
														size(formulario.documentos) > 0 && (
															<Fragment>
																<h4
																	onClick={() => this.setState({ showDocsFormulario: !this.state.showDocsFormulario })}
																>
																	<div className="flex justify-content-between">
																		<i
																			className={
																				this.state.showDocsFormulario ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
																			}
																			aria-hidden="true"
																			style={{ padding: '0 0 0 0.5rem' }}
																		></i>
																		<span style={{ padding: '0 0 0 1.5rem', flex: 1 }}>
																			Documentos fixos do processo de {formulario.nome || formulario.schema.title}
																		</span>
																	</div>
																</h4>
																<ListaDocs
																	lista={formulario.documentos}
																	showDocsFormulario={this.state.showDocsFormulario}
																/>
															</Fragment>
														)}
													{FORMS_COM_ENQUADRAMENTO.includes(formulario?.idTipoFormulario) && (
														<Fragment>
															<h4
																onClick={() =>
																	this.setState({ showDocsEnquadramento: !this.state.showDocsEnquadramento })
																}
															>
																<div className="flex justify-content-between">
																	<i
																		className={
																			this.state.showDocsEnquadramento ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
																		}
																		aria-hidden="true"
																		style={{ padding: '0 0 0 0.5rem' }}
																	></i>
																	<span style={{ padding: '0 0 0 1.5rem', flex: 1 }}>
																		Relação de documentos de acordo com o enquadramento
																	</span>
																</div>
															</h4>
															{this.state.showDocsEnquadramento && (
																<Fragment>
																	<p style={{ marginTop: '10px' }}>
																		Selecione o enquadramento abaixo para ver as listas de documentos. O visualizador de
																		enquadramento <b>NÃO</b> substitui o preenchimento na aba seguinte, apenas permite
																		antecipar a lista de documentos necessários ao preenchimento deste requerimento.
																	</p>
																	{/* TODO */}
																	<EnquadramentoForm
																		root={this.obtemRootAtual(dadosParaLicenca)}
																		idExpedienteUnico={get(formulario, 'formData.data.expediente._id')}
																		idMetadata={get(formulario, 'idTipoEnquadramento')}
																		etapas={get(dadosParaLicenca, 'enquadramento.etapas')}
																		data={
																			get(dadosParaLicenca, 'enquadramento.metadados')
																				? {
																						_id: TEMP_ID(),
																						questions: [get(dadosParaLicenca, 'enquadramento.metadados')]
																				  }
																				: null
																		}
																		readOnly={false}
																		onSubmit={null}
																		somenteListaDocs={true}
																	>
																		<ListaDocs lista={formulario.documentos} showDocsFormulario={true} />
																	</EnquadramentoForm>
																</Fragment>
															)}
														</Fragment>
													)}
												</div>
											)}
										{formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCA_EXPRESSA ? (
											<>
												{readOnly && !dadosIguais && usuarioDonoTask && (
													<div>
														<button className="btn btn-link" type="button" onClick={this.toggleDadosOriginais}>
															{this.state.dadosOriginais ? 'ver dados alterados pelo revisor' : 'ver dados originais'}
														</button>
													</div>
												)}
												<LicencaExpressaForm
													data={dadosParaLicenca}
													readOnly={readOnly}
													onSubmit={this.onSubmitLicencaMetaForm}
												/>
											</>
										) : [TIPO_FORMULARIO_EVENTOS].includes(formulario?.idTipoFormulario) ? (
											<EventosForm
												data={dadosParaLicenca}
												readOnly={readOnly}
												onSubmit={this.onSubmitNextAba2}
												tipoForm={formulario.idTipoFormulario}
											/>
										) : formulario?.idTipoFormulario === TIPO_FORMULARIO_LAC_RCE_OFICINAS_SIMILARES ? (
											<RceOficinaSimilaresForm
												data={dadosParaLicenca}
												readOnly={readOnly}
												onSubmit={this.onSubmitNextAba2}
											/>
										) : META_FORMULARIOS.includes(formulario?.idTipoFormulario) ||
										  !size(formulario?.schema) ||
										  !size(formulario?.uiSchema) ? (
											<>
												{readOnly && !dadosIguais && usuarioDonoTask && (
													<div>
														<button className="btn btn-link" type="button" onClick={this.toggleDadosOriginais}>
															{this.state.dadosOriginais ? 'ver dados alterados pelo revisor' : 'ver dados originais'}
														</button>
													</div>
												)}
												<MetadataForm
													data={dadosParaLicenca}
													readOnly={readOnly}
													onSubmit={this.onSubmitLicencaMetaForm}
													type={formulario?.idTipoFormulario}
													showNext={true}
													usuarioInterno={!!this.state.usuarioInterno}
													consultaPublicaExpedientes={true}
												/>
											</>
										) : (
											<Formulario
												form={formulario}
												asyncErrors={this.state.asyncErrors}
												onChange={this.onChangeHandler}
												onSubmit={this.onSubmitHandler}
												onValidate={this.onValidate}
												transformErrors={this.transformErrors}
												readOnly={readOnly}
											>
												{usuarioDonoTask ? (
													<>
														{this.exibirBotaoAvancar() && (
															<button type="submit" className="btn-primary">
																Avançar
															</button>
														)}
													</>
												) : null}
											</Formulario>
										)}
										{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'validation', false)} />}
									</Fragment>
								)}
								{/* ABA_LAC */}
								{this.state.aba === ABA_LAC && (
									<FormLicencaAmbiental
										formData={get(formulario, 'formData')}
										tipoFormulario={formulario?.idTipoFormulario}
										onSubmitHandler={this.onSubmitHandler}
										readOnly={readOnly}
									/>
								)}
								{/* ABA_ENQUADRAMENTO */}
								{this.state.aba === ABA_ENQUADRAMENTO && (
									<>
										{readOnly && !dadosIguais && usuarioDonoTask && (
											<div>
												<button className="btn btn-link" type="button" onClick={this.toggleDadosOriginais}>
													{this.state.dadosOriginais ? 'ver dados alterados pelo revisor' : 'ver dados originais'}
												</button>
											</div>
										)}

										{this.isDocEspecificoGerado() && (
											<>
												{this.props?.formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO ? (
													<WarningMessage>
														<span>
															Não é permitido fazer alterações após a geração do PDF do formulário de Licenciamento
															Expresso. Para alterar o enquadramento, exclua o documento gerado na aba "3. Documentos".
														</span>
													</WarningMessage>
												) : this.props?.formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCAS_EXPRESSAS ? (
													<WarningMessage>
														<span>
															Não é permitido fazer alterações após a geração do PDF do formulário de Licenças para
															obras de simples natureza. Para alterar o enquadramento, exclua o documento gerado na aba
															"3. Documentos".
														</span>
													</WarningMessage>
												) : this.props?.formulario?.idTipoFormulario === TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL ? (
													<WarningMessage>
														Não é permitido fazer alterações após a geração do PDF do formulário de Certificação em
														Sustentabilidade Ambiental. Para alterar o enquadramento, exclua o documento gerado na aba
														"3. Documentos", ou, ao menos, transforme-o em rascunho novamente.
													</WarningMessage>
												) : this.props?.formulario?.idTipoFormulario === TIPO_FORMULARIO_APROVACAO_PROJETOS ? (
													<WarningMessage>
														Não é permitido fazer alterações após a geração do PDF do formulário de Aprovação de Projeto
														Arquitetônico. Para alterar o enquadramento, exclua o documento gerado na aba "3.
														Documentos", ou, ao menos, transforme-o em rascunho novamente.
													</WarningMessage>
												) : this.props?.formulario?.idTipoFormulario === TIPO_FORMULARIO_ADESAO_PRCHPA ? (
													<WarningMessage>
														Não é permitido fazer alterações após a geração do PDF do formulário de Adesão ao Programa
														de Reabilitação do Centro Histórico de Porto Alegre. Para alterar o enquadramento, exclua o
														documento gerado na aba "3. Documentos", ou, ao menos, transforme-o em rascunho novamente.
													</WarningMessage>
												) : null}
											</>
										)}
										<EnquadramentoForm
											root={this.obtemRootAtual(dadosParaLicenca)}
											idExpedienteUnico={get(formulario, 'formData.data.expediente._id')}
											idMetadata={get(formulario, 'idTipoEnquadramento')}
											etapas={get(dadosParaLicenca, 'enquadramento.etapas')}
											data={
												get(dadosParaLicenca, 'enquadramento.metadados')
													? {
															_id: TEMP_ID(),
															questions: [get(dadosParaLicenca, 'enquadramento.metadados')],
															dataPromocao: get(dadosParaLicenca, 'enquadramento.dataPromocao')
													  }
													: null
											}
											readOnly={this.isDocEspecificoGerado() || get(formulario, 'formData.idProcedimentoSei')}
											onSubmit={result =>
												this.isDocEspecificoEmRascunho() && this.houveAlteracaoEnquadramento(result)
													? this.setState({ showConfirmaEnquadramento: true, tempResult: result })
													: this.onSubmitEnquadramento(result)
											}
										/>
									</>
								)}
								{/* ABA_DOCUMENTOS */}
								{this.state.aba === ABA_DOCUMENTOS &&
									(get(formulario, 'formData.bpmProcessInstance') && emComplementacao ? (
										<ComplementacaoBpm
											usuarioDonoTask={usuarioDonoTask}
											formulario={formulario}
											documentos={this.obtainDocumentos()}
											documentosDados={documentosDados}
											motivos={motivos}
											errors={errors}
											onDrop={this.onDrop}
											removeFile={this.removeFileHandler}
											retiraDocumento={this.retiraDocumento}
											criarNovoDocumento={this.togglePromptTituloDocumento}
											avancar={this.avancar2to3}
											criarMultiDocumentos={this.addMultiDocumentos}
											complementarProcesso={this.complementarProcesso}
											updateResposta={(infoId, resposta) =>
												this.props.dispatchUpdateRespostaExtraInfo(infoId, resposta)
											}
											emComplementacao={emComplementacao}
										/>
									) : (
										<Documentos
											formulario={formulario}
											documentos={this.obtainDocumentos()}
											documentosDados={documentosDados}
											emComplementacao={emComplementacao}
											motivos={motivos}
											errors={errors}
											onDrop={this.onDrop}
											removeFile={this.removeFileHandler}
											limpaDocumentosDados={this.limpaDocumentosDados}
											retiraDocumento={this.retiraDocumento}
											criarNovoDocumento={this.togglePromptTituloDocumento}
											avancar={this.avancar2to3}
											criarMultiDocumentos={this.addMultiDocumentos}
											complementarProcesso={this.complementarProcesso}
											updateResposta={(infoId, resposta) =>
												this.props.dispatchUpdateRespostaExtraInfo(infoId, resposta)
											}
											usuarioDonoTask={usuarioDonoTask}
										/>
									))}
								{/* ABA_DAM */}
								{this.state.aba === ABA_DAM && (
									<>
										<AbaDamForm
											formulario={formulario}
											formData={get(formulario, 'formData')}
											onSubmit={this.submitAbaDam} // atenção: submete o formData acima, que obtém da Store/Reducer
											readOnly={readOnly}
										/>
										{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'validation', false)} />}
										{process.env.NODE_ENV !== 'production' && (
											<div style={{ textAlign: 'center', marginTop: '20px' }} className="alert alert-warning">
												<strong>ATENÇÃO: Ambiente de homologação</strong>
												<p>Este é um ambiente de homologação, os dados DAM inseridos aqui não serão validados</p>
											</div>
										)}
									</>
								)}
								{/* ABA_TERMO */}
								{this.state.aba === ABA_TERMO && (
									<Fragment>
										{this.state.isBase ? (
											<div style={{ textAlign: 'center', marginTop: '20px' }}>
												<strong>Requerimento Base</strong>
												<p style={{ margin: '20pt 0' }}>não deve ser gerado, apenas copiado</p>
											</div>
										) : get(formulario, 'disabled') && semProcedimentoFormatadoSei ? (
											<div style={{ textAlign: 'center', marginTop: '20px' }}>
												<strong>RASCUNHO EXPIRADO</strong>
												<p style={{ margin: '20pt 0' }}>
													Seu processo foi descontinuado e deve ser realizada uma nova solicitação.
												</p>
											</div>
										) : rascunhoInvalido ? (
											<div style={{ textAlign: 'center', marginTop: '20px' }}>
												<strong>RASCUNHO EXPIRADO</strong>
												<p style={{ margin: '20pt 0' }}>
													Este rascunho levou mais de 90 dias em elaboração e não pode mais ser usado para criar este
													requerimento. Será necessário criar um novo processo
													{tipoProcessoNome ? ` de ${tipoProcessoNome}` : ''} para realizar o protocolo.
												</p>
											</div>
										) : get(formulario, 'idTipoFormulario') === TIPO_FORMULARIO_LICENCAS_EXPRESSAS ? (
											<TermosCondicoesLicencasExpressas
												formulario={formulario}
												avancarLabel={
													semProcedimentoFormatadoSei ? 'Concordar e prosseguir com geração do Processo' : 'Avançar'
												}
												onAvancarHandler={this.onAvancarTermosHandler}
											/>
										) : (
											<TermosCondicoes
												termo={get(formulario, 'termoAceitacao')}
												showAceitar={get(formulario, 'termoRequerAceite')}
												showDeclaracao={termoNotificacaoEmpty(get(formulario, 'termoNotificacao', ''))}
												readOnly={readOnly}
												idTipoFormulario={get(formulario, 'tipoFormulario.id')}
												termoNotificacao={
													size(trim(get(formulario, 'termoNotificacao'))) > 0 ? formulario.termoNotificacao : null
												}
												concordo={get(formulario, 'formData.data.concordo')}
												avancarLabel={
													semProcedimentoFormatadoSei ? 'Concordar e prosseguir com geração do Processo' : 'Avançar'
												}
												onAvancarHandler={this.onAvancarTermosHandler}
												onAceitarHandler={this.onAceitarTermos}
											/>
										)}
										{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'geraSei', false)} />}
										{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'gera-bpm', false)} />}
									</Fragment>
								)}
								{/* ABA_PROTOCOLO */}
								{this.state.aba === ABA_PROTOCOLO && (
									<Fragment>
										<h1>Processo {this.state.processoGerado ? 'alterado' : 'criado'} com sucesso.</h1>
										{/*se o formulario precisar gerar boleto para pagar taxas*/}
										{!isNil(get(formulario, 'formData.data.naHoraEUNaoMigrado')) &&
											!get(formulario, 'formData.data.naHoraEUNaoMigrado') && (
												<div className="form-group form-docs">
													<div className={'col alert warning'}>
														<p>
															* Expediente Único não está migrado. Dessa forma, você não poderá emitir a Licença ETR de
															forma automática, devendo o processo ser analisado pelo revisor.
														</p>
													</div>
												</div>
											)}
										<h3>Protocolo SEI: </h3>
										<div className="d-flex justify-content-between">
											<a
												href={get(formulario, 'formData.urlConsultaPublicaSei')}
												target="_blank"
												rel="noopener noreferrer"
											>
												{get(formulario, 'formData.procedimentoFormatadoSei')}
												<i style={{ marginLeft: '8px' }} className="fa fa-external-link" aria-hidden="true" />
											</a>
											{isDebug && get(formulario, 'formData.procedimentoFormatadoSei') && (
												<a
													href={obtainUrlAdmin(get(formulario, 'id'), get(formulario, 'formData.id'))}
													className="debug-message"
													target="_blank"
													rel="noopener noreferrer"
												>
													link em debug para admin
												</a>
											)}
										</div>
										{[
											TIPO_FORMULARIO_ETR_NA_HORA,
											TIPO_FORMULARIO_ETR_NA_HORA_RENOVACAO,
											TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA
										].includes(get(formulario, 'idTipoFormulario')) ? (
											<div className="text-justify">
												<p>
													Caso sua solicitação não possua nenhum condicionante, conforme preenchimento do questionário,
													você poderá emitir a licença
													{formulario.idTipoFormulario === TIPO_FORMULARIO_LICENCA_TOLDOS_NA_HORA
														? ' para toldos '
														: ' ETR '}
													clicando no processo acima.
												</p>
												<p>
													Para solicitações com condicionantes, clique no número do protocolo para abrir o Acesso
													Externo do SEI e acompanhar o andamento e os documentos do seu processo. A atualização do
													andamento é realizada no campo &quot;Descrição&quot; da &quot;Lista de Andamentos&quot; no
													Acesso Externo do SEI. Os documentos estão disponíveis no campo &quot;Lista de
													Protocolos&quot;.
												</p>
												<p>
													Se houver necessidade de complementação de documentos e/ou informações, você será avisado por
													e-mail e será atualizado no Protocolo SEI. A complementação deve ser feita pelo portal de
													licenciamento (
													<a href="https://licenciamento.procempa.com.br/" target="_blank" rel="noopener noreferrer">
														licenciamento.procempa.com.br
													</a>
													).
												</p>

												{FORMS_GERAM_LIC_TOLDO.includes(formulario?.idTipoFormulario) && (
													<>
														{this.state.mostrarMensagemCertidao &&
														!resultado &&
														get(formulario, 'formData.data.imovelPublico') === 'nao' &&
														get(formulario, 'formData.data.leitoViaPublica') === 'nao' &&
														get(formulario, 'formData.data.patrimonioCultural') === 'nao' &&
														get(formulario, 'formData.data.intervencaoVegetacao') === 'nao' ? (
															<p className="alert-info">
																Sua certidão está sendo gerada. Pode demorar até 5 minutos para estar disponível no SEI.
															</p>
														) : this.state.mostrarMensagemCertidao && resultado ? (
															<a
																href={get(formulario, 'formData.urlConsultaPublicaSei')}
																target="_blank"
																rel="noopener noreferrer"
															>
																Link para acessar Documentos
															</a>
														) : (
															<p className="alert-info">
																Este requerimento está sendo analisado pela Prefeitura neste momento.
															</p>
														)}
													</>
												)}

												{FORMS_GERAM_DOCUMENTO_NA_HORA.includes(formulario?.idTipoFormulario) && (
													<>
														{this.state.mostrarMensagemCertidao && !resultado ? (
															<p className="alert-info">
																Sua certidão está sendo gerada. Pode demorar até 5 minutos para estar disponível no SEI.
															</p>
														) : (
															<a
																href={get(formulario, 'formData.urlConsultaPublicaSei')}
																target="_blank"
																rel="noopener noreferrer"
															>
																Link para acessar Documentos
															</a>
														)}
													</>
												)}
											</div>
										) : (
											<div className="text-justify">
												{get(formulario, 'documentosPrivados') ? (
													<p>
														Copie o número do protocolo e clique no mesmo para abrir a pesquisa pública do sistema SEI e
														acompanhar o andamento. A atualização do andamento é realizada no campo "Descrição" da
														"Lista de Andamentos" no Acesso Externo do SEI.
													</p>
												) : (
													<p>
														Clique no número do Protocolo SEI para abrir o Acesso Externo do SEI e acompanhar o
														andamento e os documentos do seu processo. A atualização do andamento é realizada no campo
														&quot;Descrição&quot; da &quot;Lista de Andamentos&quot; no Acesso Externo do SEI. Os
														documentos estão disponíveis no campo &quot;Lista de Protocolos&quot;.
													</p>
												)}

												<p>
													Se houver necessidade de complementação de documentos e/ou informações, você será avisado por
													e-mail e será atualizado no Protocolo SEI. A complementação deve ser feita pelo portal de
													licenciamento (
													<a href="https://licenciamento.procempa.com.br/" target="_blank" rel="noopener noreferrer">
														licenciamento.procempa.com.br
													</a>
													).
												</p>

												{FORMS_GERAM_DOCUMENTO_NA_HORA.includes(formulario?.idTipoFormulario) && (
													<>
														{this.state.mostrarMensagemCertidao && !resultado ? (
															<p className="alert-info">
																Sua certidão está sendo gerada. Pode demorar até 5 minutos para estar disponível no SEI.
															</p>
														) : (
															<a
																href={get(formulario, 'formData.urlConsultaPublicaSei')}
																target="_blank"
																rel="noopener noreferrer"
															>
																Link para acessar Documentos
															</a>
														)}
													</>
												)}
											</div>
										)}

										{resultado ? (
											<div style={{ marginTop: '8px' }}>
												<span
													// className="error-message"
													style={{
														color: 'white',
														display: 'inline-block',
														backgroundColor: ['deferido'].includes(resultado?.toLowerCase())
															? '#00ccaa'
															: ['confirmado', 'concluído'].includes(resultado?.toLowerCase())
															? 'rgb(83 134 229)'
															: 'grey',
														borderRadius: '12px',
														padding: '0 8px',
														whiteSpace: 'nowrap'
													}}
												>
													{['deferido', 'confirmado', 'concluído'].includes(resultado?.toLowerCase()) ? (
														<i style={{ color: 'white' }} className="fa fa-check" />
													) : (
														<i style={{ color: 'white' }} className="fa fa-exclamation-circle" />
													)}
													<span> {STATUS_PROCESS[resultado] || resultado}</span>
												</span>
											</div>
										) : null}
									</Fragment>
								)}
								{loading && <Loader msg="Enviando..." />}
							</div>
						</div>
					</section>
				</div>
				<Modal
					key="addFileModal"
					show={this.state.promptTituloDocumento}
					onHide={() => this.togglePromptTituloDocumento()}
				>
					<Modal.Header>
						<Modal.Title id="titleAddFile">{'Adicionar novo documento'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label>Título do documento</label>
							<input
								placeholder="Descrição do documento"
								className="form-control"
								value={this.state.tituloDocumento || ''}
								onChange={event => this.setState({ tituloDocumento: event.target.value })}
							/>
						</div>
						<div className="form-group">
							<label>Tipo de arquivo</label>
							<Selecao
								className={'form-control'}
								multiple={false}
								selected={this.state.extensao || ''}
								label={'Extensão'}
								detailCodigo={''}
								detailDescricao={'desc'}
								autoShowList={true}
								searchTerm={''}
								searchList={[
									{ id: 'pdf', desc: 'PDF' },
									{ id: 'dwg', desc: 'DWG' }
								]}
								searchTermMinLength={0}
								errorList={false}
								onChangeSearchTerm={() => false}
								onBlurSearchTerm={() => false}
								onSelectItem={item => () => this.setState({ extensao: item })}
								onUnselect={() => () => this.setState({ extensao: '' })}
								loading={false}
								required={true}
								detailModifier={prop => prop}
								placeholder="Selecione o tipo de arquivo"
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="submit-group-small-left">
							<button
								type="button"
								className="btn btn-primary"
								style={{ marginRight: '5px' }}
								onClick={() => {
									this.criarNovoDocumento(this.state.tituloDocumento);
									this.setState({ tituloDocumento: '' });
									this.togglePromptTituloDocumento();
								}}
							>
								Adicionar
							</button>
							<button type="button" className="btn btn-secondary" onClick={() => this.togglePromptTituloDocumento()}>
								Fechar
							</button>
						</div>
					</Modal.Footer>
				</Modal>
				<Modal
					key="removeFileModal"
					show={this.state.isRemoveFileModalAtive}
					onHide={() => this.setState({ isRemoveFileModalAtive: false })}
					aria-labelledby="Remover File"
					backdropClassName={'modal-backdrop-n2'}
				>
					<Modal.Header>
						<Modal.Title id="titleRemoveFile">{'Tem certeza que deseja remover o arquivo?'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{[EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL, EXTENSAO_DOC_ESPECIFICO_VD].includes(
							this.state.removeFileAux?.idDocumento
						) ? (
							<div className="alert alert-warning" style={{ paddingLeft: '60px', marginLeft: '0', marginRight: '0' }}>
								<i
									className="fa fa-exclamation-triangle"
									style={{ fontSize: '23px', paddingRight: '9px', position: 'absolute', top: '17px', left: '15px' }}
								></i>
								<strong>Atenção:</strong> você perderá todo o preenchimento realizado no fomulário abaixo!
							</div>
						) : null}

						<strong>{this.state.removeFileAux ? this.state.removeFileAux.tituloDocumento : ''}</strong>
						<div className="form-control" style={{ marginTop: '6px', border: '2px solid #c5c5c9', padding: '16px' }}>
							<i className="fa fa-file-pdf-o" style={{ fontSize: '18px' }} />
							{!this.state.removeFileAux?.idDocumentoSei && !!this.state.removeFileAux?.originalName ? (
								<i className="fa fa-over fa-exclamation-circle" style={{ color: '#09afa7' }}>
									{get(formulario, 'formData.procedimentoFormatadoSei') ? (
										<div className="icon-tooltip">
											Arquivo enviado para servidor. Aguardando complementação do processo.
										</div>
									) : (
										<div className="icon-tooltip">Arquivo enviado para servidor. Aguardando criação do processo.</div>
									)}
								</i>
							) : this.state.removeFileAux?.originalName ? (
								<i
									className="fa fa-over fa-check"
									style={{
										color: 'green'
									}}
								>
									<div className="icon-tooltip">Arquivo anexado ao processo.</div>
								</i>
							) : (
								<i
									className="fa fa-over fa-exclamation-circle"
									style={{
										color: '#09afa7'
									}}
								/>
							)}
							<strong>{this.state.removeFileAux?.originalName ? this.state.removeFileAux.originalName : ''}</strong>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<div className="submit-group-small-left">
							<button
								type="button"
								className="btn btn-danger btn-lg"
								style={{ padding: '0.3rem 1rem', marginRight: '5px' }}
								onClick={() => {
									if (this.state.removeFileAux?.filename) {
										this.removeFile(this.state.removeFileAux);
										this.setState({ isRemoveFileModalAtive: false, removeFileAux: null });
									}
								}}
							>
								Remover
							</button>
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => this.setState({ isRemoveFileModalAtive: false, removeFileAux: null })}
							>
								Cancelar
							</button>
						</div>
					</Modal.Footer>
				</Modal>
				<Modal
					key="confirmaEnquadramentoModal"
					show={this.state.showConfirmaEnquadramento}
					onHide={() => this.setState({ showConfirmaEnquadramento: false })}
				>
					<Modal.Header>
						<Modal.Title id="titleAddFile">Confirma sumissão enquadramento</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h3>Há um rascunho de documento específico que será removido.</h3>
						<h3>Confirma seleção do enquadramento?</h3>
					</Modal.Body>
					<Modal.Footer>
						<div className="submit-group-small-left">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => this.setState({ showConfirmaEnquadramento: false, tempResult: undefined })}
								style={{ marginRight: '5px' }}
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									this.onSubmitEnquadramento(this.state.tempResult);
									this.setState({ showConfirmaEnquadramento: false, tempResult: undefined });
								}}
							>
								Submeter enquadramento e apagar documento em rascunho
							</button>
						</div>
					</Modal.Footer>
				</Modal>
				<Modal
					key="confirmaLimpaDocumentosDados"
					show={this.state.showPopupLimparDocumentosDados}
					onHide={() => this.setState({ showPopupLimparDocumentosDados: false })}
				>
					<Modal.Header>
						<Modal.Title id="titleAddFile">Confirma limpeza dos dados do documento específico</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h3>
							Os dados preechidos no documento específico serão limpos.
							<br />
							Isto permitirá, se necessário, alterar o enquadramento.
						</h3>
						<h3>Confirma limpeza dos dados?</h3>
					</Modal.Body>
					<Modal.Footer>
						<div className="submit-group-small-left">
							<button
								type="button"
								className="btn btn-secondary"
								onClick={() => this.setState({ showPopupLimparDocumentosDados: false, indiceLimpar: undefined })}
								style={{ marginRight: '5px' }}
							>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									const dd = formulario.documentosDados[this.state.indiceLimpar];
									const dIndex = formulario.documentos.findIndex(d => d.id === dd.id);
									this.props.dispatchLimparDocumentosDados(this.props.match.params.id, this.state.indiceLimpar, dIndex);
									this.setState({ showPopupLimparDocumentosDados: false, indiceLimpar: undefined });
								}}
							>
								Apagar dados do documento específico
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</Fragment>
		) : (
			<Loader msg="Obtendo dados da requisição..." />
		);
	}

	adicionaScripts = () => {
		if (document.getElementById('root_interessados_proprietarioRT')) {
			document.getElementById('root_interessados_proprietarioRT').ref = 'interessados_proprietarioRT';
		}
	};
}

Form.propTypes = {
	match: PropTypes.object,
	history: PropTypes.any,
	location: PropTypes.object,
	formulario: PropTypes.object,
	bpmTasks: PropTypes.arrayOf(PropTypes.object),
	reload: PropTypes.object,
	result: PropTypes.string,
	loading: PropTypes.bool,
	errors: PropTypes.any,
	keycloakUser: PropTypes.object,
	usuario: PropTypes.object,
	numeroAbaProtocolo: PropTypes.number,
	createMessage: PropTypes.func,
	dispatchAbaDam: PropTypes.func,
	dispatchLoadFormAndData: PropTypes.func,
	dispatchSetFormData: PropTypes.func,
	dispatchSetFormulario: PropTypes.func,
	dispatchSendFormData: PropTypes.func,
	dispatchSendEnquadramentoData: PropTypes.func,
	dispatchUploadFile: PropTypes.func,
	dispatchAddNewDocument: PropTypes.func,
	dispatchSetResult: PropTypes.func,
	dispatchSetErrors: PropTypes.func,
	dispatchSetLoading: PropTypes.func,
	dispatchRemoveFile: PropTypes.func,
	dispatchRetiraDocumento: PropTypes.func,
	dispatchSendFormCompleto: PropTypes.func,
	dispatchComplementarProcesso: PropTypes.func,
	dispatchUpdateURI: PropTypes.func,
	dispatchGoHome: PropTypes.func,
	dispatchGoAba: PropTypes.func,
	dispatchAceitarTermo: PropTypes.func,
	dispatchUpdateRespostaExtraInfo: PropTypes.func,
	dispatchSetKcUser: PropTypes.func,
	dispatchObtainBpmTasks: PropTypes.func,
	dispatchLoadUsuario: PropTypes.func,
	dispatchUsuarioInterno: PropTypes.func,
	dispatchLimparDocumentosDados: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
	dispatchLoadUsuario: data => dispatch(actions.loadUsuario(data)),
	dispatchAbaDam: data => dispatch(actions.sendAbaDam(data)),
	dispatchLoadFormAndData: (idFormulario, idFormData, disabled) =>
		dispatch(actions.loadFormAndData(idFormulario, idFormData, disabled)),
	dispatchSetFormulario: formulario => dispatch(actions.setFormulario(formulario)),
	dispatchSetFormData: formData => dispatch(actions.setFormData(formData)),
	dispatchSendFormData: formData => dispatch(actions.sendFormData(formData)),
	dispatchSendEnquadramentoData: data => dispatch(actions.sendEnquadramentoData(data)),
	dispatchUpdateURI: (idFormulario, id, aba) => {
		dispatch(replace(`/${idFormulario}/${id}?aba=${aba || ABA_DOCUMENTOS}`));
		dispatch(actions.updateFormId(id));
		return dispatch(actions.setReload(null));
	},
	dispatchUploadFile: payload => dispatch(actions.uploadFile(payload)),
	dispatchAddNewDocument: payload => dispatch(actions.addNewDocument(payload)),
	dispatchSetResult: result => dispatch(actions.setResult(result)),
	dispatchSetErrors: errors => dispatch(actions.setErrors(errors)),
	dispatchSetLoading: loading => dispatch(actions.setLoading(loading)),
	dispatchRemoveFile: documento => dispatch(actions.removeFile(documento)),
	dispatchRetiraDocumento: payload => dispatch(actions.retiraDocumento(payload)),
	dispatchSendFormCompleto: (formulario, filtroOrgao) => dispatch(actions.sendFormCompleto(formulario, filtroOrgao)),
	dispatchComplementarProcesso: (formulario, usernameLogado, complementadaEm, createMessage, filtroOrgao) =>
		dispatch(actions.complementarProcesso(formulario, usernameLogado, complementadaEm, createMessage, filtroOrgao)),
	dispatchGoHome: () => dispatch(actions.limpaStore()) && dispatch(push('/')),
	dispatchGoAba: (idFormulario, id, aba) => dispatch(replace(`/${idFormulario}/${id}?aba=${aba}`)),
	dispatchAceitarTermo: dados => dispatch(actions.setAceitarTermos(dados)),
	dispatchUpdateRespostaExtraInfo: (infoId, resposta) =>
		dispatch(actions.updateRespostaExtraInfo({ infoId, resposta })),
	dispatchSetKcUser: userInfo => dispatch(actions.setKcUser(userInfo)),
	dispatchObtainBpmTasks: (caseId, username) => dispatch(actions.obtainBpmTasks({ caseId, username })),
	dispatchUsuarioInterno: usuarioInterno => dispatch(actions.setUsuarioInterno(usuarioInterno)),
	dispatchLimparDocumentosDados: (idFormData, ddIndice, dIndice) =>
		dispatch(actions.limparDocumentosDados({ ddIndice, dIndice, idFormData }))
});

const mapStateToProps = createStructuredSelector({
	formulario: selectors.makeSelect('formulario'),
	bpmTasks: selectors.makeSelect('bpmTasks'),
	reload: selectors.makeSelect('reload'),
	result: selectors.makeSelect('result'),
	keycloakUser: selectors.makeSelect('keycloakUser'),
	loading: selectors.makeSelect('loading'),
	errors: selectors.makeSelect('errors'),
	usuario: selectors.makeSelect('usuario'),
	numeroAbaProtocolo: selectors.makeSelect('numeroAbaProtocolo')
});

const withReducer = injectReducer({ key: 'licenciamento', reducer });
const withSaga = injectSaga({ saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withMessage, withReducer, withSaga, withRouter, withConnect)(Form);

function ListaDocs({ lista, showDocsFormulario }) {
	return showDocsFormulario ? (
		<ul>
			{lista.map(doc => (
				<li key={doc.id}>
					{doc.tituloDocumento}
					{doc.obrigatorio ? <sup style={{ fontSize: '15pt', color: 'red', verticalAlign: 'text-bottom' }}>*</sup> : ''}
				</li>
			))}
		</ul>
	) : null;
}
ListaDocs.displayName = 'ListaDocs';
ListaDocs.propTypes = {
	lista: PropTypes.arrayOf(PropTypes.object),
	showDocsFormulario: PropTypes.bool
};

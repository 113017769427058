import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Alert, Modal } from 'react-bootstrap';

import useAxios from 'axios-hooks';
import { get, isNil, omit, pick, set, size, snakeCase, sortBy, trim } from 'lodash';

import Comentario from 'components/Comentario';
import ErrorMessages from 'components/ErrorMessages';
import HistoricoRespostaAnterior from 'components/HistoricoRespostaAnterior';
import Selecao from 'components/Selecao';
import ShowDebug from 'components/ShowDebug';
import WarningMessage from 'components/WarningMessage';

import { TextAreaField, TextField } from 'containers/Form/metadata-template/fields';
import NumberFieldLE from 'containers/Form/metadata-template/fields/numberFieldLE.js';
import RadioFieldLE from 'containers/Form/metadata-template/fields/radioFieldLE.js';
import PlanoDiretorAnexosSelecao from 'containers/PlanoDiretor/PlanoDiretorAnexosSelecao';

import { EXPEDIENTES_API_URL, PLANO_DIRETOR_API_URL } from 'environments';

import { filterList, isDebug, maiorQue, multiplica, soma } from 'utils/tools';

import { PLANODIRETOR_CONTROLNAME } from './InfosPlanoDiretorAP';
import { InfosProjetoSubUnidadesAP } from './InfosProjetoSubUnidadesAP';
import PlanilhaIndicesConstrutivos, {
	PLANILHA_PROPERTY
} from './PlanilhaIndicesConstrutivos/PlanilhaIndicesConstrutivos';
import {
	UAP_ENQUADRAMENTOS_ENUM,
	ATV_DECRETO_ENTRETENIMENTO_NOTURNO,
	ATV_ENSINO_ESCOLAR,
	ATV_REVENDA_POSTO_GAS,
	ATV_INTERFERENCIA_AMBIENTAL
} from './TabelaAreasAP/constantsAP';
import { AVISO_ESTUDO } from './TabelaAreasAP/constantsAP';
import UltimoEvuValido from './UltimoEvuValidoAP';

/* #region CONSTANTES */
const {
	UNIFAMILIAR_AUTONOMA,
	NAO_RESIDENCIAL,
	MULTIFAMILIAR,
	MULTIFAMILIAR_NAO_RESIDENCIAL,
	UNIFAMILIAR_NAO_RESIDENCIAL,
	HABITACAO_COLETIVA,
	UNIFAMILIAR_01_ECONO,
	UNIFAMILIAR_02_ECONO
} = UAP_ENQUADRAMENTOS_ENUM;

const OPT_SIM = { codigo: 'sim', descricao: 'Sim' };
const OPT_NAO = { codigo: 'nao', descricao: 'Não' };
const OPT_SIM_NAO = [OPT_SIM, OPT_NAO];

const INFO_TOOLTIP_ATV_NOTURNA = `Atividades enquadradas no Art. 1º do Decreto Municipal nº 18.572/2014:
I - 2.1.2.1 - bar;
II - 3.3.7 - boliches, bilhares e bingos;
III - 3.3.8 - casa noturna/danceteria;
IV - 3.3.25 - casa de eventos e/ou espetáculos;
V - 3.3.26 - centro de tradições; e
VI - 3.3.27 - quadra de escola de samba.`;

const INFO_TOOLTIP_ATV_NOTURNA_SOM = `Atividades enquadradas no §1º do Art. 1º do Decreto Municipal nº 18.572/2014:
I - 2.1.2.1 - café lancheria;
II - 2.1.2.7 - restaurante e pizzaria sem forno a lenha; e
III - 2.1.3.16 - restaurante e pizzaria.`;

export const INFOSPROJETO_CONTROLNAME = 'infosProjeto';
/* #endregion */

let i = 0;

const debugLog = (...args) => isDebug && console.debug('(DOC-ESP-UAP)', ++i, ...args);

export function InfosProjetoAP({
	data: localData,
	dataAnterior,
	tipoForm = '',
	onChangeHandler,
	errosSubmissao: errosSubmissaoProps,
	semExpedienteUnico,
	disabled = false,
	changeComentarioHandler = null,
	usuarioInterno = false,
	readOnlyVersao = null
}) {
	const planoDiretorData = get(localData, PLANODIRETOR_CONTROLNAME, {});
	const infosProjetoData = get(localData, [INFOSPROJETO_CONTROLNAME], {});
	const infosProjetoDataAnterior = get(dataAnterior, [INFOSPROJETO_CONTROLNAME], {});
	const picData = get(infosProjetoData, PLANILHA_PROPERTY);
	const picDataAnterior = get(infosProjetoDataAnterior, PLANILHA_PROPERTY);
	const possuiAreaAtingida = localData?.possuiAreaAtingida === 'sim';
	const areaAtingidaMatricula = parseFloat(localData?.areaAtingidaMatricula?.value || 0);
	const areaAtingidaMenorPoligonal = parseFloat(localData?.areaAtingidaMenorPoligonal?.value || 0);
	const totalIaPermitido = parseFloat(infosProjetoData?.indiceAproveitamentoTotalPermitido?.value || 0);
	const totalIaMaxTotalPermitido = parseFloat(infosProjetoData?.indiceAproveitamentoMaximoTotalPermitido?.value || 0);

	/** @type {[]} */
	const subunidades = get(localData, `${PLANODIRETOR_CONTROLNAME}.dadosSubunidades`, []);
	const subunidadesAnterior = get(dataAnterior, `${PLANODIRETOR_CONTROLNAME}.dadosSubunidades`, []);
	const sufixoComentario = 'Comentario';

	const errosSubmissao = useMemo(() => {
		if (!errosSubmissaoProps) {
			return [];
		}
		if (Array.isArray(errosSubmissaoProps)) {
			return errosSubmissaoProps;
		}
		let ouput = [];
		Object.keys(errosSubmissaoProps).forEach(key => {
			let newErro = {
				campo: key,
				message: errosSubmissaoProps[key]
			};
			ouput.push(newErro);
		});
		return ouput;
	}, [errosSubmissaoProps]);

	const [showAvisoAreaAplicacaoRu, setShowAvisoAreaAplicacaoRu] = useState(false);
	const [erros, setErros] = useState({});
	const [searchMaterial, setSearchMaterial] = useState();
	const [outroMaterial, setOutroMaterial] = useState();
	const [hasOutroMaterial, setHasOutroMaterial] = useState(false);
	// informa que algo mudou aqui e a subunidade precisa mudar
	const [mudarSubunidades, setMudarSubunidades] = useState([]);
	const [confirmaRemoverPic, setConfirmaRemoverPic] = useState(false);
	const [temComentario, setTemComentario] = useState({});
	const [advertenciaRestricoes, setAdvertenciaRestricoes] = useState(false);

	const setTemComentarioAux = valores => {
		const { name, value } = valores || {};
		if (temComentario[name] !== value) {
			setTemComentario(oldState => {
				const newState = { ...oldState };
				newState[name] = value;
				return newState;
			});
		}
	};

	const isMultifamiliar = includesTipoForm([MULTIFAMILIAR], tipoForm);
	const isUnidadeAutonoma = includesTipoForm([UNIFAMILIAR_AUTONOMA], tipoForm);
	const isNaoResidencial = includesTipoForm([NAO_RESIDENCIAL], tipoForm);
	const isEnqHabColetiva = includesTipoForm([HABITACAO_COLETIVA], tipoForm);
	const isUnifamiliar = includesTipoForm([UNIFAMILIAR_01_ECONO, UNIFAMILIAR_02_ECONO], tipoForm);
	const isNaoResidenciais = includesTipoForm(
		[NAO_RESIDENCIAL, MULTIFAMILIAR_NAO_RESIDENCIAL, UNIFAMILIAR_NAO_RESIDENCIAL],
		tipoForm
	);
	const isUnifamiliarENaoResidencial = includesTipoForm([UNIFAMILIAR_NAO_RESIDENCIAL], tipoForm);
	const isMultifamiliarNaoResidencial = includesTipoForm([MULTIFAMILIAR_NAO_RESIDENCIAL], tipoForm);

	const isDecretoEntrenimentoNoturnoMemo = useMemo(() => isDecretoEntrenimentoNoturno(subunidades), [subunidades]);

	const isInterferenciaAmbientalMemo = useMemo(() => isInterferenciaAmbiental(subunidades), [subunidades]);

	const isAtividadesEnsinoMemo = useMemo(() => isAtividadesEnsino(subunidades), [subunidades]);

	const isAtividadeDepositoRevendaGasMemo = useMemo(() => isAtividadeDepositoRevendaGas(subunidades), [subunidades]);

	const isVagasTotaisMaiorQue400 = maiorQue(get(infosProjetoData, 'totalQtdVagasCondominiais.value'), 400);

	const isProjetoPossuiBaseCorpo =
		infosProjetoData.projetoPossuiBaseCorpo === 'sim'
			? true
			: infosProjetoData.projetoPossuiBaseCorpo === 'nao'
			? false
			: undefined;

	const possuiIndicesConstrutivos = infosProjetoData?.aquisicaoIndicesConstrutivos === 'sim';

	/** alguns cálculos automáticos */
	const calculos = useCallback(
		localData => {
			const infosProjetoData = get(localData, [INFOSPROJETO_CONTROLNAME], {});
			const subunidades = get(localData, `${PLANODIRETOR_CONTROLNAME}.dadosSubunidades`, []);
			if (!isUnidadeAutonoma) {
				let sumIA = subunidades.reduce((acc, subUnidade) => {
					const val = get(subUnidade, 'indiceAproveitamento.value');
					return soma(acc, val);
				}, 0);

				let sumIAMaximo = subunidades.reduce((acc, subUnidade) => {
					const val = get(subUnidade, 'indiceAproveitamentoMaximo.value');
					return soma(acc, val);
				}, 0);

				if (
					isNaoResidencial &&
					parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value) === 1 &&
					infosProjetoData?.trintaPorcentoIa === 'sim'
				) {
					sumIA = multiplica(sumIA, 1.3);
				}

				infosProjetoData.indiceAproveitamentoTotalPermitido = { value: sumIA };
				infosProjetoData.indiceAproveitamentoMaximoTotalPermitido = { value: sumIAMaximo };

				const sumTO = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOSubunidade.value');
					return soma(p, val);
				}, 0);
				infosProjetoData.taxaOcupacaoTotalPermitido = { value: sumTO };

				const sumTBase = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOBase.value');
					return soma(p, val);
				}, 0);
				const sumTCorpo = subunidades.reduce((p, c) => {
					const val = get(c, 'resultadoTOCorpo.value');
					return soma(p, val);
				}, 0);
				infosProjetoData.totalPermitidoBase = { value: sumTBase };
				infosProjetoData.totalPermitidoCorpo = { value: sumTCorpo };
			}
			if (isNaoResidenciais || isMultifamiliar || isEnqHabColetiva) {
				const bs = get(infosProjetoData, 'qtdBoxesPrivativosSimples.value');
				const bd = get(infosProjetoData, 'qtdBoxesPrivativosDuplos.value');
				infosProjetoData.totalQtdBoxesPrivativos = { value: soma(bs, bd, { decimais: 0 }) };

				// vagas = Boxes privativos simples + 2x (boxes privativos duplos) + Vagas condominiais
				const vc = get(infosProjetoData, 'qtdVagasCondominiais.value');
				const bd2 = multiplica(bd, 2, { decimais: 0 });
				const bd2s = soma(bd2, bs, { decimais: 0 });
				infosProjetoData.totalQtdVagasCondominiais = { value: soma(bd2s, vc, { decimais: 0 }) };
			}
			if (isMultifamiliar || isMultifamiliarNaoResidencial) {
				debugLog('André: ========== Quota Ideal ==========');
				// quota ideal
				debugLog('André: cálculo das subunidades');
				subunidades.forEach(sAux => {
					const { subunidade, areaTerrenoAtingida, quotaRegimeUrbanisticoAnexo } = sAux;
					debugLog('André: ================> subunidade', subunidade.subunidade);
					if (subunidade) {
						const codigoVolumetria = parseInt(subunidade.volumetria || 0, 10);
						const codigoIndiceAproveitamento = parseInt(subunidade.indiceAproveitamento || 0, 10);
						const isAreaOcupacaoRarefeita = [31, 33, 35, 37, 39].includes(codigoIndiceAproveitamento);
						debugLog(
							`André: volumetria ${codigoVolumetria} e código do indice de aproveitamento`,
							`${codigoIndiceAproveitamento} (é portanto ${isAreaOcupacaoRarefeita ? '' : 'não '}rarefeita)`
						);
						sAux.deveUsarQuotaIdealSubunidade = codigoVolumetria === 1 || isAreaOcupacaoRarefeita;
						debugLog(
							`André: com estas informações deduzimos que ${
								sAux.deveUsarQuotaIdealSubunidade ? 'DEVE' : 'NÃO PRECISA'
							} usar a quota ideal`
						);
						sAux.quantidadeMaxEconomiasResidenciaisSubunidade = 0;
						if (sAux.deveUsarQuotaIdealSubunidade) {
							const qi = quotaRegimeUrbanisticoAnexo?.quotaideal?.replace('.', '');
							if (qi) {
								sAux.quantidadeMaxEconomiasResidenciaisSubunidade = parseFloat(areaTerrenoAtingida?.value || 0) / qi;
							}
							debugLog(
								'André: fórmula => area terreno / quota ideal => cálculo =>',
								`${areaTerrenoAtingida?.value} / ${qi} =`,
								sAux.quantidadeMaxEconomiasResidenciaisSubunidade
							);
						} else {
							debugLog(
								'André: Não devendo usar QuotaIdeal não há cálculo da quantidade máxima de economias residenciais'
							);
						}
						sAux.quantidadeMaxEconomiasResidenciaisSubunidadeArredondada = Math.round(
							sAux.quantidadeMaxEconomiasResidenciaisSubunidade
						);
						sAux.areaOcupacaoRarefeita = isAreaOcupacaoRarefeita;
						sAux.codigoIndiceAproveitamento = codigoIndiceAproveitamento;
						sAux.codigoVolumetria = codigoVolumetria;
					}
					debugLog('André: ==============================');
				});

				let quantidadeMaxEconomiasResidenciaisLong = subunidades.reduce((acc, sub) => {
					const val = sub?.quantidadeMaxEconomiasResidenciaisSubunidade || 0;
					return soma(acc, val);
				}, 0);
				const quantidadeMaxEconomiasResidenciaisProjeto = Math.round(quantidadeMaxEconomiasResidenciaisLong);
				debugLog(
					'André: terminando cálculos das subunidades, o número máximo de economias é',
					quantidadeMaxEconomiasResidenciaisLong,
					'o que arredondando dá',
					quantidadeMaxEconomiasResidenciaisProjeto
				);

				infosProjetoData.deveUsarQuotaIdealProjeto = subunidades.reduce(
					(acc, sub) => acc && sub.deveUsarQuotaIdealSubunidade,
					true
				);
				infosProjetoData.quantidadeMaxEconomiasResidenciaisProjeto = {
					value: `${quantidadeMaxEconomiasResidenciaisProjeto}`
				};
				debugLog(
					`André: Portanto o projeto ${
						infosProjetoData.deveUsarQuotaIdealProjeto ? 'DEVE' : 'NÃO PRECISA'
					}  usar a Quota Ideal${
						infosProjetoData.deveUsarQuotaIdealProjeto
							? ` e a quantidade máxima de economias residenciais será ${infosProjetoData.quantidadeMaxEconomiasResidenciaisProjeto.value}`
							: ''
					}`
				);

				debugLog('subunidades: ', subunidades, infosProjetoData);
			}
			let sum = 0;
			if (isNaoResidencial) {
				sum = parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value || '0');
			} else {
				sum =
					parseInt(infosProjetoData?.numeroEconomiasResidencial?.value || '0') +
					parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value || '0');
			}
			infosProjetoData.numeroEconomiasTotal = { value: `${sum}` };
		},
		[
			isEnqHabColetiva,
			isMultifamiliar,
			isMultifamiliarNaoResidencial,
			isNaoResidenciais,
			isNaoResidencial,
			isUnidadeAutonoma
		]
	);

	// manipular os tipos de construção
	const [tiposConstrucao, setTiposConstrucao] = useState([]);
	const [{ data: tiposconstrucao }] = useAxios(`${EXPEDIENTES_API_URL}/cadastros/tipoconstrucao`);

	const handleSelect = ({ name, value: item, operacao }) => {
		//localData.tipoConstrucao
		const tiposConstru = infosProjetoData[name] || {};
		if (item.codigo === 'outro') {
			setHasOutroMaterial(true);
			setSearchMaterial(undefined);
		} else if (operacao === 'add-outro') {
			tiposConstru[item.codigo] = { ...item };
			setHasOutroMaterial(false);
			setOutroMaterial(undefined);
		} else if (operacao === 'add') {
			tiposConstru[item.codigo] = { ...item };
		}
		if (operacao === 'remove') {
			delete tiposConstru[item.codigo];
		}
		localChangeHandler && localChangeHandler({ name, value: { ...tiposConstru } });
	};

	const localChangeHandler = useCallback(
		changes => {
			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { name, value } = changes;
			const omitProperties = [];

			// verifica alterações nas atividades do plano diretor
			if (name === 'planoDiretor') {
				const subunidadesAlterado = get(value, ['dadosSubunidades'], []);
				const atividadeDepositoPostoRevendaGas_anterior = isAtividadeDepositoRevendaGas(subunidadesAnterior);
				const atividadeDepositoPostoRevendaGas_alterado = isAtividadeDepositoRevendaGas(subunidadesAlterado);
				// se deixou de ter a atividade entre todas as subunidades
				if (atividadeDepositoPostoRevendaGas_anterior && !atividadeDepositoPostoRevendaGas_alterado) {
					// remover as respectivas respostas marcadas do infoProjetosAp
					omitProperties.push('atividadeDepositoPostoRevendaGas');
					omitProperties.push('atividadeDepositoPostoRevendaGasPossuiDistanciaMinima');
				}
				const interferenciaAmbiental_anterior = isInterferenciaAmbiental(subunidadesAnterior);
				const interferenciaAmbiental_alterado = isInterferenciaAmbiental(subunidadesAlterado);
				if (interferenciaAmbiental_anterior && !interferenciaAmbiental_alterado) {
					omitProperties.push('atividadeSaude');
					omitProperties.push('atividadeSaudePossuiVagaAmbulancia');
				}
				const isAtividadesEnsino_anterior = isAtividadesEnsino(subunidadesAnterior);
				const isAtividadesEnsino_alterado = isAtividadesEnsino(subunidadesAlterado);
				if (isAtividadesEnsino_anterior && !isAtividadesEnsino_alterado) {
					omitProperties.push('atividadeEnsinoPreEscolarInfantilMaternal');
				}

				const isDecretoEntrenimentoNoturno_anterior = isDecretoEntrenimentoNoturno(subunidadesAnterior);
				const isDecretoEntrenimentoNoturno_alterado = isDecretoEntrenimentoNoturno(subunidadesAlterado);
				if (isDecretoEntrenimentoNoturno_anterior && !isDecretoEntrenimentoNoturno_alterado) {
					omitProperties.push('atividadeEntrenenimentoNoturno');
					omitProperties.push('atividadeEntrenenimentoNoturnoComMusicaAmplificada');
				}
			}

			// set dos dados do projeto
			if (name === PLANODIRETOR_CONTROLNAME) {
				set(localData, [PLANODIRETOR_CONTROLNAME], value);
			} else {
				set(localData, [INFOSPROJETO_CONTROLNAME, name], value);
			}

			if (name === 'aquisicaoIndicesConstrutivos' && value === 'nao') {
				omitProperties.push(PLANILHA_PROPERTY);
			} else if (infosProjetoData?.aquisicaoIndicesConstrutivos === 'nao') {
				omitProperties.push(PLANILHA_PROPERTY);
			}

			calculos(localData);

			const { erros, errorsToRemove } = validarInfosProjetoAP(
				infosProjetoData,
				localData,
				tipoForm,
				isMultifamiliar,
				isMultifamiliarNaoResidencial
			);
			setErros(old => Object.assign({}, omit(old, [name, ...errorsToRemove]), erros));

			onChangeHandler &&
				onChangeHandler({ name: INFOSPROJETO_CONTROLNAME, value: { ...omit(infosProjetoData, omitProperties) } });
		},
		[
			calculos,
			infosProjetoData,
			isMultifamiliar,
			isMultifamiliarNaoResidencial,
			localData,
			onChangeHandler,
			subunidadesAnterior,
			tipoForm
		]
	);

	const localChangeHandlerComAlert = useCallback(
		(changes, picData) => {
			const temDados =
				(picData?.totalAreaAdensavelCalculada || 0) > 0 || (picData?.totalAreaNaoAdensavelCalculada || 0) > 0;

			if (Array.isArray(changes)) {
				const [valid] = changes;
				changes = valid;
			}
			const { value } = changes;

			if (value === 'nao' && temDados) {
				setConfirmaRemoverPic(true);
			} else {
				localChangeHandler(changes);
			}
		},
		[localChangeHandler]
	);

	useEffect(() => {
		if (infosProjetoData) {
			if (infosProjetoData && !infosProjetoData.projetoPossuiEvuValido) {
				localChangeHandler({ name: 'projetoPossuiEvuValido', value: localData.ultimoEvuValido ? 'sim' : 'nao' });
			}
		}
	}, [infosProjetoData, localChangeHandler, localData.ultimoEvuValido]);

	useEffect(() => {
		if (tiposconstrucao) {
			let tipos = tiposconstrucao.map(tp => pick(tp, ['_id', 'codigo', 'descricao']));
			tipos = sortBy(tipos, 'descricao');
			tipos.push({ _id: '000', codigo: 'outro', descricao: 'Informar outro' });
			setTiposConstrucao(tipos);
		}
	}, [tiposconstrucao]);

	const atividadeCallbackHandle = useCallback(
		i => () => {
			mudarSubunidades[i] = false;
			setMudarSubunidades([...mudarSubunidades]);
		},
		[mudarSubunidades]
	);

	const removerPic = useCallback(() => {
		debugLog('(PIC)', `Removendo Planilha de Índices Construtivos (${PLANILHA_PROPERTY})`);
		localChangeHandler({ name: 'aquisicaoIndicesConstrutivos', value: 'nao' });
		setConfirmaRemoverPic(false);
	}, [localChangeHandler]);

	let bloquearQtdVagasCondominiais = false;
	if (
		isEnqHabColetiva ||
		(isNaoResidencial && parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value || 0, 10) === 1)
	) {
		bloquearQtdVagasCondominiais = true;
		delete infosProjetoData.qtdVagasCondominiais;
		calculos(localData);
	}

	return (
		<fieldset>
			<legend>{`${isUnidadeAutonoma ? '3' : '4'}. Projeto`}</legend>
			<legend className="legenda-titulo-secundario dados-iniciais">{`${
				isUnidadeAutonoma ? '3' : '4'
			}.1 Dados iniciais do projeto`}</legend>
			{isUnidadeAutonoma ? (
				<div className="projeto-ap">
					<div
						className={`pergunta metragem ${
							temComentario[`taxaOcupacaoProjeto${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<NumberFieldLE
							name="taxaOcupacaoProjeto"
							label="Taxa de Ocupação do Projeto (m²)"
							placeHolder="metragem m²"
							required={true}
							onChangeHandler={e => localChangeHandler(e, localData.quotaTaxaOcupacao, 'quotaTaxaOcupacao')}
							value={infosProjetoData.taxaOcupacaoProjeto}
							decimals={2}
							maxSize="10"
							disabled={disabled}
						/>
						<CustomErrorMessages property="taxaOcupacaoProjeto" errors1={erros} errors2={errosSubmissao} />

						<HistoricoRespostaAnterior
							dataAnterior={infosProjetoDataAnterior['taxaOcupacaoProjeto']}
							dataAtual={infosProjetoData['taxaOcupacaoProjeto']}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`taxaOcupacaoProjeto${sufixoComentario}`}
							valueComentario={infosProjetoData[`taxaOcupacaoProjeto${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={!!readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`taxaOcupacaoProjeto${sufixoComentario}`]}
						/>
					</div>
					<div
						className={`pergunta metragem ${
							temComentario[`numeroPavimentos${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<NumberFieldLE
							name="numeroPavimentos"
							label="Número de pavimentos"
							placeHolder="0"
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.numeroPavimentos}
							maxSize="10"
							disabled={disabled}
						/>
						<CustomErrorMessages property="numeroPavimentos" errors1={erros} errors2={errosSubmissao} />

						<HistoricoRespostaAnterior
							dataAnterior={infosProjetoDataAnterior['numeroPavimentos']}
							dataAtual={infosProjetoData['numeroPavimentos']}
							usuarioInterno={usuarioInterno}
						/>

						<Comentario
							campoComentario={`numeroPavimentos${sufixoComentario}`}
							valueComentario={infosProjetoData[`numeroPavimentos${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={!!readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`numeroPavimentos${sufixoComentario}`]}
						/>
					</div>
					<div
						className={`pergunta metragem ${
							temComentario[`numeroDormitorios${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<NumberFieldLE
							name="numeroDormitorios"
							label="Número de dormitórios"
							placeHolder="0"
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.numeroDormitorios}
							maxSize="10"
							disabled={disabled}
						/>
						<CustomErrorMessages property="numeroDormitorios" errors1={erros} errors2={errosSubmissao} />

						<HistoricoRespostaAnterior
							dataAnterior={infosProjetoDataAnterior['numeroDormitorios']}
							dataAtual={infosProjetoData['numeroDormitorios']}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`numeroDormitorios${sufixoComentario}`}
							valueComentario={infosProjetoData[`numeroDormitorios${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={!!readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`numeroDormitorios${sufixoComentario}`]}
						/>
					</div>
					<div
						className={`pergunta metragem ${
							temComentario[`tipoConstrucao${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<div className="flex-start" style={{ alignItems: 'baseline' }}>
							<label>Tipo de material</label>
							<Selecao
								className={'form-control'}
								multiple={true}
								selected={Object.values(infosProjetoData?.tipoConstrucao || {})}
								label={'Tipo de construção'}
								detailCodigo={''}
								detailDescricao={'descricao'}
								autoShowList={true}
								searchTerm={searchMaterial}
								searchList={filterList(tiposConstrucao, searchMaterial)}
								searchTermMinLength={1}
								errorList={false}
								onChangeSearchTerm={e => setSearchMaterial(e.target.value)}
								onBlurSearchTerm={() => false}
								onSelectItem={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'add' })}
								onUnselect={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'remove' })}
								loading={false}
								required={true}
								placeholder="tipos de material"
								readOnly={disabled}
							/>
						</div>
						{hasOutroMaterial && (
							<div className="flex-start" style={{ alignItems: 'baseline', marginTop: '8px' }}>
								<TextField
									name="outroMaterial"
									value={outroMaterial || ''}
									label="Descrever Outro Material"
									placeHolder="Informar o nome do outro material..."
									onChangeHandler={([{ value }]) => setOutroMaterial(value)}
									style={{ marginRight: '8px' }}
									disabled={disabled}
								/>
								{!disabled && (
									<button
										type="button"
										className="btn btn-primary"
										style={{ heigth: '39px' }}
										onClick={() => {
											let item = { descricao: outroMaterial, codigo: snakeCase(outroMaterial) };
											size(outroMaterial) &&
												handleSelect({
													name: 'tipoConstrucao',
													value: item,
													operacao: 'add-outro'
												});
										}}
									>
										Adicionar
									</button>
								)}
							</div>
						)}
						<ErrorMessages
							errorList={
								erros?.tipoConstrucao ||
								(errosSubmissao || []).filter(e => (e.campo === 'tipoConstrucao' ? e.message : null))
							}
						/>

						<HistoricoRespostaAnterior
							dataAnterior={infosProjetoDataAnterior['tipoConstrucao']}
							dataAtual={infosProjetoData['tipoConstrucao']}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`tipoConstrucao${sufixoComentario}`}
							valueComentario={infosProjetoData[`tipoConstrucao${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={!!readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`tipoConstrucao${sufixoComentario}`]}
						/>
					</div>
					<div
						className={`pergunta ${
							temComentario[`recuoJardimIsento${sufixoComentario}`]
								? 'resposta-com-comentario pin-comentario'
								: usuarioInterno
								? 'resposta-com-comentario'
								: ''
						}`}
					>
						<RadioFieldLE
							name="recuoJardimIsento"
							label="Conforme PDDUA, há isenção do Recuo de Jardim?"
							sublabel={
								<div style={{ color: 'rgb(133, 100, 4)', fontSize: '10pt', fontStyle: 'italic' }}>
									Este campo deverá ser utilizado para informar dimensão do(s) recuo(s) de jardim, se obrigatório.
								</div>
							}
							options={OPT_SIM_NAO}
							required={true}
							onChangeHandler={localChangeHandler}
							value={infosProjetoData.recuoJardimIsento}
							disabled={disabled}
						/>
						<ErrorMessages
							errorList={
								erros?.recuoJardimIsento ||
								(errosSubmissao || []).filter(e => (e.campo === 'recuoJardimIsento' ? e.message : null))
							}
						/>
						<TextAreaField
							label={'Informe a dimensão do(s) recuo(s) de jardim, quando houver.'}
							required={false}
							placeHolder={''}
							name={'recuoJardimIsentoObservacoes'}
							value={infosProjetoData.recuoJardimIsentoObservacoes || ''}
							onChangeHandler={localChangeHandler}
							containerClass={''}
							errorList={
								erros?.recuoJardimIsentoObservacoes ||
								(errosSubmissao || []).filter(e => (e.campo === 'recuoJardimIsentoObservacoes' ? e.message : null))
							}
							readOnly={false}
						/>
						<HistoricoRespostaAnterior
							dataAnterior={infosProjetoDataAnterior['recuoJardimIsento']}
							dataAtual={infosProjetoData['recuoJardimIsento']}
							usuarioInterno={usuarioInterno}
						/>
						<Comentario
							campoComentario={`recuoJardimIsento${sufixoComentario}`}
							valueComentario={infosProjetoData[`recuoJardimIsento${sufixoComentario}`]}
							onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
							setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
							readOnly={!!readOnlyVersao || !usuarioInterno}
							temComentario={temComentario[`recuoJardimIsento${sufixoComentario}`]}
						/>
					</div>
				</div>
			) : (
				<>
					<div className="projeto-ap">
						<div style={{ display: 'none' }}>
							{/*
									atividadeAnexo52 precisa ser renderizado para carregar as propriedades necessárias,
									mas não deve aparecer mais na tela, já que agora todas os enquadramentos
									tem atividades no 4.2
								*/}
							<PlanoDiretorAnexosSelecao
								data={{ atividadeAnexo52: get(localData, [INFOSPROJETO_CONTROLNAME, 'inicializaStores']) }}
								property="atividadeAnexo52"
								multiple={false}
								label="Atividade"
								url={`${PLANO_DIRETOR_API_URL}/anexo52`}
								containerClass="pergunta selecao2"
								detailCodigo=""
								detailDescricao="atv"
								readOnly={true}
								setDataHandler={() => null}
							/>
						</div>
						<div
							className={`pergunta metragem ${
								temComentario[`alturaMaximaAeroportoAerodromo${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								label="Altura máxima pela guia Aeroporto (DECEA) informada na DMWEB (m)"
								name="alturaMaximaAeroportoAerodromo"
								value={infosProjetoData.alturaMaximaAeroportoAerodromo}
								readOnly={false}
								disabled={disabled}
								onChangeHandler={localChangeHandler}
								maxSize="10"
								decimals={2}
								placeHolder="0,00"
								required={true}
							/>
							<CustomErrorMessages property="alturaMaximaAeroportoAerodromo" errors1={erros} errors2={errosSubmissao} />

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['alturaMaximaAeroportoAerodromo']}
								dataAtual={infosProjetoData['alturaMaximaAeroportoAerodromo']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`alturaMaximaAeroportoAerodromo${sufixoComentario}`}
								valueComentario={infosProjetoData[`alturaMaximaAeroportoAerodromo${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`alturaMaximaAeroportoAerodromo${sufixoComentario}`]}
							/>
						</div>
						<div
							className={`pergunta metragem ${
								temComentario[`alturaTotalProjeto${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								label="Altura do elemento (mastro, antena, iluminação) mais alto do projeto (m). Observar Portaria nº 957/GC3-09/07/15, Cap. VII"
								name="alturaTotalProjeto"
								value={infosProjetoData.alturaTotalProjeto}
								readOnly={false}
								disabled={disabled}
								onChangeHandler={localChangeHandler}
								maxSize="10"
								decimals={2}
								placeHolder="0,00"
								required={true}
							/>
							<ErrorMessages
								errorList={
									erros?.alturaTotalProjeto ||
									(errosSubmissao || []).filter(e => (e.campo === 'alturaTotalProjeto' ? e.message : null))
								}
							/>
							{parseFloat(infosProjetoData?.alturaTotalProjeto?.value || 0) >
								parseFloat(infosProjetoData?.alturaMaximaAeroportoAerodromo?.value || 0) && (
								<ErrorMessages
									type="warning"
									errorList={[
										'A altura do elemento mais alto do projeto ultrapassa a ' +
											'altura máxima permitida pelo DECEA. Deverá apresentar ' +
											'autorização do órgão anteriormente à aprovação do ' +
											'projeto.'
									]}
								/>
							)}
							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['alturaTotalProjeto']}
								dataAtual={infosProjetoData['alturaTotalProjeto']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`alturaTotalProjeto${sufixoComentario}`}
								valueComentario={infosProjetoData[`alturaTotalProjeto${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`alturaTotalProjeto${sufixoComentario}`]}
							/>
						</div>
						<div
							className={`pergunta metragem ${
								temComentario[`numeroPavimentos${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="numeroPavimentos"
								label="Número de pavimentos"
								placeHolder="0"
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.numeroPavimentos}
								maxSize="10"
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={
									erros?.numeroPavimentos ||
									(errosSubmissao || []).filter(e => (e.campo === 'numeroPavimentos' ? e.message : null))
								}
							/>

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['numeroPavimentos']}
								dataAtual={infosProjetoData['numeroPavimentos']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`numeroPavimentos${sufixoComentario}`}
								valueComentario={infosProjetoData[`numeroPavimentos${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`numeroPavimentos${sufixoComentario}`]}
							/>
						</div>

						<div
							className={`pergunta ${
								temComentario[`alpIsenta${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name="alpIsenta"
								label="Conforme PDDUA, há isenção da Área Livre Permeável?"
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.alpIsenta}
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={
									erros?.alpIsenta || (errosSubmissao || []).filter(e => (e.campo === 'alpIsenta' ? e.message : null))
								}
							/>

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['alpIsenta']}
								dataAtual={infosProjetoData['alpIsenta']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`alpIsenta${sufixoComentario}`}
								valueComentario={infosProjetoData[`alpIsenta${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`alpIsenta${sufixoComentario}`]}
							/>
						</div>
						<div
							className={`pergunta ${
								temComentario[`recuoJardimIsento${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name="recuoJardimIsento"
								label="Conforme PDDUA, há isenção do Recuo de Jardim?"
								sublabel={
									<div style={{ color: 'rgb(133, 100, 4)', fontSize: '10pt', fontStyle: 'italic' }}>
										Este campo deverá ser utilizado para informar dimensão do(s) recuo(s) de jardim, se obrigatório.
									</div>
								}
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.recuoJardimIsento}
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={
									erros?.recuoJardimIsento ||
									(errosSubmissao || []).filter(e => (e.campo === 'recuoJardimIsento' ? e.message : null))
								}
							/>
							<TextAreaField
								label={'Informe a dimensão do(s) recuo(s) de jardim, quando houver.'}
								required={false}
								placeHolder={''}
								name={'recuoJardimIsentoObservacoes'}
								value={infosProjetoData.recuoJardimIsentoObservacoes || ''}
								onChangeHandler={localChangeHandler}
								containerClass={''}
								errorList={
									erros?.recuoJardimIsentoObservacoes ||
									(errosSubmissao || []).filter(e => (e.campo === 'recuoJardimIsentoObservacoes' ? e.message : null))
								}
								readOnly={false}
							/>
							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['recuoJardimIsento']}
								dataAtual={infosProjetoData['recuoJardimIsento']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`recuoJardimIsento${sufixoComentario}`}
								valueComentario={infosProjetoData[`recuoJardimIsento${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`recuoJardimIsento${sufixoComentario}`]}
							/>
						</div>

						{!isNaoResidencial && (
							<div
								className={`pergunta metragem ${
									temComentario[`numeroDormitorios${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<NumberFieldLE
									name="numeroDormitorios"
									label="Número de dormitórios"
									placeHolder="0"
									required={false}
									onChangeHandler={localChangeHandler}
									value={infosProjetoData.numeroDormitorios}
									maxSize="10"
									disabled={disabled}
								/>
								<ErrorMessages
									errorList={
										erros?.numeroDormitorios ||
										(errosSubmissao || []).filter(e => (e.campo === 'numeroDormitorios' ? e.message : null))
									}
								/>

								<HistoricoRespostaAnterior
									dataAnterior={infosProjetoDataAnterior['numeroDormitorios']}
									dataAtual={infosProjetoData['numeroDormitorios']}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`numeroDormitorios${sufixoComentario}`}
									valueComentario={infosProjetoData[`numeroDormitorios${sufixoComentario}`]}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={!!readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`numeroDormitorios${sufixoComentario}`]}
								/>
							</div>
						)}
						<div
							className={`pergunta metragem ${
								temComentario[`tipoConstrucao${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<div className="flex-start" style={{ width: '100%', alignItems: 'baseline' }}>
								<label>
									Tipo de material<span className="required">*</span>
								</label>
								<Selecao
									className={'form-control'}
									multiple={true}
									selected={Object.values(infosProjetoData?.tipoConstrucao || {})}
									label={'Tipo de construção'}
									detailCodigo={''}
									detailDescricao={'descricao'}
									autoShowList={true}
									searchTerm={searchMaterial}
									searchList={filterList(tiposConstrucao, searchMaterial)}
									searchTermMinLength={1}
									errorList={false}
									onChangeSearchTerm={e => setSearchMaterial(e.target.value)}
									onBlurSearchTerm={() => false}
									onSelectItem={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'add' })}
									onUnselect={item => () => handleSelect({ name: 'tipoConstrucao', value: item, operacao: 'remove' })}
									loading={false}
									required={true}
									placeholder="Tipo de material"
									readOnly={disabled}
								/>
							</div>
							{hasOutroMaterial && (
								<div className="flex-start" style={{ alignItems: 'baseline' }}>
									<TextField
										name="outroMaterial"
										value={outroMaterial || ''}
										label="Descrever Outro Material"
										placeHolder="Informar o nome do outro material..."
										onChangeHandler={([{ value }]) => setOutroMaterial(value)}
										style={{ marginRight: '8px' }}
										disabled={disabled}
									/>
									{!disabled && (
										<button
											type="button"
											className="btn btn-primary"
											style={{ heigth: '39px' }}
											onClick={() => {
												let item = { descricao: outroMaterial, codigo: snakeCase(outroMaterial) };
												size(outroMaterial) &&
													handleSelect({
														name: 'tipoConstrucao',
														value: item,
														operacao: 'add-outro'
													});
											}}
										>
											Adicionar
										</button>
									)}
								</div>
							)}
							<ErrorMessages
								errorList={
									erros?.tipoConstrucao ||
									(errosSubmissao || []).filter(e => (e.campo === 'tipoConstrucao' ? e.message : null))
								}
							/>

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['tipoConstrucao']}
								dataAtual={infosProjetoData['tipoConstrucao']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`tipoConstrucao${sufixoComentario}`}
								valueComentario={infosProjetoData[`tipoConstrucao${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`tipoConstrucao${sufixoComentario}`]}
							/>
						</div>

						{((isNaoResidencial && parseInt(infosProjetoData?.numeroEconomiasNaoResidencial?.value) === 1) ||
							isEnqHabColetiva) && (
							<div
								className={`pergunta ${
									temComentario[`trintaPorcentoIa${sufixoComentario}`]
										? 'resposta-com-comentario pin-comentario'
										: usuarioInterno
										? 'resposta-com-comentario'
										: ''
								}`}
							>
								<RadioFieldLE
									name="trintaPorcentoIa"
									label="O projeto utilizará acréscimo de 30% de índice de aproveitamento conforme Art. 107, §5º do PDDUA?"
									options={OPT_SIM_NAO}
									required={true}
									onChangeHandler={localChangeHandler}
									value={infosProjetoData.trintaPorcentoIa}
									disabled={disabled}
								/>
								<ErrorMessages
									errorList={
										erros?.trintaPorcentoIa ||
										(errosSubmissao || []).filter(e => (e.campo === 'trintaPorcentoIa' ? e.message : null))
									}
								/>

								<HistoricoRespostaAnterior
									dataAnterior={infosProjetoDataAnterior['trintaPorcentoIa']}
									dataAtual={infosProjetoData['trintaPorcentoIa']}
									usuarioInterno={usuarioInterno}
								/>
								<Comentario
									campoComentario={`trintaPorcentoIa${sufixoComentario}`}
									valueComentario={infosProjetoData[`trintaPorcentoIa${sufixoComentario}`]}
									onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
									readOnly={!!readOnlyVersao || !usuarioInterno}
									temComentario={temComentario[`trintaPorcentoIa${sufixoComentario}`]}
								/>
							</div>
						)}
					</div>
					<div>
						{/* subunidades */}
						<div>
							<legend className="legenda-titulo-secundario">{`${
								isUnidadeAutonoma ? '3' : '4'
							}.2 Dados das subunidades`}</legend>
							{size(subunidades) === 0 && (
								<Alert variant="warning" style={{ whiteSpace: 'pre-wrap', marginTop: '10px', marginLeft: '0px' }}>
									Os dados serão apresentados quando houver subunidades selecionadas
								</Alert>
							)}
							{subunidades.map((sub, i) => (
								<InfosProjetoSubUnidadesAP
									key={sub.id}
									data={localData}
									tipoForm={tipoForm}
									onChangeHandler={localChangeHandler}
									dadosSubunidade={sub}
									subunidadesAnterior={subunidadesAnterior}
									dadosSubunidadeAnterior={subunidadesAnterior.find(sbAnt => sbAnt.id === sub.id)}
									atividadeModificada={mudarSubunidades[i]}
									atividadeModificadaCallback={atividadeCallbackHandle(i)}
									errosSubmissao={errosSubmissao}
									indicesConstrutivos={possuiIndicesConstrutivos}
									showAvisoAreaAplicacaoRu={showAvisoAreaAplicacaoRu}
									setShowAvisoAreaAplicacaoRu={setShowAvisoAreaAplicacaoRu}
									disabled={disabled}
									changeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
									usuarioInterno={usuarioInterno}
									setAdvertenciaRestricoes={setAdvertenciaRestricoes}
									readOnlyVersao={readOnlyVersao}
								/>
							))}
							{subunidadesAnterior
								.filter(subAnt => !subunidades.find(subAtual => subAnt.id === subAtual.id))
								.map((sub, indiceFilter) => (
									<div
										key={`sbus-removidas-${indiceFilter}`}
										className="conjunto-dados-projeto-subunidade subunidade-removida"
									>
										<div>
											<div>
												<h2 className="titulo-subunidade-ap">Subunidade {sub?.subunidade?.subunidade}</h2>
												<HistoricoRespostaAnterior
													dataAnterior={'1'}
													dataAtual={'0'}
													usuarioInterno={usuarioInterno}
													msgAlt={'Esta subunidade foi removida'}
												/>
											</div>
										</div>
									</div>
								))}
						</div>
						{/* fim das subunidades */}
						<legend className="legenda-titulo-secundario">{`${isUnidadeAutonoma ? '3' : '4'}.3 Dados gerais`}</legend>
						{isNaoResidenciais && (
							<>
								<div
									className={`pergunta ${
										temComentario[`projetoAtendeRestricoesAtividades${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<RadioFieldLE
										name="projetoAtendeRestricoesAtividades"
										label={
											'A(s) atividade(s) não residencial(is) (conforme ANEXO 5.2 do PDDUA) atende(m) a RESTRIÇÃO QUANTO À IMPLANTAÇÃO DE ATIVIDADES NA ÁREA DE OCUPAÇÃO INTENSIVA (ANEXO 5.3) e/ou RESTRIÇÃO QUANTO AOS LIMITES DE PORTE NA ÁREA DE OCUPAÇÃO INTENSIVA (ANEXO 5.4)?'
										}
										options={OPT_SIM_NAO}
										required={true}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.projetoAtendeRestricoesAtividades}
										disabled={disabled}
									/>
									<ErrorMessages
										errorList={
											erros?.projetoAtendeRestricoesAtividades ||
											(errosSubmissao || []).filter(e =>
												e.campo === 'projetoAtendeRestricoesAtividades' ? e.message : null
											)
										}
									/>
									{infosProjetoData?.projetoAtendeRestricoesAtividades === 'sim' ? (
										<WarningMessage>
											{
												'O atendimento aos Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA) requer declaração do responsável técnico de que está atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte"5. Termo de Responsabilidade"'
											}
										</WarningMessage>
									) : (
										infosProjetoData?.projetoAtendeRestricoesAtividades === 'nao' && (
											<>
												{size(
													(errosSubmissao || []).filter(e =>
														e.campo === 'projetoAtendeRestricoesAtividades' ? e.message : null
													)
												) === 0 && size(erros?.projetoAtendeRestricoesAtividades) === 0 ? (
													<ErrorMessages
														errorList={[
															'A(s) atividade(s) do projeto deve(m) estar de acordo com os Anexos 5.3 e 5.4 do Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA)'
														]}
													/>
												) : null}
											</>
										)
									)}

									<HistoricoRespostaAnterior
										dataAnterior={infosProjetoDataAnterior['projetoAtendeRestricoesAtividades']}
										dataAtual={infosProjetoData['projetoAtendeRestricoesAtividades']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`projetoAtendeRestricoesAtividades${sufixoComentario}`}
										valueComentario={infosProjetoData[`projetoAtendeRestricoesAtividades${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`projetoAtendeRestricoesAtividades${sufixoComentario}`]}
									/>
								</div>
								<div
									className={`pergunta ${
										temComentario[`projetoImpactoUrbano${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<RadioFieldLE
										name="projetoImpactoUrbano"
										label={
											'A(s) atividade(s) não residencial(ais), conforme Anexo 5.2 do PDDUA, referem-se a Projetos Especiais de Impacto Urbano de 1° ou 2º grau, listadas no Anexo 11 do PDDUA?'
										}
										options={OPT_SIM_NAO}
										required={true}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.projetoImpactoUrbano}
										disabled={disabled}
									/>
									<ErrorMessages
										errorList={
											erros?.projetoImpactoUrbano ||
											(errosSubmissao || []).filter(e => (e.campo === 'projetoImpactoUrbano' ? e.message : null))
										}
									/>

									<HistoricoRespostaAnterior
										dataAnterior={infosProjetoDataAnterior['projetoImpactoUrbano']}
										dataAtual={infosProjetoData['projetoImpactoUrbano']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`projetoImpactoUrbano${sufixoComentario}`}
										valueComentario={infosProjetoData[`projetoImpactoUrbano${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`projetoImpactoUrbano${sufixoComentario}`]}
									/>
									{infosProjetoData?.projetoImpactoUrbano === 'sim' && (
										<div
											className={`pergunta ${
												temComentario[`projetoViabilidaUrbanistico${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="projetoViabilidaUrbanistico"
												label={
													'O projeto possui Estudo de Viabilidade Urbanístico (EVU) válido referente a atividade conforme Art. 159 do PDDUA?'
												}
												options={OPT_SIM_NAO}
												required={true}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.projetoViabilidaUrbanistico}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.projetoViabilidaUrbanistico ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'projetoViabilidaUrbanistico' ? e.message : null
													)
												}
											/>
											{infosProjetoData?.projetoViabilidaUrbanistico === 'nao' &&
												size(
													(errosSubmissao || []).filter(e =>
														e.campo === 'projetoViabilidaUrbanistico' ? e.message : null
													)
												) === 0 &&
												size(erros?.projetoViabilidaUrbanistico) === 0 && <ErrorMessages errorList={[AVISO_ESTUDO]} />}

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['projetoViabilidaUrbanistico']}
												dataAtual={infosProjetoData['projetoViabilidaUrbanistico']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`projetoViabilidaUrbanistico${sufixoComentario}`}
												valueComentario={infosProjetoData[`projetoViabilidaUrbanistico${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`projetoViabilidaUrbanistico${sufixoComentario}`]}
											/>
										</div>
									)}
								</div>
								{isDecretoEntrenimentoNoturnoMemo && (
									<>
										<div
											className={`pergunta ${
												temComentario[`atividadeEntrenenimentoNoturno${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="atividadeEntrenenimentoNoturno"
												label="A(s) atividade(s) do projeto enquadra-se como atividade de entretenimento noturno, com horário de funcionamento que se estenda após as 00h00min, de acordo com o Art. 1º do Decreto Municipal nº 18.572/2014?"
												value={infosProjetoData.atividadeEntrenenimentoNoturno}
												options={OPT_SIM_NAO}
												required
												onChangeHandler={localChangeHandler}
												tooltip={INFO_TOOLTIP_ATV_NOTURNA}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.atividadeEntrenenimentoNoturno ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'atividadeEntrenenimentoNoturno' ? e.message : null
													)
												}
											/>
											{get(infosProjetoData, 'atividadeEntrenenimentoNoturno') === 'sim' && (
												<ErrorMessages
													type="warning"
													errorList={[
														'A(s) atividade(s) do projeto deve(m) atender os termos do Decreto 18.572/2014 e Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA). Será permitido o protocolo do projeto em caso de Estudo de Viabilidade Urbanítica (EVU) aprovado e válido.'
													]}
												/>
											)}

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['atividadeEntrenenimentoNoturno']}
												dataAtual={infosProjetoData['atividadeEntrenenimentoNoturno']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`atividadeEntrenenimentoNoturno${sufixoComentario}`}
												valueComentario={infosProjetoData[`atividadeEntrenenimentoNoturno${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`atividadeEntrenenimentoNoturno${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta ${
												temComentario[`atividadeEntrenenimentoNoturnoComMusicaAmplificada${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="atividadeEntrenenimentoNoturnoComMusicaAmplificada"
												label="A(s) atividade(s) do projeto enquadra-se como atividade de entretenimento noturno, com horário de funcionamento que se estenda após as 00h00min e com música amplificada (mecânica e/ou ao vivo), de acordo com o §1º do Art. 1º do Decreto Municipal nº 18.572/2014?"
												value={infosProjetoData.atividadeEntrenenimentoNoturnoComMusicaAmplificada}
												options={OPT_SIM_NAO}
												required
												onChangeHandler={localChangeHandler}
												tooltip={INFO_TOOLTIP_ATV_NOTURNA_SOM}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.atividadeEntrenenimentoNoturnoComMusicaAmplificada ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'atividadeEntrenenimentoNoturnoComMusicaAmplificada' ? e.message : null
													)
												}
											/>
											{get(infosProjetoData, 'atividadeEntrenenimentoNoturnoComMusicaAmplificada') === 'sim' && (
												<ErrorMessages
													type="warning"
													errorList={[
														'A(s) atividade(s) do projeto deve(m) atender os termos do Decreto 18.572/2014 e Plano Diretor de Desenvolvimento Urbano Ambiental (PDDUA). Será permitido o protocolo do projeto em caso de Estudo de Viabilidade Urbanítica (EVU) aprovado e válido.'
													]}
												/>
											)}

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['atividadeEntrenenimentoNoturnoComMusicaAmplificada']}
												dataAtual={infosProjetoData['atividadeEntrenenimentoNoturnoComMusicaAmplificada']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`atividadeEntrenenimentoNoturnoComMusicaAmplificada${sufixoComentario}`}
												valueComentario={
													localData[`atividadeEntrenenimentoNoturnoComMusicaAmplificada${sufixoComentario}`]
												}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={
													temComentario[`atividadeEntrenenimentoNoturnoComMusicaAmplificada${sufixoComentario}`]
												}
											/>
										</div>
									</>
								)}
								{isAtividadesEnsinoMemo && (
									<div
										className={`pergunta ${
											temComentario[`atividadeEnsinoPreEscolarInfantilMaternal${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<RadioFieldLE
											name="atividadeEnsinoPreEscolarInfantilMaternal"
											label="A(s) atividade(s) do projeto enquadra-se como estabelecimento de ensino pré-escolar/escola de educação infantil/escola maternal?"
											value={infosProjetoData.atividadeEnsinoPreEscolarInfantilMaternal}
											options={OPT_SIM_NAO}
											required
											onChangeHandler={localChangeHandler}
											disabled={disabled}
										/>
										<ErrorMessages
											errorList={
												erros?.atividadeEnsinoPreEscolarInfantilMaternal ||
												(errosSubmissao || []).filter(e =>
													e.campo === 'atividadeEnsinoPreEscolarInfantilMaternal' ? e.message : null
												)
											}
										/>

										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['atividadeEnsinoPreEscolarInfantilMaternal']}
											dataAtual={infosProjetoData['atividadeEnsinoPreEscolarInfantilMaternal']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`atividadeEnsinoPreEscolarInfantilMaternal${sufixoComentario}`}
											valueComentario={infosProjetoData[`atividadeEnsinoPreEscolarInfantilMaternal${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={!!readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`atividadeEnsinoPreEscolarInfantilMaternal${sufixoComentario}`]}
										/>
									</div>
								)}
								{isAtividadeDepositoRevendaGasMemo && (
									<>
										<div
											className={`pergunta ${
												temComentario[`atividadeDepositoPostoRevendaGas${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="atividadeDepositoPostoRevendaGas"
												label="A(s) atividade(s) do projeto enquadra-se como depósito ou posto de revenda de gás?"
												value={infosProjetoData.atividadeDepositoPostoRevendaGas}
												options={OPT_SIM_NAO}
												required
												onChangeHandler={localChangeHandler}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.atividadeDepositoPostoRevendaGas ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'atividadeDepositoPostoRevendaGas' ? e.message : null
													)
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['atividadeDepositoPostoRevendaGas']}
												dataAtual={infosProjetoData['atividadeDepositoPostoRevendaGas']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`atividadeDepositoPostoRevendaGas${sufixoComentario}`}
												valueComentario={infosProjetoData[`atividadeDepositoPostoRevendaGas${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`atividadeDepositoPostoRevendaGas${sufixoComentario}`]}
											/>
										</div>
										{get(infosProjetoData, 'atividadeDepositoPostoRevendaGas') === 'sim' && (
											<div
												className={`pergunta ${
													temComentario[`atividadeDepositoPostoRevendaGasPossuiDistanciaMinima${sufixoComentario}`]
														? 'resposta-com-comentario pin-comentario'
														: usuarioInterno
														? 'resposta-com-comentario'
														: ''
												}`}
											>
												<RadioFieldLE
													name="atividadeDepositoPostoRevendaGasPossuiDistanciaMinima"
													label="O projeto atende as distâncias mínimas de segurança, conforme determina o Anexo 5.8, alterado pelo Dec. 17.892/2012, se for o caso de revenda e depósito de gás?"
													value={infosProjetoData.atividadeDepositoPostoRevendaGasPossuiDistanciaMinima}
													options={OPT_SIM_NAO}
													required
													onChangeHandler={localChangeHandler}
													disabled={disabled}
												/>
												<ErrorMessages
													errorList={
														erros?.atividadeDepositoPostoRevendaGasPossuiDistanciaMinima ||
														(errosSubmissao || []).filter(e =>
															e.campo === 'atividadeDepositoPostoRevendaGasPossuiDistanciaMinima' ? e.message : null
														)
													}
												/>

												<HistoricoRespostaAnterior
													dataAnterior={
														infosProjetoDataAnterior['atividadeDepositoPostoRevendaGasPossuiDistanciaMinima']
													}
													dataAtual={infosProjetoData['atividadeDepositoPostoRevendaGasPossuiDistanciaMinima']}
													usuarioInterno={usuarioInterno}
												/>
												<Comentario
													campoComentario={`atividadeDepositoPostoRevendaGasPossuiDistanciaMinima${sufixoComentario}`}
													valueComentario={
														localData[`atividadeDepositoPostoRevendaGasPossuiDistanciaMinima${sufixoComentario}`]
													}
													onChangeComentarioHandler={
														typeof changeComentarioHandler === 'function' ? localChangeHandler : null
													}
													setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
													readOnly={!!readOnlyVersao || !usuarioInterno}
													temComentario={
														temComentario[`atividadeDepositoPostoRevendaGasPossuiDistanciaMinima${sufixoComentario}`]
													}
												/>
											</div>
										)}
									</>
								)}
								{isInterferenciaAmbientalMemo && (
									<>
										<div
											className={`pergunta ${
												temComentario[`atividadeSaude${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="atividadeSaude"
												label="A(s) atividade(s) do projeto enquadra-se como Ambulatório, Posto de Atendimento Médico ou Clínica/Policlínica?"
												value={infosProjetoData.atividadeSaude}
												options={OPT_SIM_NAO}
												required
												onChangeHandler={localChangeHandler}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.atividadeSaude ||
													(errosSubmissao || []).filter(e => (e.campo === 'atividadeSaude' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['atividadeSaude']}
												dataAtual={infosProjetoData['atividadeSaude']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`atividadeSaude${sufixoComentario}`}
												valueComentario={infosProjetoData[`atividadeSaude${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`atividadeSaude${sufixoComentario}`]}
											/>

											{get(infosProjetoData, 'atividadeSaude') === 'sim' && (
												<div
													className={`pergunta ${
														temComentario[`atividadeSaudePossuiVagaAmbulancia${sufixoComentario}`]
															? 'resposta-com-comentario pin-comentario'
															: usuarioInterno
															? 'resposta-com-comentario'
															: ''
													}`}
												>
													<RadioFieldLE
														name="atividadeSaudePossuiVagaAmbulancia"
														label="O projeto atende ao mínimo de 1 vaga especial no interior do lote para Embarque/Desembarque com dimensões para ambulância?"
														value={infosProjetoData.atividadeSaudePossuiVagaAmbulancia}
														options={OPT_SIM_NAO}
														required
														onChangeHandler={localChangeHandler}
														disabled={disabled}
													/>
													<ErrorMessages
														errorList={
															erros?.atividadeSaudePossuiVagaAmbulancia ||
															(errosSubmissao || []).filter(e =>
																e.campo === 'atividadeSaudePossuiVagaAmbulancia' ? e.message : null
															)
														}
													/>

													{infosProjetoData?.atividadeSaudePossuiVagaAmbulancia === 'sim' ? (
														<WarningMessage>
															O atendimento ao mínimo de 1 vaga especial no interior do lote para Embarque/Desembarque
															com dimensões para ambulância requer declaração do responsável técnico de que está
															atendendo à legislação pertinente no termo de responsabilidade, assinado na etapa seguinte
															"5. Termo de Responsabilidade"
														</WarningMessage>
													) : infosProjetoData?.atividadeSaudePossuiVagaAmbulancia === 'nao' &&
													  size(
															(errosSubmissao || []).filter(e =>
																e.campo === 'atividadeSaudePossuiVagaAmbulancia' ? e.message : null
															)
													  ) === 0 &&
													  size(erros?.atividadeSaudePossuiVagaAmbulancia) === 0 ? (
														<ErrorMessages
															errorList={[
																'A(s) atividade(s) do projeto deve(m) prever no mínimo 1 vaga especial no interior do lote para Embarque/Desembarque com dimensões para ambulância.'
															]}
														/>
													) : null}

													<HistoricoRespostaAnterior
														dataAnterior={infosProjetoDataAnterior['atividadeSaudePossuiVagaAmbulancia']}
														dataAtual={infosProjetoData['atividadeSaudePossuiVagaAmbulancia']}
														usuarioInterno={usuarioInterno}
													/>
													<Comentario
														campoComentario={`atividadeSaudePossuiVagaAmbulancia${sufixoComentario}`}
														valueComentario={infosProjetoData[`atividadeSaudePossuiVagaAmbulancia${sufixoComentario}`]}
														onChangeComentarioHandler={
															typeof changeComentarioHandler === 'function' ? localChangeHandler : null
														}
														setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
														readOnly={!!readOnlyVersao || !usuarioInterno}
														temComentario={temComentario[`atividadeSaudePossuiVagaAmbulancia${sufixoComentario}`]}
													/>
												</div>
											)}
										</div>
									</>
								)}
							</>
						)}
						{/* INICIO */}
						{!showAvisoAreaAplicacaoRu && (
							<>
								{(isNaoResidenciais || isMultifamiliar || isEnqHabColetiva) && (
									<div
										className={`pergunta metragem ${
											temComentario[`indiceAproveitamentoTotalPermitido${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="indiceAproveitamentoTotalPermitido"
											label="Índice de Aproveitamento (IA) total permitido (m²)"
											value={infosProjetoData.indiceAproveitamentoTotalPermitido}
											decimals={2}
											maxSize="10"
											readOnly={true}
											disabled={disabled}
										/>
										<ErrorMessages
											errorList={
												erros?.indiceAproveitamentoTotalPermitido ||
												(errosSubmissao || []).filter(e =>
													e.campo === 'indiceAproveitamentoTotalPermitido' ? e.message : null
												)
											}
										/>

										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['indiceAproveitamentoTotalPermitido']}
											dataAtual={infosProjetoData['indiceAproveitamentoTotalPermitido']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`indiceAproveitamentoTotalPermitido${sufixoComentario}`}
											valueComentario={infosProjetoData[`indiceAproveitamentoTotalPermitido${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={!!readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`indiceAproveitamentoTotalPermitido${sufixoComentario}`]}
										/>
									</div>
								)}
								{(isNaoResidenciais || isMultifamiliar) && possuiIndicesConstrutivos && (
									<div
										className={`pergunta metragem ${
											temComentario[`indiceAproveitamentoMaximoTotalPermitido${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="indiceAproveitamentoMaximoTotalPermitido"
											label="Índice de Aproveitamento (IA) Máximo total permitido (m²)"
											value={infosProjetoData.indiceAproveitamentoMaximoTotalPermitido}
											decimals={2}
											maxSize="10"
											readOnly={true}
											disabled={disabled}
										/>
										<ErrorMessages
											errorList={
												erros?.indiceAproveitamentoMaximoTotalPermitido ||
												(errosSubmissao || []).filter(e =>
													e.campo === 'indiceAproveitamentoMaximoTotalPermitido' ? e.message : null
												)
											}
										/>

										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['indiceAproveitamentoMaximoTotalPermitido']}
											dataAtual={infosProjetoData['indiceAproveitamentoMaximoTotalPermitido']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`indiceAproveitamentoMaximoTotalPermitido${sufixoComentario}`}
											valueComentario={infosProjetoData[`indiceAproveitamentoMaximoTotalPermitido${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={!!readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`indiceAproveitamentoMaximoTotalPermitido${sufixoComentario}`]}
										/>
									</div>
								)}

								{(isMultifamiliar || isMultifamiliarNaoResidencial) && infosProjetoData?.deveUsarQuotaIdealProjeto && (
									<div
										className={`pergunta metragem ${
											temComentario[`quantidadeMaxEconomiasResidenciaisProjeto${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="quantidadeMaxEconomiasResidenciaisProjeto"
											label="Número de economias permitido aplicando a quota ideal (QI)"
											value={infosProjetoData.quantidadeMaxEconomiasResidenciaisProjeto}
											maxSize="10"
											readOnly={true}
											disabled={disabled}
										/>
										<ErrorMessages
											errorList={
												erros?.quantidadeMaxEconomiasResidenciaisProjeto ||
												(errosSubmissao || []).filter(e =>
													e.campo === 'quantidadeMaxEconomiasResidenciaisProjeto' ? e.message : null
												)
											}
										/>

										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['quantidadeMaxEconomiasResidenciaisProjeto']}
											dataAtual={infosProjetoData['quantidadeMaxEconomiasResidenciaisProjeto']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`quantidadeMaxEconomiasResidenciaisProjeto${sufixoComentario}`}
											valueComentario={infosProjetoData[`quantidadeMaxEconomiasResidenciaisProjeto${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={!!readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`quantidadeMaxEconomiasResidenciaisProjeto${sufixoComentario}`]}
										/>
									</div>
								)}

								{(isMultifamiliar || isUnifamiliarENaoResidencial || isMultifamiliarNaoResidencial) && (
									<div
										className={`pergunta metragem ${
											temComentario[`numeroEconomiasResidencial${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="numeroEconomiasResidencial"
											label="Número de Economias - Residencial"
											placeHolder="0"
											required={true}
											onChangeHandler={localChangeHandler}
											value={infosProjetoData.numeroEconomiasResidencial}
											maxSize="10"
											disabled={disabled}
										/>
										<ErrorMessages
											errorList={(errosSubmissao || []).filter(e =>
												e.campo === 'numeroEconomiasResidencial' ? e.message : null
											)}
										/>
										{size(
											(errosSubmissao || []).filter(e => (e.campo === 'numeroEconomiasResidencial' ? e.message : null))
										) === 0 && <ErrorMessages errorList={erros?.numeroEconomiasResidencial} />}

										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['numeroEconomiasResidencial']}
											dataAtual={infosProjetoData['numeroEconomiasResidencial']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`numeroEconomiasResidencial${sufixoComentario}`}
											valueComentario={infosProjetoData[`numeroEconomiasResidencial${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={!!readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`numeroEconomiasResidencial${sufixoComentario}`]}
										/>
									</div>
								)}

								{isNaoResidenciais && (
									<>
										<div
											className={`pergunta metragem ${
												temComentario[`numeroEconomiasNaoResidencial${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="numeroEconomiasNaoResidencial"
												label="Número de Economias - Não Residencial"
												placeHolder="0"
												required={true}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.numeroEconomiasNaoResidencial}
												maxSize="10"
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={(errosSubmissao || []).filter(e =>
													e.campo === 'numeroEconomiasNaoResidencial' ? e.message : null
												)}
											/>
											{size(
												(errosSubmissao || []).filter(e =>
													e.campo === 'numeroEconomiasNaoResidencial' ? e.message : null
												)
											) === 0 && <ErrorMessages errorList={erros?.numeroEconomiasNaoResidencial} />}

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['numeroEconomiasNaoResidencial']}
												dataAtual={infosProjetoData['numeroEconomiasNaoResidencial']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`numeroEconomiasNaoResidencial${sufixoComentario}`}
												valueComentario={infosProjetoData[`numeroEconomiasNaoResidencial${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`numeroEconomiasNaoResidencial${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`numeroEconomiasTotal${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="numeroEconomiasTotal"
												label="Número de Economias - Total"
												placeHolder="0"
												required={true}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.numeroEconomiasTotal}
												maxSize="10"
												readOnly={true}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={(errosSubmissao || []).filter(e =>
													e.campo === 'numeroEconomiasTotal' ? e.message : null
												)}
											/>
											{size(
												(errosSubmissao || []).filter(e => (e.campo === 'numeroEconomiasTotal' ? e.message : null))
											) === 0 && <ErrorMessages errorList={erros?.numeroEconomiasTotal} />}

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['numeroEconomiasTotal']}
												dataAtual={infosProjetoData['numeroEconomiasTotal']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`numeroEconomiasTotal${sufixoComentario}`}
												valueComentario={infosProjetoData[`numeroEconomiasTotal${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`numeroEconomiasTotal${sufixoComentario}`]}
											/>
										</div>
									</>
								)}

								<div
									className={`pergunta ${
										temComentario[`projetoPossuiBaseCorpo${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<RadioFieldLE
										name="projetoPossuiBaseCorpo"
										label="O projeto possui base e corpo?"
										options={OPT_SIM_NAO}
										required={true}
										onChangeHandler={localChangeHandler}
										value={infosProjetoData.projetoPossuiBaseCorpo}
										disabled={disabled}
									/>
									<ErrorMessages
										errorList={
											erros?.projetoPossuiBaseCorpo ||
											(errosSubmissao || []).filter(e => (e.campo === 'projetoPossuiBaseCorpo' ? e.message : null))
										}
									/>

									<HistoricoRespostaAnterior
										dataAnterior={infosProjetoDataAnterior['projetoPossuiBaseCorpo']}
										dataAtual={infosProjetoData['projetoPossuiBaseCorpo']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`projetoPossuiBaseCorpo${sufixoComentario}`}
										valueComentario={infosProjetoData[`projetoPossuiBaseCorpo${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`projetoPossuiBaseCorpo${sufixoComentario}`]}
									/>
								</div>
								{isProjetoPossuiBaseCorpo && (
									<>
										<div
											className={`pergunta metragem ${
												temComentario[`totalPermitidoBase${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalPermitidoBase"
												label="Taxa de Ocupação (TO) total permitido para a base (m²)"
												value={infosProjetoData.totalPermitidoBase}
												decimals={2}
												maxSize="10"
												readOnly={true}
												disabled={disabled}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalPermitidoBase']}
												dataAtual={infosProjetoData['totalPermitidoBase']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalPermitidoBase${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalPermitidoBase${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalPermitidoBase${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`totalBaseProjeto${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalBaseProjeto"
												label="Taxa de Ocupação (TO) total da base do projeto (m²)"
												required={true}
												value={infosProjetoData.totalBaseProjeto}
												decimals={2}
												maxSize="10"
												onChangeHandler={localChangeHandler}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.totalBaseProjeto ||
													(errosSubmissao || []).filter(e => (e.campo === 'totalBaseProjeto' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalBaseProjeto']}
												dataAtual={infosProjetoData['totalBaseProjeto']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalBaseProjeto${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalBaseProjeto${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalBaseProjeto${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`totalPermitidoCorpo${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalPermitidoCorpo"
												label="Taxa de Ocupação (TO) total permitido para o corpo (m²)"
												value={infosProjetoData.totalPermitidoCorpo}
												decimals={2}
												maxSize="10"
												readOnly={true}
												disabled={disabled}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalPermitidoCorpo']}
												dataAtual={infosProjetoData['totalPermitidoCorpo']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalPermitidoCorpo${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalPermitidoCorpo${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalPermitidoCorpo${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`totalCorpoProjeto${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalCorpoProjeto"
												label="Taxa de Ocupação (TO) total do corpo do projeto (m²)"
												required={true}
												value={infosProjetoData.totalCorpoProjeto}
												decimals={2}
												maxSize="10"
												onChangeHandler={localChangeHandler}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.totalCorpoProjeto ||
													(errosSubmissao || []).filter(e => (e.campo === 'totalCorpoProjeto' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalCorpoProjeto']}
												dataAtual={infosProjetoData['totalCorpoProjeto']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalCorpoProjeto${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalCorpoProjeto${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalCorpoProjeto${sufixoComentario}`]}
											/>
										</div>
									</>
								)}
								<div
									className={`pergunta metragem ${
										temComentario[`taxaOcupacaoTotalPermitido${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<NumberFieldLE
										name="taxaOcupacaoTotalPermitido"
										label="Taxa de Ocupação (TO) total permitido (m²)"
										value={infosProjetoData.taxaOcupacaoTotalPermitido}
										decimals={2}
										maxSize="10"
										readOnly={true}
										disabled={disabled}
									/>

									<HistoricoRespostaAnterior
										dataAnterior={infosProjetoDataAnterior['taxaOcupacaoTotalPermitido']}
										dataAtual={infosProjetoData['taxaOcupacaoTotalPermitido']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`taxaOcupacaoTotalPermitido${sufixoComentario}`}
										valueComentario={infosProjetoData[`taxaOcupacaoTotalPermitido${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`taxaOcupacaoTotalPermitido${sufixoComentario}`]}
									/>
								</div>
							</>
						)}
						{/* FIM */}
						<div
							className={`pergunta metragem ${
								temComentario[`taxaOcupacaoTotal${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<NumberFieldLE
								name="taxaOcupacaoTotal"
								label="Taxa de Ocupação (TO) total do projeto (m²)"
								required={true}
								value={infosProjetoData.taxaOcupacaoTotal}
								decimals={2}
								maxSize="10"
								onChangeHandler={localChangeHandler}
								disabled={disabled}
							/>
							<CustomErrorMessages property="taxaOcupacaoTotal" errors1={erros} errors2={errosSubmissao} />

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['taxaOcupacaoTotal']}
								dataAtual={infosProjetoData['taxaOcupacaoTotal']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`taxaOcupacaoTotal${sufixoComentario}`}
								valueComentario={infosProjetoData[`taxaOcupacaoTotal${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`taxaOcupacaoTotal${sufixoComentario}`]}
							/>
						</div>
						<div
							className={`pergunta ${
								temComentario[`projetoPossuiEvuValido${sufixoComentario}`]
									? 'resposta-com-comentario pin-comentario'
									: usuarioInterno
									? 'resposta-com-comentario'
									: ''
							}`}
						>
							<RadioFieldLE
								name="projetoPossuiEvuValido"
								label="O projeto possui Estudo de Viabilidade Urbanística aprovado e válido?"
								options={OPT_SIM_NAO}
								required={true}
								onChangeHandler={localChangeHandler}
								value={infosProjetoData.projetoPossuiEvuValido}
								disabled={disabled}
							/>
							<ErrorMessages
								errorList={
									erros?.projetoPossuiEvuValido ||
									(errosSubmissao || []).filter(e => (e.campo === 'projetoPossuiEvuValido' ? e.message : null))
								}
							/>
							{advertenciaRestricoes && infosProjetoData.projetoPossuiEvuValido === 'nao' && (
								<ErrorMessages
									type="warning"
									errorList={[
										{
											type: 'warning',
											message: 'A atividade necessita de Estudo de Viabilidade Urbanística aprovado e válido'
										}
									]}
								/>
							)}

							<HistoricoRespostaAnterior
								dataAnterior={infosProjetoDataAnterior['projetoPossuiEvuValido']}
								dataAtual={infosProjetoData['projetoPossuiEvuValido']}
								usuarioInterno={usuarioInterno}
							/>
							<Comentario
								campoComentario={`projetoPossuiEvuValido${sufixoComentario}`}
								valueComentario={infosProjetoData[`projetoPossuiEvuValido${sufixoComentario}`]}
								onChangeComentarioHandler={typeof changeComentarioHandler === 'function' ? localChangeHandler : null}
								setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
								readOnly={!!readOnlyVersao || !usuarioInterno}
								temComentario={temComentario[`projetoPossuiEvuValido${sufixoComentario}`]}
							/>
							<UltimoEvuValido evu={localData?.ultimoEvuValido} semExpedienteUnico={semExpedienteUnico} />
						</div>
					</div>
					<div>
						{(isNaoResidenciais || isMultifamiliar || isEnqHabColetiva || isUnifamiliar) && (
							<>
								<legend className="legenda-titulo-secundario">{`${
									isUnidadeAutonoma ? '3' : '4'
								}.4 Vagas de estacionamento`}</legend>
								{isUnifamiliar && !isNaoResidenciais ? (
									<div
										className={`pergunta metragem ${
											temComentario[`totalQtdVagasCondominiais${sufixoComentario}`]
												? 'resposta-com-comentario pin-comentario'
												: usuarioInterno
												? 'resposta-com-comentario'
												: ''
										}`}
									>
										<NumberFieldLE
											name="totalQtdVagasCondominiais"
											label="Número de vagas de estacionamento"
											placeHolder="0"
											required={false}
											disabled={disabled}
											onChangeHandler={localChangeHandler}
											value={infosProjetoData.totalQtdVagasCondominiais}
											maxSize="10"
										/>
										<HistoricoRespostaAnterior
											dataAnterior={infosProjetoDataAnterior['totalQtdVagasCondominiais']}
											dataAtual={infosProjetoData['totalQtdVagasCondominiais']}
											usuarioInterno={usuarioInterno}
										/>
										<Comentario
											campoComentario={`totalQtdVagasCondominiais${sufixoComentario}`}
											valueComentario={infosProjetoData[`totalQtdVagasCondominiais${sufixoComentario}`]}
											onChangeComentarioHandler={
												typeof changeComentarioHandler === 'function' ? localChangeHandler : null
											}
											setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
											readOnly={readOnlyVersao || !usuarioInterno}
											temComentario={temComentario[`totalQtdVagasCondominiais${sufixoComentario}`]}
										/>
									</div>
								) : (
									<>
										<div
											className={`pergunta metragem ${
												temComentario[`qtdBoxesPrivativosSimples${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="qtdBoxesPrivativosSimples"
												label="Boxes privativos simples"
												placeHolder="0"
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.qtdBoxesPrivativosSimples}
												maxSize="10"
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.qtdBoxesPrivativosSimples ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'qtdBoxesPrivativosSimples' ? e.message : null
													)
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['qtdBoxesPrivativosSimples']}
												dataAtual={infosProjetoData['qtdBoxesPrivativosSimples']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`qtdBoxesPrivativosSimples${sufixoComentario}`}
												valueComentario={infosProjetoData[`qtdBoxesPrivativosSimples${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`qtdBoxesPrivativosSimples${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`qtdBoxesPrivativosDuplos${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="qtdBoxesPrivativosDuplos"
												label="Boxes privativos duplos"
												placeHolder="0"
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.qtdBoxesPrivativosDuplos}
												maxSize="10"
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.qtdBoxesPrivativosDuplos ||
													(errosSubmissao || []).filter(e =>
														e.campo === 'qtdBoxesPrivativosDuplos' ? e.message : null
													)
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['qtdBoxesPrivativosDuplos']}
												dataAtual={infosProjetoData['qtdBoxesPrivativosDuplos']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`qtdBoxesPrivativosDuplos${sufixoComentario}`}
												valueComentario={infosProjetoData[`qtdBoxesPrivativosDuplos${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`qtdBoxesPrivativosDuplos${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`totalQtdBoxesPrivativos${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalQtdBoxesPrivativos"
												label="Boxes totais"
												placeHolder="0"
												readOnly={true}
												disabled={disabled}
												value={infosProjetoData.totalQtdBoxesPrivativos}
												maxSize="10"
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalQtdBoxesPrivativos']}
												dataAtual={infosProjetoData['totalQtdBoxesPrivativos']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalQtdBoxesPrivativos${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalQtdBoxesPrivativos${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalQtdBoxesPrivativos${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`qtdVagasCondominiais${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="qtdVagasCondominiais"
												label="Vagas condominiais"
												placeHolder="0"
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.qtdVagasCondominiais}
												maxSize="10"
												disabled={disabled}
												tooltip={
													bloquearQtdVagasCondominiais
														? 'Devido a edificação possuir uma economia, não há vagas condominiais'
														: null
												}
												forceTooltip={bloquearQtdVagasCondominiais}
												readOnly={bloquearQtdVagasCondominiais}
											/>
											<ErrorMessages
												errorList={
													erros?.qtdVagasCondominiais ||
													(errosSubmissao || []).filter(e => (e.campo === 'qtdVagasCondominiais' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['qtdVagasCondominiais']}
												dataAtual={infosProjetoData['qtdVagasCondominiais']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`qtdVagasCondominiais${sufixoComentario}`}
												valueComentario={infosProjetoData[`qtdVagasCondominiais${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`qtdVagasCondominiais${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`totalQtdVagasCondominiais${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="totalQtdVagasCondominiais"
												label="Vagas totais"
												placeHolder="0"
												readOnly={true}
												disabled={disabled}
												value={infosProjetoData.totalQtdVagasCondominiais}
												maxSize="10"
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['totalQtdVagasCondominiais']}
												dataAtual={infosProjetoData['totalQtdVagasCondominiais']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`totalQtdVagasCondominiais${sufixoComentario}`}
												valueComentario={infosProjetoData[`totalQtdVagasCondominiais${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`totalQtdVagasCondominiais${sufixoComentario}`]}
											/>
										</div>
										{isVagasTotaisMaiorQue400 && (isMultifamiliar || isMultifamiliarNaoResidencial) && (
											<div
												className={`pergunta ${
													temComentario[`projetoPossuiEvu2GrauValido${sufixoComentario}`]
														? 'resposta-com-comentario pin-comentario'
														: usuarioInterno
														? 'resposta-com-comentario'
														: ''
												}`}
											>
												<RadioFieldLE
													name="projetoPossuiEvu2GrauValido"
													label="O projeto possui Estudo de Viabilidade Urbanística de 2º grau aprovado e válido?"
													options={OPT_SIM_NAO}
													required={true}
													onChangeHandler={localChangeHandler}
													value={infosProjetoData.projetoPossuiEvu2GrauValido}
													disabled={disabled}
												/>
												<ErrorMessages
													errorList={
														erros?.projetoPossuiEvu2GrauValido ||
														(errosSubmissao || []).filter(e =>
															e.campo === 'projetoPossuiEvu2GrauValido' ? e.message : null
														)
													}
												/>

												<HistoricoRespostaAnterior
													dataAnterior={infosProjetoDataAnterior['projetoPossuiEvu2GrauValido']}
													dataAtual={infosProjetoData['projetoPossuiEvu2GrauValido']}
													usuarioInterno={usuarioInterno}
												/>
												<Comentario
													campoComentario={`projetoPossuiEvu2GrauValido${sufixoComentario}`}
													valueComentario={infosProjetoData[`projetoPossuiEvu2GrauValido${sufixoComentario}`]}
													onChangeComentarioHandler={
														typeof changeComentarioHandler === 'function' ? localChangeHandler : null
													}
													setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
													readOnly={!!readOnlyVersao || !usuarioInterno}
													temComentario={temComentario[`projetoPossuiEvu2GrauValido${sufixoComentario}`]}
												/>
											</div>
										)}
										<div
											className={`pergunta ${
												temComentario[`possuiVagaCargaDescarga${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="possuiVagaCargaDescarga"
												label="Vagas de carga e descarga?"
												options={OPT_SIM_NAO}
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.possuiVagaCargaDescarga}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.possuiVagaCargaDescarga ||
													(errosSubmissao || []).filter(e => (e.campo === 'possuiVagaCargaDescarga' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['possuiVagaCargaDescarga']}
												dataAtual={infosProjetoData['possuiVagaCargaDescarga']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`possuiVagaCargaDescarga${sufixoComentario}`}
												valueComentario={infosProjetoData[`possuiVagaCargaDescarga${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`possuiVagaCargaDescarga${sufixoComentario}`]}
											/>
											{infosProjetoData?.possuiVagaCargaDescarga === 'sim' && (
												<div
													className={`pergunta metragem ${
														temComentario[`qtdVagasCargaDescarga${sufixoComentario}`]
															? 'resposta-com-comentario pin-comentario'
															: usuarioInterno
															? 'resposta-com-comentario'
															: ''
													}`}
												>
													<>
														<NumberFieldLE
															name="qtdVagasCargaDescarga"
															label="Quantas vagas de carga e descarga?"
															placeHolder="0"
															required={false}
															onChangeHandler={localChangeHandler}
															value={infosProjetoData.qtdVagasCargaDescarga}
															maxSize="10"
															disabled={disabled}
														/>
														<ErrorMessages
															errorList={
																erros?.qtdVagasCargaDescarga ||
																(errosSubmissao || []).filter(e =>
																	e.campo === 'qtdVagasCargaDescarga' ? e.message : null
																)
															}
														/>

														<HistoricoRespostaAnterior
															dataAnterior={infosProjetoDataAnterior['qtdVagasCargaDescarga']}
															dataAtual={infosProjetoData['qtdVagasCargaDescarga']}
															usuarioInterno={usuarioInterno}
														/>
														<Comentario
															campoComentario={`qtdVagasCargaDescarga${sufixoComentario}`}
															valueComentario={infosProjetoData[`qtdVagasCargaDescarga${sufixoComentario}`]}
															onChangeComentarioHandler={
																typeof changeComentarioHandler === 'function' ? localChangeHandler : null
															}
															setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
															readOnly={!!readOnlyVersao || !usuarioInterno}
															temComentario={temComentario[`qtdVagasCargaDescarga${sufixoComentario}`]}
														/>
													</>
												</div>
											)}
										</div>

										<div
											className={`pergunta ${
												temComentario[`possuiBicicletario${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<RadioFieldLE
												name="possuiBicicletario"
												label="Bicicletário?"
												options={OPT_SIM_NAO}
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.possuiBicicletario}
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.possuiBicicletario ||
													(errosSubmissao || []).filter(e => (e.campo === 'possuiBicicletario' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['possuiBicicletario']}
												dataAtual={infosProjetoData['possuiBicicletario']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`possuiBicicletario${sufixoComentario}`}
												valueComentario={infosProjetoData[`possuiBicicletario${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`possuiBicicletario${sufixoComentario}`]}
											/>
										</div>
										<div
											className={`pergunta metragem ${
												temComentario[`qtdVagasDeficiencia${sufixoComentario}`]
													? 'resposta-com-comentario pin-comentario'
													: usuarioInterno
													? 'resposta-com-comentario'
													: ''
											}`}
										>
											<NumberFieldLE
												name="qtdVagasDeficiencia"
												label="Vagas para PcD (Art. 124, §4º do PDDUA)?"
												placeHolder="0"
												required={false}
												onChangeHandler={localChangeHandler}
												value={infosProjetoData.qtdVagasDeficiencia}
												maxSize="10"
												disabled={disabled}
											/>
											<ErrorMessages
												errorList={
													erros?.qtdVagasDeficiencia ||
													(errosSubmissao || []).filter(e => (e.campo === 'qtdVagasDeficiencia' ? e.message : null))
												}
											/>

											<HistoricoRespostaAnterior
												dataAnterior={infosProjetoDataAnterior['qtdVagasDeficiencia']}
												dataAtual={infosProjetoData['qtdVagasDeficiencia']}
												usuarioInterno={usuarioInterno}
											/>
											<Comentario
												campoComentario={`qtdVagasDeficiencia${sufixoComentario}`}
												valueComentario={infosProjetoData[`qtdVagasDeficiencia${sufixoComentario}`]}
												onChangeComentarioHandler={
													typeof changeComentarioHandler === 'function' ? localChangeHandler : null
												}
												setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
												readOnly={!!readOnlyVersao || !usuarioInterno}
												temComentario={temComentario[`qtdVagasDeficiencia${sufixoComentario}`]}
											/>
										</div>
									</>
								)}
							</>
						)}
					</div>
					<div>
						{(isMultifamiliar || isNaoResidenciais || isEnqHabColetiva) && (
							<>
								<legend className="legenda-titulo-secundario">{`${
									isUnidadeAutonoma ? '3' : '4'
								}.5 Índices Construtivos`}</legend>
								<div
									className={`pergunta pergunta-indices-construtivos ${
										temComentario[`aquisicaoIndicesConstrutivos${sufixoComentario}`]
											? 'resposta-com-comentario pin-comentario'
											: usuarioInterno
											? 'resposta-com-comentario'
											: ''
									}`}
								>
									<RadioFieldLE
										name="aquisicaoIndicesConstrutivos"
										label="O projeto contempla a aquisição de índices construtivos?"
										options={OPT_SIM_NAO}
										required={true}
										onChangeHandler={changes => localChangeHandlerComAlert(changes, picData)}
										value={infosProjetoData.aquisicaoIndicesConstrutivos}
										tooltip="Solo Criado, Transferência de Potencial Construtivo (TPC), Leilão"
										disabled={disabled}
										observacao={
											infosProjetoData.aquisicaoIndicesConstrutivos === 'sim' ? (
												<span style={{ color: '#856404', fontSize: '10pt', fontStyle: 'italic' }}>
													Em caso de alteração da resposta, os dados preenchidos para os índices construtivos serão
													apagados
												</span>
											) : null
										}
									/>
									<ErrorMessages
										errorList={
											erros?.aquisicaoIndicesConstrutivos ||
											(errosSubmissao || []).filter(e =>
												e.campo === 'aquisicaoIndicesConstrutivos' ? e.message : null
											)
										}
									/>

									<HistoricoRespostaAnterior
										dataAnterior={infosProjetoDataAnterior['aquisicaoIndicesConstrutivos']}
										dataAtual={infosProjetoData['aquisicaoIndicesConstrutivos']}
										usuarioInterno={usuarioInterno}
									/>
									<Comentario
										campoComentario={`aquisicaoIndicesConstrutivos${sufixoComentario}`}
										valueComentario={infosProjetoData[`aquisicaoIndicesConstrutivos${sufixoComentario}`]}
										onChangeComentarioHandler={
											typeof changeComentarioHandler === 'function' ? localChangeHandler : null
										}
										setTemComentarioAux={({ name, value }) => setTemComentarioAux({ name, value })}
										readOnly={!!readOnlyVersao || !usuarioInterno}
										temComentario={temComentario[`aquisicaoIndicesConstrutivos${sufixoComentario}`]}
									/>
								</div>
								{possuiIndicesConstrutivos && (
									<PlanilhaIndicesConstrutivos
										planilha={picData || {}}
										planilhaAnterior={picDataAnterior || {}}
										planoDiretor={planoDiretorData}
										infosProjeto={infosProjetoData}
										possuiAreaAtingida={possuiAreaAtingida}
										possuiImovelInventariado={['imoveltombado', 'imovelinventariadodeestruturacao'].includes(
											localData?.restricoesAdministrativas?.predioTombadoInventariado
										)}
										areaAtingidaMatricula={areaAtingidaMatricula}
										areaAtingidaMenorPoligonal={areaAtingidaMenorPoligonal}
										totalIaPermitido={totalIaPermitido}
										totalIaMaxTotalPermitido={totalIaMaxTotalPermitido}
										onChangeHandler={(value, name) => localChangeHandler({ name, value })}
										readOnly={disabled}
										readOnlyVersao={readOnlyVersao}
										errors={errosSubmissao || []}
										usuarioInterno={usuarioInterno}
									/>
								)}
								<ShowDebug data={picData} />
							</>
						)}
					</div>
				</>
			)}
			<Modal
				show={confirmaRemoverPic}
				onHide={() => setConfirmaRemoverPic(false)}
				dialogClassName="modal-90w"
				aria-labelledby="Arrazoado EVU"
			>
				<Modal.Header closeButton>
					<Modal.Title id="arrazoadoEvu">Remover dados da Planilha de Índices Construtivos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Confirma exclusão dos dados da planilha de Índices Construtivos?
					<div className="toolbar">
						<button type="button" className="btn btn-default" onClick={() => setConfirmaRemoverPic(false)}>
							Cancelar
						</button>
						<button type="button" className="btn btn-primary" onClick={removerPic}>
							Confirmar
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</fieldset>
	);
}

InfosProjetoAP.displayName = 'InfosProjetoAP';
InfosProjetoAP.propTypes = {
	data: PropTypes.object,
	dataAnterior: PropTypes.object,
	onChangeHandler: PropTypes.func,
	tipoForm: PropTypes.string,
	errosSubmissao: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
	semExpedienteUnico: PropTypes.bool,
	disabled: PropTypes.bool,
	changeComentarioHandler: PropTypes.func,
	usuarioInterno: PropTypes.bool,
	readOnlyVersao: PropTypes.any
};

/**
 * valida e limpa os dados do Form baseado no METAFORMLE.INFOS_PROJETO
 *
 * @returns objeto com erros
 * */
const validarInfosProjetoAP = (infosProjeto, data, tipoForm, isMultifamiliar, isMultifamiliarNaoResidencial) => {
	const dadosForm = infosProjeto;
	const isNaoResidenciais = includesTipoForm([NAO_RESIDENCIAL], tipoForm);

	let erros = {};
	let errorsToRemove = [
		'totalBaseProjeto',
		'totalCorpoProjeto',
		'taxaOcupacaoTotal',
		'alturaTotalProjeto',
		'taxaOcupacaoProjeto',
		'numeroEconomiasTotal',
		'numeroEconomiasResidencial',
		'projetoPossuiEvu2GrauValido',
		'qtdVagasDeficiencia'
	];
	const isProjetoPossuiBaseCorpo =
		dadosForm.projetoPossuiBaseCorpo === 'sim' ? true : dadosForm.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		let valInformado = get(dadosForm, ['totalBaseProjeto', 'value']);
		let valLimite = get(dadosForm, ['totalPermitidoBase', 'value']);
		if (maiorQue(valInformado, valLimite)) {
			set(erros, 'totalBaseProjeto', [
				{
					type: 'warning',
					message:
						'Taxa de Ocupação (TO) total da base do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para a base (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
				}
			]);
			errorsToRemove = errorsToRemove.filter(e => e !== 'totalBaseProjeto');
		}
		valInformado = get(dadosForm, ['totalCorpoProjeto', 'value']);
		valLimite = get(dadosForm, ['totalPermitidoCorpo', 'value']);
		if (maiorQue(valInformado, valLimite)) {
			set(erros, 'totalCorpoProjeto', [
				{
					type: 'warning',
					message:
						'Taxa de Ocupação (TO) total do corpo do projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido para o corpo (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
				}
			]);
			errorsToRemove = errorsToRemove.filter(e => e !== 'totalCorpoProjeto');
		}
	}

	const valInformado = get(dadosForm, ['taxaOcupacaoTotal', 'value']);
	const valLimite = get(dadosForm, ['taxaOcupacaoTotalPermitido', 'value']);
	if (maiorQue(valInformado, valLimite)) {
		set(erros, 'taxaOcupacaoTotal', [
			{
				type: 'warning',
				message:
					'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Taxa de Ocupação (TO) total permitido (m²), exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
			}
		]);
		errorsToRemove = errorsToRemove.filter(e => e !== 'taxaOcupacaoTotal');
	}

	const valLimiteLote = get(data, ['areaTotalMatriculaLote', 'value']);
	if (maiorQue(valInformado, valLimiteLote)) {
		set(erros, 'taxaOcupacaoTotal', [
			'A Taxa de Ocupação (TO) total do Projeto (m²) deve ser menor ou igual a Área total de matrícula do lote (m²).'
		]);
		errorsToRemove = errorsToRemove.filter(e => e !== 'taxaOcupacaoTotal');
	}

	const alturaMaximaAeroportoAerodromo = get(dadosForm, 'alturaMaximaAeroportoAerodromo.value');
	const alturaTotal = get(dadosForm, 'alturaTotalProjeto.value');

	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'nao'
	) {
		set(erros, 'alturaTotalProjeto', [
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão"Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		]);
		errorsToRemove = errorsToRemove.filter(e => e !== 'alturaTotalProjeto');
	}
	const taxaOcupacaoProjeto = get(dadosForm, 'taxaOcupacaoProjeto.value');
	const quotaTaxaOcupacao = get(data, 'quotaTaxaOcupacao.value');
	if (maiorQue(taxaOcupacaoProjeto, quotaTaxaOcupacao)) {
		set(erros, 'taxaOcupacaoProjeto', [
			'A Taxa de Ocupação do Projeto (m²) deve ser menor ou igual à Taxa de Ocupação (m²) para a Unidade Autônoma conforme a aprovação do condomínio, exceto em caso de Estudo de Viabilidade Urbanístico aprovado e válido.'
		]);
		errorsToRemove = errorsToRemove.filter(e => e !== 'taxaOcupacaoProjeto');
	}
	if (
		maiorQue(alturaTotal, alturaMaximaAeroportoAerodromo) &&
		get(data, 'dmiRestricaoAlturaProximidadeAeroporto') === 'sim' &&
		get(data, 'edificacaoUltrapassaAlturaDECEA') === 'nao'
	) {
		set(erros, 'alturaTotalProjeto', [
			'Há divergência nas respostas do formulário. Verificar a restrição relativa à ALTURA FACE PROXIMIDADE AEROPORTUÁRIA. A altura total do projeto é maior do que a Altura máxima definida pela guia Aeroporto informada na DMWEB, anexe o documento AUTORIZAÇÃO do DECEA (DECLARAÇÃO DE INEXIGIBILIDADE) no botão"Adicionar documento" da aba DOCUMENTOS após concluir este formulário.'
		]);
		errorsToRemove = errorsToRemove.filter(e => e !== 'alturaTotalProjeto');
	}

	const numeroEconomiasNaoResidencial = get(dadosForm, 'numeroEconomiasNaoResidencial.value');
	if (isNaoResidenciais && (numeroEconomiasNaoResidencial === 0 || numeroEconomiasNaoResidencial === '0')) {
		set(erros, 'numeroEconomiasTotal', ['Deve ser informada ao menos 1 economia não residencial.']);
	}
	errorsToRemove = errorsToRemove.filter(e => e !== 'numeroEconomiasTotal');

	if ((isMultifamiliar || isMultifamiliarNaoResidencial) && data?.infosProjeto?.deveUsarQuotaIdealProjeto) {
		const quantidadeMaxEconomiasResidenciaisProjeto = parseInt(
			data?.infosProjeto?.quantidadeMaxEconomiasResidenciaisProjeto?.value || 0,
			10
		);
		const numeroEconomiasResidencial = parseInt(data?.infosProjeto?.numeroEconomiasResidencial?.value || 0, 10);

		if (quantidadeMaxEconomiasResidenciaisProjeto !== 0) {
			if (numeroEconomiasResidencial > quantidadeMaxEconomiasResidenciaisProjeto) {
				set(erros, 'numeroEconomiasResidencial', [
					{
						type: 'warning',
						message:
							'O número de economias residenciais ultrapassa o limite permitido pelo Plano Diretor aplicando a quota ideal (QI).'
					}
				]);
			}
			errorsToRemove = errorsToRemove.filter(e => e !== 'numeroEconomiasResidencial');
		}

		//totalQtdVagasCondominiais
		const totalQtdVagasCondominiais = get(data, 'infosProjeto.totalQtdVagasCondominiais.value');
		if (maiorQue(totalQtdVagasCondominiais, '400')) {
			// projetoPossuiEvu2GrauValido dever ser obrigatório
			if (isNil(get(data, 'infosProjeto.projetoPossuiEvu2GrauValido'))) {
				set(erros, 'projetoPossuiEvu2GrauValido', [
					'Informar "O projeto possui Estudo de Viabilidade Urbanística de 2º grau aprovado e válido?"'
				]);
				errorsToRemove = errorsToRemove.filter(e => e !== 'projetoPossuiEvu2GrauValido');
			}
		}

		if (maiorQue(totalQtdVagasCondominiais, '100')) {
			// calcula os 2% do totalQtdVagasCondominiais
			const minimoVagasPCD = multiplica(totalQtdVagasCondominiais, '0.02');
			const qtdVagasDeficiencia = get(data, 'infosProjeto.qtdVagasDeficiencia.value');
			// Se não tiver 2% das vagas, mostrar a seguinte mensagem:
			if (maiorQue(minimoVagasPCD, qtdVagasDeficiencia)) {
				set(erros, 'qtdVagasDeficiencia', [
					'O número de vagas para PcD deve estar de acordo com a NBR 9050 que exige 2% das vagas totais para vagas para PcD'
				]);
				errorsToRemove = errorsToRemove.filter(e => e !== 'qtdVagasDeficiencia');
			}
		}
	}

	return { erros, errorsToRemove };
};

export function includesTipoForm(tiposArray, tipoForm) {
	return (tipoForm || '').split(',').reduce((acc, tipoFormAux) => {
		if (tiposArray.includes(tipoFormAux)) {
			return true;
		}
		return acc;
	}, false);
}

export const isAtividadeDepositoRevendaGas = subunidades =>
	subunidades.some(subunidade => {
		const { dadosAtividades = [] } = subunidade;
		return dadosAtividades.some(atv => ATV_REVENDA_POSTO_GAS.includes(trim(atv.atividadeAnexo52.item)));
	});

export const isInterferenciaAmbiental = subunidades =>
	subunidades.some(subunidade => {
		const { dadosAtividades = [] } = subunidade;
		return dadosAtividades.some(atv => ATV_INTERFERENCIA_AMBIENTAL.includes(trim(atv.atividadeAnexo52.item)));
	});

export const isAtividadesEnsino = subunidades =>
	subunidades.some(subunidade => {
		const { dadosAtividades = [] } = subunidade;
		return dadosAtividades.some(atv => ATV_ENSINO_ESCOLAR.includes(trim(atv.atividadeAnexo52.item)));
	});

export const isDecretoEntrenimentoNoturno = subunidades =>
	subunidades.some(subunidade => {
		const { dadosAtividades = [] } = subunidade;
		return dadosAtividades.some(atv => ATV_DECRETO_ENTRETENIMENTO_NOTURNO.includes(trim(atv.atividadeAnexo52.item)));
	});

function CustomErrorMessages({ errors1, errors2, property }) {
	CustomErrorMessages.displayName = 'CustomErrorMessages';
	CustomErrorMessages.propTypes = {
		errors1: PropTypes.object,
		errors2: PropTypes.array,
		property: PropTypes.string
	};
	return (
		<ErrorMessages
			errorList={[
				...(errors1?.[property] || []),
				...(errors2 || []).filter(e => (e.campo === property ? e.message : null))
			]}
		/>
	);
}
